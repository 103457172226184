import React from "react";
import { ChangeEvent, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { FaTimes } from "react-icons/fa";
import { ISelectOptions } from "interfaces/all-cases.interface";
import arrow from "assets/images/select_arrow.png";
import DropdownPresentation from "components/common/dropdown/DropDown";
import ClearFilters from "components/common/button/ClearFilters";
import { useFilingTransactionContext } from "../FilingTransactionContext";
import { OperationTypeEnum } from "utils/enum";
import { FilingBlankTypeList, StatusList } from "utils/constants";
import { MultiSelect } from 'primereact/multiselect';
import { string } from "yup";

const initialSelectOption: ISelectOptions = { id: '', value: '' };

type FilingTransaction_SearchBarProps = {
  activeTab?: string;
};
const FilingTransaction_SearchFilters = (props: FilingTransaction_SearchBarProps) => {
  const [selectedCompany, setSelectedCompany] = useState<ISelectOptions>(initialSelectOption);
  const [companyList, setCompanyList] = useState<ISelectOptions[]>([]);
  const [typeList, setTypeList] = useState<ISelectOptions[]>([]);
  const [selectedType, setSelectedType] = useState<ISelectOptions>(initialSelectOption);
  const [selectedBlankType, setSelectedBlankType] = useState<ISelectOptions[]>([]);

  const [payrollDate, setPayrollDate] = useState<[Date | null, Date | null]>([null, null])
  const [commissionDate, setCommissionDate] = useState<[Date | null, Date | null]>([null, null])

  const { filingTransactions, getFilingTransactions, setFilingTransactions, filingType,
    setFilingType, allCompanies, companyId, setCompanyId,
    setShowSpinner,
    setSelectedFilingTransactionId, setBulkRecords,
    dateFiled, datePaid, setDateFiled, setDatePaid
    , getAccountingQueue, setAccountingQueue, accountingQueue } = useFilingTransactionContext();

  useEffect(() => {
    const typeList = Object.entries(OperationTypeEnum)
      .filter(([key, value]) => typeof value === 'number') // Keep only numeric values
      .map(([key, value]) => ({
        id: value, // Numeric value
        value: key // Enum name as string
      }));

    setTypeList(typeList);
  }, []);
  useEffect(() => {
    var companyList = allCompanies.map((item) => ({
      id: item.id,
      value: item.companyName
    }));
    var newList = companyList.filter((item) => item.value != "Super Company");
    setCompanyList(newList);
  }, [allCompanies]);
  useEffect(() => {
    setSelectedCompany(initialSelectOption);
  }, [filingType]);

  useEffect(() => {
    setAccountingQueue((prevAll) => ({
      ...prevAll, fromDatePaid: null, toDatePaid: null, type: ''
      , fromDatePayroll: null, toDatePayroll: null, fromDateCommission: null, toDateCommission: null, blankOption: []
    }));
    setFilingTransactions((prevAll) => ({ ...prevAll, type: filingType, companyId: '', fromDate: null, toDate: null, datePaidFromDate: null, datePaidToDate: null, blankOption: [] }));

    setSelectedType(initialSelectOption);
    setCommissionDate([null, null]);
    setPayrollDate([null, null]);
    setSelectedBlankType([]);
  }, [props.activeTab]);
  const getDataResponse = async (fromDate: Date | null, toDate: Date | null) => {
    if (fromDate && toDate) {
      getFilingTransactions(1, 100, filingTransactions.searchParam, filingType, companyId, fromDate, toDate, datePaid[0], datePaid[1], filingTransactions.blankOption);
      setFilingTransactions((prevAll) => ({ ...prevAll, type: filingType, fromDate: fromDate, toDate: toDate }));

    }
    else if (fromDate == null && toDate == null) {
      getFilingTransactions(1, 100, filingTransactions.searchParam, filingType, companyId, null, null, datePaid[0], datePaid[1], filingTransactions.blankOption);
      setFilingTransactions((prevAll) => ({ ...prevAll, type: filingType, fromDate: null, toDate: null }));
    }
  };

  const getDatePaidDataResponse = async (fromDate: Date | null, toDate: Date | null) => {
    if (fromDate && toDate) {
      if (props.activeTab != "Accounting Queue") {
        getFilingTransactions(1, 100, filingTransactions.searchParam, filingType, companyId, dateFiled[0], dateFiled[1], fromDate, toDate, filingTransactions.blankOption);
        setFilingTransactions((prevAll) => ({ ...prevAll, type: filingType, datePaidFromDate: fromDate, datePaidToDate: toDate }));
      }
      else {
        getAccountingQueue(1, 100, accountingQueue.searchParam, fromDate, toDate, accountingQueue.type
          , accountingQueue.fromDatePayroll, accountingQueue.toDatePayroll, accountingQueue.fromDateCommission, accountingQueue.toDateCommission, accountingQueue.blankOption);
        setAccountingQueue((prevAll) => ({ ...prevAll, fromDatePaid: fromDate, toDatePaid: toDate }));
      }
    }
    else if (fromDate == null && toDate == null) {
      if (props.activeTab != "Accounting Queue") {
        getFilingTransactions(1, 100, filingTransactions.searchParam, filingType, companyId, dateFiled[0], dateFiled[1], null, null, filingTransactions.blankOption);
        setFilingTransactions((prevAll) => ({ ...prevAll, type: filingType, datePaidFromDate: null, datePaidToDate: null }));
      }
      else {
        getAccountingQueue(1, 100, accountingQueue.searchParam, null, null, accountingQueue.type
          , accountingQueue.fromDatePayroll, accountingQueue.toDatePayroll, accountingQueue.fromDateCommission, accountingQueue.toDateCommission, accountingQueue.blankOption);
        setAccountingQueue((prevAll) => ({ ...prevAll, fromDatePaid: null, toDatePaid: null }));
      }
    }
  };

  const getPayrollDataResponse = async (fromDate: Date | null, toDate: Date | null) => {
    if (fromDate && toDate) {
      getAccountingQueue(1, 100, accountingQueue.searchParam
        , accountingQueue.fromDatePaid, accountingQueue.toDatePaid, accountingQueue.type, fromDate, toDate
        , accountingQueue.fromDateCommission, accountingQueue.toDateCommission, accountingQueue.blankOption);
      setAccountingQueue((prevAll) => ({ ...prevAll, fromDatePayroll: fromDate, toDatePayroll: toDate }));
    }
    else if (fromDate == null && toDate == null) {
      getAccountingQueue(1, 100, accountingQueue.searchParam
        , accountingQueue.fromDatePaid, accountingQueue.toDatePaid, accountingQueue.type, null, null
        , accountingQueue.fromDateCommission, accountingQueue.toDateCommission, accountingQueue.blankOption);
      setAccountingQueue((prevAll) => ({ ...prevAll, fromDatePayroll: null, toDatePayroll: null }));
    }
  };

  const getCommissionDateResponse = async (fromDate: Date | null, toDate: Date | null) => {
    if (fromDate && toDate) {
      getAccountingQueue(1, 100, accountingQueue.searchParam
        , accountingQueue.fromDatePaid, accountingQueue.toDatePaid, accountingQueue.type
        , accountingQueue.fromDatePayroll, accountingQueue.toDatePayroll, fromDate, toDate, accountingQueue.blankOption);
      setAccountingQueue((prevAll) => ({ ...prevAll, fromDateCommission: fromDate, toDateCommission: toDate }));
    }
    else if (fromDate == null && toDate == null) {
      getAccountingQueue(1, 100, accountingQueue.searchParam
        , accountingQueue.fromDatePaid, accountingQueue.toDatePaid, accountingQueue.type
        , accountingQueue.fromDatePayroll, accountingQueue.toDatePayroll, null, null, accountingQueue.blankOption);
      setAccountingQueue((prevAll) => ({ ...prevAll, fromDateCommission: null, toDateCommission: null }));
    }
  };

  const handleCompanyChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedFilingTransactionId([]);
    setBulkRecords([]);
    setSelectedCompany({ id: event.target.value, value: companyList.find(x => x.id === event.target.value)?.value || '' });
    setCompanyId(event.target.value);
    getFilingTransactions(1, 100, filingTransactions.searchParam, filingType, event.target.value, dateFiled[0], dateFiled[1], datePaid[0], datePaid[1], filingTransactions.blankOption);
    // getAllCases(1, 100, allCases.searchParam, status, selectedCounty.id.toString(),selectedFiling?selectedFiling.id==1:null);
    setFilingTransactions((prevAll) => ({ ...prevAll, type: filingType, companyId: event.target.value }));
  };

  const handleBlankTypeChange = (selectedOptions: ISelectOptions[]) => {
    // Update the selected blank type with multiple values
    setSelectedBlankType(selectedOptions);
    const selectedValues = selectedOptions.map(option => option.value);
    setSelectedFilingTransactionId([]);
    setBulkRecords([]);

    const values = selectedOptions.join(",")
    const valuesList: string[] = values.split(',').map(value => value.trim());
    const selectedIds = FilingBlankTypeList
      .filter(item => selectedOptions.some(option => option.value === item.value)) // Check for matching values
      .map(item => item.id); // Extract the corresponding ids

    if (props.activeTab != "Accounting Queue") {
      // Call the API or function with updated parameters
      getFilingTransactions(1, 100, filingTransactions.searchParam, filingType, filingTransactions.companyId, dateFiled[0], dateFiled[1], datePaid[0], datePaid[1], valuesList);

      // Update the filing transactions state based on the first selected option
      setFilingTransactions((prevAll) => ({
        ...prevAll,
        blankOption: valuesList,
      }));
    }
    else {
      getAccountingQueue(1, 100, accountingQueue.searchParam
        , accountingQueue.fromDatePaid, accountingQueue.toDatePaid, accountingQueue.type
        , accountingQueue.fromDatePayroll, accountingQueue.toDatePayroll
        , accountingQueue.fromDateCommission, accountingQueue.toDateCommission, valuesList);
      setAccountingQueue((prevAll) => ({ ...prevAll, blankOption: valuesList }));
    }
  };

  const handleTypeChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedId = Number(event.target.value); // Convert to number
    const selectedType = typeList.find(x => x.id === selectedId);

    setSelectedType({
      id: selectedId,
      value: selectedType?.value || ''
    });

    const value = selectedType?.value || '';
    getAccountingQueue(1, 100, accountingQueue.searchParam, accountingQueue.fromDatePaid, accountingQueue.toDatePaid
      , value, accountingQueue.fromDatePayroll, accountingQueue.toDatePayroll, accountingQueue.fromDateCommission, accountingQueue.toDateCommission, accountingQueue.blankOption);
    setAccountingQueue((prevAll) => ({ ...prevAll, type: value }));
  };

  const clearSearchFilters = () => {
    setSelectedCompany(initialSelectOption);
    setCompanyId("");
    setDateFiled([null, null]);
    setDatePaid([null, null]);
    setSelectedType(initialSelectOption);
    setCommissionDate([null, null]);
    setPayrollDate([null, null]);
    setSelectedBlankType([]);
    if (props.activeTab != "Accounting Queue") {
      getFilingTransactions(1, 100, filingTransactions.searchParam, filingType);
      setFilingTransactions((prevAll) => ({ ...prevAll, type: filingType, companyId: '', fromDate: null, toDate: null, datePaidFromDate: null, datePaidToDate: null, blankOption: [] }));
    }
    else {
      getAccountingQueue(1, 100, accountingQueue.searchParam);
      setAccountingQueue((prevAll) => ({
        ...prevAll, fromDatePaid: null, toDatePaid: null, type: ''
        , fromDatePayroll: null, toDatePayroll: null, fromDateCommission: null, toDateCommission: null, blankOption: []
      }));
    }
  };


  return (
    <>
      <div className="flex items-center filterSec">
        {props.activeTab != "Accounting Queue" && <>
          <DropdownPresentation
            heading=""
            selectedOption={selectedCompany}
            handleSelect={handleCompanyChange}
            options={companyList}
            placeholder="Select Company"
          />
          <div className="mr-1.5">
            <DatePicker
              className="bg-calendar peer !placeholder-gray-500 outline-none py-1.5 md:py-2 p-2.5 !pr-7 block border w-full border-gray-200 rounded-md text-xs placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none bg-no-repeat bg-[center_right_10px]"
              selectsRange={true}
              startDate={dateFiled[0]}
              endDate={dateFiled[1]}
              onChange={(update: [Date | null, Date | null]) => {
                setSelectedFilingTransactionId([]);
                setBulkRecords([]);
                setDateFiled(update);
                getDataResponse(update[0], update[1]);
              }}
              maxDate={new Date()}
              placeholderText={"Select FiledDate"}
            />
          </div>
        </>}
        <div className="mr-[0.75rem]">
          <DatePicker
            className="bg-calendar peer !placeholder-gray-500 outline-none py-2 md:py-2.5 p-3 !pr-8 block border w-full border-gray-200 rounded-md text-xs placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none bg-no-repeat bg-[center_right_10px]"
            selectsRange={true}
            startDate={datePaid[0]}
            endDate={datePaid[1]}
            onChange={(update: [Date | null, Date | null]) => {
              setSelectedFilingTransactionId([]);
              setBulkRecords([]);
              setDatePaid(update);
              getDatePaidDataResponse(update[0], update[1]);
            }}
            maxDate={new Date()}
            placeholderText={"Select DatePaid"}
          />
        </div>
        {props.activeTab == "Accounting Queue" &&
          <>
            <DropdownPresentation
              heading=""
              selectedOption={selectedType}
              handleSelect={handleTypeChange}
              options={typeList}
              placeholder="Select ActionType"
            />
            <div className="mr-[0.75rem]">
              <DatePicker
                className="bg-calendar peer !placeholder-gray-500 outline-none py-2 md:py-2.5 p-3 !pr-8 block border w-full border-gray-200 rounded-md text-xs placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none bg-no-repeat bg-[center_right_10px]"
                selectsRange={true}
                startDate={payrollDate[0]}
                endDate={payrollDate[1]}
                onChange={(update: [Date | null, Date | null]) => {
                  setPayrollDate(update);
                  getPayrollDataResponse(update[0], update[1]);
                }}
                maxDate={new Date()}
                placeholderText={"Select PayrollDate"}
              />
            </div>
            <div className="mr-[0.75rem]">
              <DatePicker
                className="bg-calendar peer !placeholder-gray-500 outline-none py-2 md:py-2.5 p-3 !pr-8 block border w-full border-gray-200 rounded-md text-xs placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none bg-no-repeat bg-[center_right_10px]"
                selectsRange={true}
                startDate={commissionDate[0]}
                endDate={commissionDate[1]}
                onChange={(update: [Date | null, Date | null]) => {
                  setCommissionDate(update);
                  getCommissionDateResponse(update[0], update[1]);
                }}
                maxDate={new Date()}
                placeholderText={"Select CommissionDate"}
              />
            </div>
          </>}
        <MultiSelect
          value={selectedBlankType}
          options={props.activeTab === "Accounting Queue" ? FilingBlankTypeList : FilingBlankTypeList.filter(item => item.id === "DatePaid" || item.id === "InvoiceNo")}
          optionLabel="value" // Assuming "value" is the display label of the options
          placeholder="Filter by Blanks"
          className="w-full md:w-[13rem] mr-[0.75rem]"
          display="chip" // To show selected options as chips
          onChange={(e) => handleBlankTypeChange(e.value)}  // Pass the new selected values
          selectAllLabel="Select All"
        />
        <ClearFilters
          type="button"
          isRounded={false}
          title="Clear Filters"
          handleClick={clearSearchFilters}
          icon={<FaTimes />}
        />
      </div>
    </>
  );
};

export default FilingTransaction_SearchFilters;