import { IAllCasesItems } from "interfaces/all-cases.interface";
import moment from 'moment-timezone';
import { IJWTDecodedToken, ILoginResponse } from "../interfaces/user.interface";
import { jwtDecode } from "jwt-decode";
import { AOSStatusEnum, DismissalReasonEnum, dismissalReasonToEnumMap, OperationTypeEnum, ServiceMethod } from "./enum";
import { colors } from "./constants";

// used in home screen to show user name on the screen
export const getUserInfoFromToken = (): IJWTDecodedToken | null => {
  
  const storedUser = localStorage.getItem("user");
  
  if (storedUser) {
    try {
      const parsedUser: ILoginResponse = JSON.parse(storedUser);
      if(parsedUser.token!=null)
      {
        const decodedToken: IJWTDecodedToken = jwtDecode(parsedUser.token);
        return decodedToken;
      }
      return null;
    } catch (error) {
      console.error("Error parsing or decoding token:", error);
    }
  }

  return null;
};

/**
 * This is being used in late Notice page for create notice section
 */
export const convertHtmlToBase64 = (htmlContent: string): string => {
  // Encode the HTML content to base64
  const base64String = btoa(unescape(encodeURIComponent(htmlContent)));
  return base64String;
};

/**
 * Returns the current date formatted as "DD.MM.YYYY".
 * @returns {string} Formatted date string.
 */
export const getFormattedTodayDate = (): string => {
  // Get today's date
  const today = new Date();

  // Extract day, month, and year
  const day = String(today.getDate()).padStart(2, "0"); // Ensure two digits for day
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const year = today.getFullYear();

  // Create the formatted date string
  return `${day}.${month}.${year}`;
};

/**
 * download pdf
 * @param pdfUrl pdf url
 */
export const downloadPDF = async (
  pdfUrl: string,
  customHeaders?: Record<string, string>
) => {
  try {
    // Extract the file name from the PDF URL
    const fileName = pdfUrl.substring(pdfUrl.lastIndexOf("/") + 1);

    const response = await fetch(pdfUrl, {
      headers: {
        "Content-Type": "application/pdf",
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch PDF: ${response}`);
    }

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName; // Use the extracted file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Clean up the URL object to release resources
    URL.revokeObjectURL(url);
  } catch (error: any) {
    console.error("Error downloading PDF:", error);
  }
};

/**
 * @param date
 * @returns date in DD/MM/YYYY format
 */
export const formattedDate = (date: string) => {
  if(date==""){
    return date;
  }
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  return new Date(date).toLocaleDateString(undefined, options);
};

/**
 * @param price string
 * @returns  amount with decimal
 */
export const convertToPrice = (price: string) => {
  return parseFloat(price).toFixed(2);
};

/**
 *  used in import csv late notices screen
 * @param dateString
 * @returns date obj
 */
const parseDate = (dateString: string) => {
  const [day, month, year] = dateString.split("-");
  // Note: months in JavaScript are 0-indexed, so we subtract 1 from the month
  return new Date(Number(year), Number(month) - 1, Number(day));
};

/**
 * used in import csv late notices screem
 * convert date in YYYY-MM-DD format
 * @param dateString
 * @returns get date in YYYY-MM-DD format.
 */
export const convertDateToYYYYMMDD = (dateString: string): string => {
  // const date = new Date(dateString);
  // const year = date.getFullYear();
  // const month = (date.getMonth() + 1).toString().padStart(2, "0");
  // const day = date.getDate().toString().padStart(2, "0");
  // const formattedDate = `${year}-${month}-${day}`;

  // return formattedDate;
  const formattedDate = new Date(dateString).toLocaleDateString("en-US");
  return formattedDate;
};

export const getDate = (dateString: string): Date => {
  // const date = new Date(dateString);
  // const year = date.getFullYear();
  // const month = (date.getMonth() + 1).toString().padStart(2, "0");
  // const day = date.getDate().toString().padStart(2, "0");
  // const formattedDate = `${year}-${month}-${day}`;
  // const formattedDate = new Date(dateString).toLocaleDateString("en-US");
  const [day, month, year] = dateString.split("-").map(Number);
  const dateObject = new Date(year, month - 1, day);
  return dateObject;
};

export const copyText = async (link: string) => {
  try {
    // Use navigator.clipboard.writeText if available
    await navigator.clipboard.writeText(link);
  } catch (error) {
    console.error("Error copying text to clipboard:", error);
  }
};

// Function to format currency
export const formatCurrency = (value: number) => {
  
  // Assuming value is a number
  const formattedValue = parseFloat(value?.toString()).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formattedValue;
};

const compareObjects = (obj1: any, obj2: any): boolean => {
  // Check if either object is undefined or null
  if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2; // If both are null or non-objects, consider them equal
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if ((val1 === null && val2 !== null) || (val1 !== null && val2 === null)) {
      return false; // If one is null and the other is not, consider them different
    }

    if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (!compareObjects(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
}



export const compareChanges = (list1: IAllCasesItems[], list2: IAllCasesItems[]): boolean => {
  if (list1.length !== list2.length) {
    return true;
  }

  for (let i = 0; i < list1.length; i++) {
    if (!compareObjects(list1[i], list2[i])) {
      return true; // Return true on the first difference
    }
  }

  return false; // If no differences found, return false
}


export const handlePostalCodeKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  // Allow only numbers (0-9) and special keys like Backspace, Delete, Arrow keys, etc.
  const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "End", "Home"];
  if (!/[\d\b]/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export const getEvictionServiceMethod = (method: string): ServiceMethod | undefined => {
  switch (method) {
    case 'Tack':
      return ServiceMethod.TACK_MAIL;
    case 'Notorious':
      return ServiceMethod.NOTORIOUSLY;
    case 'Personal':
      return ServiceMethod.PERSONALLY;
    case 'Non Est':
      return ServiceMethod.NON_EST;
   case 'Information Only':
      return ServiceMethod.INFORMATION_ONLY;
    default:
      return undefined;
  }
};

export const fileToBase64 = (file:File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const result = reader.result;
        if (typeof result === 'string') {
            resolve(result.split(',')[1]);
        } else {
            reject(new Error('Failed to read file as Base64.'));
        }
    };
    reader.onerror = error => reject(error);
});
};

export const getStatusClassName = (status: string): string => {
   let className = "";
   const baseClass = "w-28 min-h-5 leading-[11px] text-center py-[3px] px-1.5 text-white inline-flex items-center justify-center rounded-sm";

   switch (status) {
      case "Filed":
         className = `bg-sky-200 ${baseClass}`;
         break;
      case "Amended":
         className = `bg-[#FF914D] ${baseClass}`;
         break;
      case "Amendment Ready":
         className = `bg-blue-400 ${baseClass}`;
         break;
      case "Writ Ready":
         className = `bg-[#fd4545] ${baseClass}`;
         break;
      case "Dismissal Applied for":
         className = `bg-[#4DB452] ${baseClass}`;
         break;
      case "Set-Out":
         className = `bg-yellow-300 ${baseClass}`;
         break;
      case "Submitted":
         className = `bg-[#54C1FF] ${baseClass}`;
         break;
      case "Writ Applied for":
         className = `bg-[#E61818] ${baseClass}`;
         break;
      case "Answered":
         className = `bg-[#6D37BB] ${baseClass}`;
         break;
      case "Check for Writ":
         className = `bg-[#F86565] ${baseClass}`;
         break;
      case "Served":
         className = `bg-[#427AC7] ${baseClass}`;
         break;
      case "Submission In Progress":
         className = `bg-yellow-300 ${baseClass}`;
         break;
      case "Dismissal Submission In Progress":
         className = `bg-yellow-300 ${baseClass}`;
         break;
      default:
         className = `bg-black-500 ${baseClass}`;
         break;
   }

   return className;
};

export const convertDateStringToUTCISOString  = (dateString: string): string => {
   const parts = dateString.split('/');
   if (parts.length === 3) {
      const [month, day, year] = dateString.split("/");
      const isoDateString = new Date(Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day), 0, 0, 0)).toISOString();

      return isoDateString;
   }
   throw new Error('Invalid date string format');
};   

export const convertToUTCISOString = (dateString: string): string => {
   const date = new Date(dateString);
   const utcISOString = date.toISOString();
   return utcISOString;
};

export const convertToEasternISOString = (dateString: string): string => {
   // Parse the date using Moment.js
   const date = moment(dateString, 'M/D/YYYY');
   
   // Set the time zone to Eastern Time
   date.tz('America/New_York');
   
   // Format the date as ISO string
   const isoString = date.toISOString();
   
   return isoString;
};


let colorIndex = 0;

export const getRandomColor = () => {
  const color = colors[colorIndex];
  colorIndex = (colorIndex + 1) % colors.length;
  return color;
};

export const centerTextPlugin = {
  id: 'centerText',
  beforeDraw: (chart: any) => {
    if (chart.config.type === 'doughnut') {
      const { width, height, ctx } = chart;
      ctx.restore();
      
      // Set a smaller font size for the middle text
      const fontSize = (height / 200).toFixed(2); // Adjust the denominator to control size
      ctx.font = `${fontSize}em Arial`;
      ctx.textBaseline = 'middle';

      const sum = chart.config.data.datasets[0].data.reduce((acc: number, val: number) => acc + val, 0);
      const text = sum === 0 ? "No Data Found" : `Total: ${sum}`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2;
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }
};


export const GetChartConfiguration = (labels:string[],data: number[],title:string) => {
  const sum = data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const allZero = data.every(count => count === 0);
  const backgroundColors = data.map(() => getRandomColor());

  const config = {
    type: 'doughnut',
    data: {
      labels: labels,
      datasets: [
        {
          label: title,
          data: data,
          backgroundColor: backgroundColors,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: true,
        }
      },
      cutout: 75,
    },
    plugins: [centerTextPlugin],
  };

return config;
};

// Descriptions can be stored in a separate object for better readability and maintainability
const AOSStatusDescriptions: { [key in AOSStatusEnum]: string } = {
   [AOSStatusEnum.NeedToSend]: "NeedToSend",
   [AOSStatusEnum.EmailSent]: "EmailSent",
   [AOSStatusEnum.EmailResent]: "EmailResent",
   [AOSStatusEnum.Modified]: "Modified",
   [AOSStatusEnum.Signed]: "Signed"
};

// Function to get the description
export const getAOSStatusDescription = (status: AOSStatusEnum): string => {
   return AOSStatusDescriptions[status];
};

export const formatCountyList = (counties: string[]): string => {
   if (counties.length === 0) return '';
   if (counties.length === 1) return counties[0];
   const lastCounty = counties.pop();
   return `${counties.join(', ')} and ${lastCounty}`;
};

export const mapDismissalReasonToEnum = (reason: string): DismissalReasonEnum | undefined => {
   return dismissalReasonToEnumMap[reason];
};

export const enumToDismissalReasonString = (reason: DismissalReasonEnum): string => {
   switch (reason) {
      case DismissalReasonEnum.Satisfied:
         return "Satisfied";
      case DismissalReasonEnum.WithPrejudice:
         return "With Prejudice";
      case DismissalReasonEnum.WithoutPrejudice:
         return "Without Prejudice";
      default:
         return "";  // Handle any unexpected values
   }
};

export const formatDateToDateTime = (dateStr: string): Date | null => {
   if (!dateStr) return null;
   
   // Assuming date is in "mm/dd/yyyy" format
   const [month, day, year] = dateStr.split("/");
   return new Date(`${year}-${month}-${day}`);
};

export const classNames = (...classes: string[]) => {
   return classes.filter(Boolean).join(" ");
};

export const getDocumentNameByFilingType = (filingType: number | null): string => {
   switch (filingType) {
      case OperationTypeEnum.Eviction:
         return 'eviction.pdf';
      case OperationTypeEnum.Dismissal:
         return 'dismissal.pdf';
      case OperationTypeEnum.Writ:
         return 'writ.pdf';
      case OperationTypeEnum.Amendment:
         return 'amendment.pdf';
      case OperationTypeEnum.AOS:
         return 'aos.pdf';
      default:
         return ''; // Default fallback name
   }
};

export const convertUtcToEst  = (utcDate: string) => {
  // Parse the UTC date string into a Date object
  const date = new Date(utcDate);

  // Get the system's time zone offset in minutes (positive or negative)
  const offsetInMinutes = date.getTimezoneOffset();

  // Calculate the local time by adjusting the UTC time with the system's offset
  const localDate = new Date(date.getTime() - offsetInMinutes * 60 * 1000); // Convert minutes to milliseconds

  // Format the local date as MM/DD/YYYY
  const formattedDate = `${String(localDate.getMonth() + 1).padStart(2, '0')}/${String(localDate.getDate()).padStart(2, '0')}/${localDate.getFullYear()}`;

  // Get the hours, minutes, and seconds in the local time zone
  let hours = localDate.getHours();
  const minutes = localDate.getMinutes();
  const seconds = localDate.getSeconds();

  // Determine AM/PM and convert 24-hour time to 12-hour format
  const amPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert hour to 12-hour format (0 becomes 12)

  // Format the time as HH:MM:SS AM/PM
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')} ${amPm}`;

  // Return the date and time without showing the time zone name
  return {
    date: formattedDate,  // MM/DD/YYYY format
    time: formattedTime,  // HH:MM:SS AM/PM format
  };
};


export const parseToDecimal = (value: number | string | null | undefined): number => {
   if (value === null || value === undefined) {
       return 0; // Return 0 for null or undefined
   }
   const parsedValue = parseFloat(value as string);
   return isNaN(parsedValue) ? 0 : parsedValue; // Return 0 if parsing fails
}

export const convertToEST = (date: Date) => {
  var a=moment(date).tz("America/New_York").format();
  // Convert the selected date to EST
  return moment(date).tz("America/New_York").format(); // ISO format with EST
};

//Remove Special Character and Alphabets and pick only digits
// export const formatZip = (zip: string): string => {
//   // Remove any non-digit characters and limit to a max of 5 digits
//   const digitsOnly = zip.replace(/\D/g, ''); // Remove non-digit characters
//   return digitsOnly.substring(0, 5); // Return only the first 5 digits
// };

//Remove only special character and pick first five including alphabets and digits
export const formatZip = (zip: string): string => {
  // Remove any special characters, keeping only letters and digits
  const validChars = zip.replace(/[^a-zA-Z0-9]/g, ''); // Remove special characters
  return validChars.substring(0, 5); // Return only the first 5 characters
};