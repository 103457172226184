import React from "react";
import { ChangeEvent, useEffect, useState } from "react";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";
import { useDebounce } from "hooks/useDebounce";
import { useProcessServerContext } from "pages/process-server/ProcessServerContext";

const ProcessServer_SearchBar = () => {
   // will get data from api and set that in the updateProcessServerCases
   const { setProcessServerCases, getProcessServerCases, processServerCases } = useProcessServerContext();
   // search query state
   const [searchQuery, setSearchQuery] = useState<string>('');
   const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');
   // debounced search
   let debouncedSearch = useDebounce(searchQuery, 500, 3);

   useEffect(() => {
      setSearchQuery(processServerCases.searchParam ?? "");
      // eslint-disable-next-line react-hooks/exhaustive-deps
      debouncedSearch = processServerCases.searchParam ?? "";

   }, []);

   // search on the basis of property name, property address, tenant name, case number, or filer email
   const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setSearchQuery(event.target.value);
   };

   const updateProcessServerCases = (searchParam: string) => {
      const trimmedSearchParam = searchParam.trim();
      if (!trimmedSearchParam.length) return;
      setSearchQuery('');
      getProcessServerCases(1, 100, trimmedSearchParam,processServerCases.serverName,
         processServerCases.serviceMethod,
         processServerCases.dateRange);
      setProcessServerCases((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
   };

   const handleSearchIconClick = () => {
      getProcessServerCases(1, 100, searchQuery.trim(),processServerCases.serverName,
      processServerCases.serviceMethod,
      processServerCases.dateRange);
      setProcessServerCases((prev) => ({
         ...prev,
         searchParam: searchQuery.trim(),
      }));
   };

   const handleCrossIconClick = () => {
      setSearchQuery('');
      getProcessServerCases(1, 100, "",processServerCases.serverName,
         processServerCases.serviceMethod,
         processServerCases.dateRange);
      setProcessServerCases((prev) => ({
         ...prev,
         searchParam: "",
      }));
   };

   // useEffect(() => {
   //    const trimmedSearchParam = searchQuery.trim();
   //    if (debouncedSearch) {
   //       setProcessServerCases((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
   //       if (trimmedSearchParam && trimmedSearchParam.length >= 3) {
   //          if(searchedApiQuery  == "")
   //             {
   //                setSearchedApiQuery(trimmedSearchParam);
   //             }
   //          getProcessServerCases(1, 100, trimmedSearchParam);
   //       }
   //    }
   //    else if(trimmedSearchParam.length < searchedApiQuery.length)
   //       {
   //          setSearchedApiQuery(trimmedSearchParam);
   //          getProcessServerCases(1, 100, trimmedSearchParam);
   //          setProcessServerCases((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
   //       } 
   //    else {
   //       updateProcessServerCases('');
   //    }
   // }, [debouncedSearch]);

   useEffect(() => {
      const trimmedSearchParam = searchQuery.trim();
      if (debouncedSearch) {
         setProcessServerCases((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
         if (trimmedSearchParam) {
            if(searchedApiQuery  == "")
               {
                  setSearchedApiQuery(trimmedSearchParam);
               }
            getProcessServerCases(1, 100, trimmedSearchParam,processServerCases.serverName,
               processServerCases.serviceMethod,
               processServerCases.dateRange);
         }
      }
      else if(trimmedSearchParam.length < searchedApiQuery.length)
         {
            setSearchedApiQuery(trimmedSearchParam);
            getProcessServerCases(1, 100, trimmedSearchParam,processServerCases.serverName,
               processServerCases.serviceMethod,
               processServerCases.dateRange);
            setProcessServerCases((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
         } 
      else {
         updateProcessServerCases('');
      }
   }, [debouncedSearch]);
   useEffect(() => {
      if (!processServerCases.searchParam?.length) {
         setSearchQuery('');
      }

   }, [processServerCases.searchParam]);

   return (
      <MultiLineSearch
         value={searchQuery}
         handleInputChange={handleChange}
         handleSearchIconClick={handleSearchIconClick}
         handleCrossIconClick={handleCrossIconClick}
      />
   );
};

export default ProcessServer_SearchBar;
