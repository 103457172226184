import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";
import { useDebounce } from "hooks/useDebounce";
import { useAllCheckCasesContext } from "./CheckCasesContext";

const AllCheckCases_SearchBar = () => {
  const { query } = useParams();
  const [searchQuery, setSearchQuery] = useState<string>(query || "");
  const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');
  const { setAllCheckCases, getAllCheckCases } = useAllCheckCasesContext();
  const debouncedSearch = useDebounce(searchQuery, 500, 3);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSearchQuery(event.target.value);
  };

  const updateAllCheckCases = (searchParam: string) => {
    if (searchParam.length >= 3) {
      getAllCheckCases(1, 100, searchParam);
      setAllCheckCases((prev) => ({ ...prev, searchParam: searchParam }));
    }
  };

  const handleSearchIconClick = () => {
    if (searchQuery.length >= 3) {
      getAllCheckCases(1, 100, searchQuery);
      setAllCheckCases((prev) => ({ ...prev, searchParam: searchQuery }));
    }
  };

  const handleCrossIconClick = () => {
    setSearchQuery('');
    getAllCheckCases(1, 100, "");
    setAllCheckCases((prev) => ({ ...prev, searchParam: "" }));
  };
  // useEffect(() => {
  //   setAllCheckCases((prev) => ({ ...prev, searchParam: debouncedSearch }));
  //   if (debouncedSearch && debouncedSearch.length >= 3) {
  //     if(searchedApiQuery  == "")
  //       {
  //          setSearchedApiQuery(debouncedSearch);
  //       }
  //     getAllCheckCases(1, 100, debouncedSearch);
  //   }
  //   else if(debouncedSearch.length < searchedApiQuery.length)
  //     {
  //        setSearchedApiQuery(debouncedSearch);
  //        getAllCheckCases(1, 100, debouncedSearch);
  //     } 
  //   else {
  //     updateAllCheckCases("");
  //   }
  // }, [debouncedSearch]);

  useEffect(() => {
    setAllCheckCases((prev) => ({ ...prev, searchParam: debouncedSearch }));
    if (debouncedSearch) {
      if(searchedApiQuery  == "")
        {
           setSearchedApiQuery(debouncedSearch);
        }
      getAllCheckCases(1, 100, debouncedSearch);
    }
    else if(debouncedSearch.length < searchedApiQuery.length)
      {
         setSearchedApiQuery(debouncedSearch);
         getAllCheckCases(1, 100, debouncedSearch);
      } 
    else {
      updateAllCheckCases("");
    }
  }, [debouncedSearch]);
  useEffect(() => {
    if (!searchQuery) {
      updateAllCheckCases("");
    }
  }, [searchQuery]);

  return (
    <MultiLineSearch
      value={searchQuery}
      handleInputChange={handleChange}
      handleSearchIconClick={handleSearchIconClick}
      handleCrossIconClick={handleCrossIconClick}
    />
  );
};

export default AllCheckCases_SearchBar;
