import React from "react";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "context/AuthContext";
import { useEvictionQueueContext } from "../EvictionQueueContext";
import Spinner from "components/common/spinner/Spinner";
import TabComponent from "components/common/tabs/tabs";
import EvictionQueueTasksGrid from "../EvictionQueueTasksGrid";

type EvictionQueueTabsProps = {
   //handleBack:()=>void;
};

const EvictionQueueTabs = (props: EvictionQueueTabsProps) => {
   const { userRole } = useAuth();
   const {
      showSpinner,
      evictionQueueTasks,
      evictionQueuesData,
      getEvictionQueuesData,
      getEvictionQueueTasks,
      setSelectedEvictionQueueId,
      setSelectedEvictionId,
      setFilteredRecords,
      getAllCounties,
      getAllCompanies
   } = useEvictionQueueContext();

   const isMounted = useRef(true);

   useEffect(() => {
      if (
         evictionQueuesData &&
         evictionQueuesData.items[selectedTabIndex] &&
         evictionQueuesData.items[selectedTabIndex].id
      ) {
         getEvictionQueueTasks(1, 100, evictionQueuesData.items[selectedTabIndex].id, evictionQueueTasks.actiontype ?? 0, evictionQueueTasks.status ?? 0, evictionQueueTasks.searchParam, evictionQueueTasks.county, evictionQueueTasks.company);
         setSelectedEvictionQueueId(evictionQueuesData.items[selectedTabIndex].id);
      }
   }, [evictionQueuesData, userRole]);

   useEffect(() => {
      if (isMounted.current) {
         getEvictionQueuesData(1, 100);
         getAllCounties();
         getAllCompanies();
         isMounted.current = false;
      }
   }, [userRole]);

   const [tabName, setTabName] = useState("");
   const [selectedTabIndex, setSelectedTabIndex] = useState(0);

   return (
      <div className="relative -mr-0.5">
         {showSpinner && <Spinner />}
         <>
            <div className="mb-1.5">
               {<TabComponent
                  selectedTabIndex={selectedTabIndex}
                  onTabSelect={(index: number) => {
                     setTabName(evictionQueuesData.items[index].name)
                     setSelectedTabIndex(index)
                     setSelectedEvictionQueueId(evictionQueuesData.items[index].id)
                     setSelectedEvictionId([])
                     setFilteredRecords([])
                  }}
                  tabs={evictionQueuesData.items.map((tab, index) => ({
                     id: tab.id,
                     name: tab.name,
                     content: <EvictionQueueTasksGrid activeTab={tab.name}></EvictionQueueTasksGrid>
                  }))}
               ></TabComponent>}
            </div>
         </>
      </div>
   );
}
export default EvictionQueueTabs;
