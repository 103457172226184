import axios from "middlewares/axiosInstance";
import {
  IPropertyInfoAddress,
  ISearchCasesRequest,
  IAllCheckCases
} from "interfaces/case.interface";
import { AxiosResponse } from "axios";

/**
 * @param searchCaseRequest  model contains search Param
 * @param county on the basis dropdown available on check case status screen
 * @returns  cases based on tenant name, property name
 */
const searchCases = async (searchCaseRequest: ISearchCasesRequest) => {
  const response: AxiosResponse<IPropertyInfoAddress[]> = await axios<
    IPropertyInfoAddress[]
  >({
    method: "POST",
    url: "/api/AllCase/GetCasesByCountyAndParam",
    data: searchCaseRequest,
  });

  return response as AxiosResponse<IPropertyInfoAddress[]>;
};

const getAllCheckCases = async (
  pageNumber: number,
  pageSize: number,
  searchParam?: string
) => {
  
  const queryParams = `?searchParam=${searchParam ?? ''}`;
  const response: AxiosResponse<IAllCheckCases> = await axios<IAllCheckCases>({
    method: "GET",
    url: `/api/AllCase/GetCases/${pageNumber}/${pageSize}${queryParams}`,
  });

  return response as AxiosResponse<IAllCheckCases>;
};

const CaseStatusService = {
  searchCases,
  getAllCheckCases,
};
export default CaseStatusService;
