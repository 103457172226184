import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ProcessServerProvider } from "./ProcessServerContext";
import { useAuth } from "context/AuthContext";
import ProcessServerGrid from "./components/ProcessServerGrid";
import ProcessServerSearchBar from "./components/ProcessServerActions/ProcessServer_SearchBar";
import ProcessServerButtons from "./components/ProcessServerButtons";
import ProcessServerSearchFilters from "./components/ProcessServer_SearchFilters";
import { ProcessServerButtonsList } from "utils/constants";
import { UserRole } from "utils/enum";
import ReviewSign from "./ReviewSign";

type ProcessServerProps = {};

const ProcessServer = (props: ProcessServerProps) => {
   const { userRole } = useAuth();
   const location = useLocation();
   const [dispoIds, setDispoIds] = useState<string[]>([]);
   const [showReviewSign, setShowReviewSign] = useState<boolean>(false);

   useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      if (location.pathname === "/process-server-sign" && searchParams.has('dispoIds')) {
         setShowReviewSign(true);
         setDispoIds(searchParams.get('dispoIds')?.split(',') || []);
      }

   }, [location.pathname, location.search]);

   return (
      <ProcessServerProvider>
         {!showReviewSign && (
            <>
               <div className="relative flex flex-wrap items-center mb-1.5">
                  <ProcessServerSearchBar />
                  <ProcessServerButtons
                     buttons={ProcessServerButtonsList}
                     handleClick={() => { }}
                  />
               </div>
               {userRole.includes(UserRole.C2CAdmin) ||userRole.includes(UserRole.ChiefAdmin)? <ProcessServerSearchFilters /> : null}
               <div className="process_grid">
                  <ProcessServerGrid />
               </div>
            </>
         )}

         {showReviewSign && (
            <ReviewSign
               dispoIds={dispoIds}
               showReviewSign={showReviewSign}
               setShowReviewSign={() => setShowReviewSign(false)}
            />
         )}

      </ProcessServerProvider>
   );
};

export default ProcessServer;