import React, { ChangeEvent, useEffect, useState } from "react";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";
import { useDebounce } from "hooks/useDebounce";
import { useAmendmentsContext } from "../../AmendmentsContext";

type Amendments_SearchBarProps = {
   activeTab?: string;
};

const Amendments_SearchBar = (props: Amendments_SearchBarProps) => {
   // State for holding the search query
   const [searchQuery, setSearchQuery] = useState<string>("");
   const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');

   // Debounce the search query
   const debouncedSearch = useDebounce(searchQuery, 500);

   // Get necessary functions from the context
   // const { setAllAmendments, getAllAmendments } = useAmendmentsContext();
   const {
      signedAmendment,
      getAllAmendments,
      setAllUnsignedAmendment,
      setAllSignedAmendment,
      unsignedAmendment,
      unservedAmendment,
      getAllUnservedAmendment,
      setAllUnservedAmendment
   } = useAmendmentsContext();

   useEffect(() => {
      if (props.activeTab === "Ready to Sign") {
         setSearchQuery(unsignedAmendment.searchParam ?? "");
      } else if (props.activeTab === "Signed") {
         setSearchQuery(signedAmendment.searchParam ?? "");
      }
      else if (props.activeTab === "Unserved Amendments") {
         setSearchQuery(unservedAmendment.searchParam ?? "");
      }

   }, []);

   // Handle change in the search input
   const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setSearchQuery(event.target.value);
   };

   // Update amendments when the debounced search query changes
   const updateAmendments = (searchParam: string) => {
      const trimmedSearchParam = searchParam.trim();
      if (!trimmedSearchParam.length) return;
      setSearchQuery('');
      if (props.activeTab === "Ready to Sign") {
         getAllAmendments(1, 100, false, trimmedSearchParam);
      };
      if (props.activeTab === "Signed") {
         getAllAmendments(1, 100, true, trimmedSearchParam);
      };
      if (props.activeTab === "Unserved Amendments") {
         getAllUnservedAmendment(1, 100, trimmedSearchParam);
      };
      setAllUnsignedAmendment((prevAmendments) => ({
         ...prevAmendments,
         searchParam: trimmedSearchParam,
      }));
      setAllSignedAmendment((prevAmendments) => ({
         ...prevAmendments,
         searchParam: trimmedSearchParam,
      }));
      setAllUnservedAmendment((prevAmendments) => ({
         ...prevAmendments,
         searchParam: trimmedSearchParam,
      }));
   };


   const handleSearchIconClick = () => {
      const trimmedSearchParam = searchQuery.trim();
      setAllSignedAmendment((prev) => ({
         ...prev,
         searchParam: trimmedSearchParam,
      }));
      setAllUnsignedAmendment((prev) => ({
         ...prev,
         searchParam: trimmedSearchParam,
      }));
      setAllUnservedAmendment((prev) => ({
         ...prev,
         searchParam: trimmedSearchParam,
      }));
      // if (props.activeTab === "Signed") {
      getAllAmendments(1, 100, true, "");
      // } else {
      getAllAmendments(1, 100, false, "");
      getAllUnservedAmendment(1, 100, "");
      // }
   };

   const handleCrossIconClick = () => {
      setSearchQuery("");
      setAllSignedAmendment((prev) => ({
         ...prev,
         searchParam: "",
      }));
      setAllUnsignedAmendment((prev) => ({
         ...prev,
         searchParam: "",
      }));
      setAllUnservedAmendment((prev) => ({
         ...prev,
         searchParam: "",
      }));
      // if (props.activeTab === "Signed") {
      getAllAmendments(1, 100, true, "");

      // } else {
      getAllAmendments(1, 100, false, "");
      getAllUnservedAmendment(1, 100, "");
      // }
   };

   // useEffect to trigger the API call when the debounced search query changes
   // useEffect(() => {
   //    const trimmedSearchParam = searchQuery.trim();
   //    if (debouncedSearch.trim()) {
   //       setAllUnsignedAmendment((prevDismissals) => ({
   //          ...prevDismissals,
   //          searchParam: trimmedSearchParam
   //       }));
   //       setAllSignedAmendment((prevAmendments) => ({
   //          ...prevAmendments,
   //          searchParam: trimmedSearchParam,
   //       }));

   //       if (trimmedSearchParam && trimmedSearchParam.length >= 3) {
   //          if(searchedApiQuery  == "")
   //             {
   //                setSearchedApiQuery(trimmedSearchParam);
   //             }
   //          getAllAmendments(1, 100, props.activeTab === "Unsigned" ? false : true, trimmedSearchParam);
   //          getAllAmendments(1, 100, props.activeTab === "Signed" ? false : true, trimmedSearchParam);
   //          setAllUnsignedAmendment((prevAmendments) => ({
   //             ...prevAmendments,
   //             searchParam: trimmedSearchParam,
   //          }));
   //       }      
   //       }
   //       else if(trimmedSearchParam.length < searchedApiQuery.length)
   //          {
   //             setSearchedApiQuery(trimmedSearchParam);
   //             getAllAmendments(1, 100, props.activeTab === "Unsigned" ? false : true, trimmedSearchParam);
   //          // getAllAmendments(1, 100, props.activeTab === "Signed" ? false : true, trimmedSearchParam);
   //          setAllUnsignedAmendment((prevAmendments) => ({
   //             ...prevAmendments,
   //             searchParam: trimmedSearchParam,
   //          }));
   //          setAllSignedAmendment((prevAmendments) => ({
   //             ...prevAmendments,
   //             searchParam: trimmedSearchParam,
   //          }));
   //          }
   //     else {
   //       updateAmendments("");
   //    }
   // }, [debouncedSearch]);

   useEffect(() => {
      const trimmedSearchParam = searchQuery.trim();
      if (debouncedSearch.trim()) {
         setAllUnsignedAmendment((prevDismissals) => ({
            ...prevDismissals,
            searchParam: trimmedSearchParam
         }));
         setAllSignedAmendment((prevAmendments) => ({
            ...prevAmendments,
            searchParam: trimmedSearchParam,
         }));
         setAllUnservedAmendment((prevAmendments) => ({
            ...prevAmendments,
            searchParam: trimmedSearchParam,
         }));

         if (trimmedSearchParam) {
            if (searchedApiQuery == "") {
               setSearchedApiQuery(trimmedSearchParam);
            }
            if (props.activeTab != "Unserved Amendments") {
               getAllAmendments(1, 100, props.activeTab === "Ready to Sign" ? false : true, trimmedSearchParam);
               getAllAmendments(1, 100, props.activeTab === "Signed" ? false : true, trimmedSearchParam);
               setAllUnsignedAmendment((prevAmendments) => ({
                  ...prevAmendments,
                  searchParam: trimmedSearchParam,
               }));
            }
            else {
               getAllUnservedAmendment(1, 100, trimmedSearchParam);
               setAllUnservedAmendment((prevAmendments) => ({
                  ...prevAmendments,
                  searchParam: trimmedSearchParam,
               }));
            }
         }
      }
      else if (trimmedSearchParam.length < searchedApiQuery.length) {
         setSearchedApiQuery(trimmedSearchParam);
         if (props.activeTab != "Unserved Amendments") {
            getAllAmendments(1, 100, props.activeTab === "Ready to Sign" ? false : true, trimmedSearchParam);
            // getAllAmendments(1, 100, props.activeTab === "Signed" ? false : true, trimmedSearchParam);
            setAllUnsignedAmendment((prevAmendments) => ({
               ...prevAmendments,
               searchParam: trimmedSearchParam,
            }));
            setAllSignedAmendment((prevAmendments) => ({
               ...prevAmendments,
               searchParam: trimmedSearchParam,
            }));
         }
         else {
            getAllUnservedAmendment(1, 100, trimmedSearchParam);
            setAllUnservedAmendment((prevAmendments) => ({
               ...prevAmendments,
               searchParam: trimmedSearchParam,
            }));
         }
      }
      else {
         updateAmendments("");
      }
   }, [debouncedSearch]);

   useEffect(() => {
      if (!unsignedAmendment.searchParam?.length) {
         setSearchQuery('');
      }

      if (!signedAmendment.searchParam?.length) {
         setSearchQuery('');
      }
      if (!unservedAmendment.searchParam?.length) {
         setSearchQuery('');
      }

   }, [unsignedAmendment.searchParam, signedAmendment.searchParam, unservedAmendment.searchParam]);

   useEffect(() => {
      setSearchQuery('');
      setAllUnsignedAmendment((prevAmendments) => ({
         ...prevAmendments,
         searchParam: '',
      }));
      setAllSignedAmendment((prevAmendments) => ({
         ...prevAmendments,
         searchParam: '',
      }));
      setAllUnservedAmendment((prevAmendments) => ({
         ...prevAmendments,
         searchParam: '',
      }));
   }, [props.activeTab]);

   // Render the MultiLineSearch component
   return (
      <MultiLineSearch
         value={searchQuery}
         handleInputChange={handleChange}
         handleSearchIconClick={handleSearchIconClick}
         handleCrossIconClick={handleCrossIconClick}
      />
   );
};

export default Amendments_SearchBar;
