import React from 'react';
import { IFiler } from 'interfaces/common.interface';

type FilerInfoCardProps = {
   filerInfo: IFiler;
};

const FilerInfoCard: React.FC<FilerInfoCardProps> = ({ filerInfo }: FilerInfoCardProps) => {
   return (
      <div className='p-3.5 bg-white border border-gray-200 rounded shadow relative'>
         <h2 className='text-sm font-medium absolute bg-white top-[-8px] left-2.5 px-1.5 !leading-4'>Filer Information</h2>
         <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1.5'>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>FilerBusinessName </strong> {filerInfo?.filerBusinessName}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>EvictionFilerEmail </strong> {filerInfo?.filerEmail}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>EvictionFilerPhone </strong> {filerInfo?.filerPhone}
            </div>
         </div>
      </div>
   );
};

export default FilerInfoCard;
