import React, {
   Dispatch,
   ReactNode,
   SetStateAction,
   createContext,
   useContext,
   useState,
} from "react";
import { ICompanyItems } from "interfaces/all-users.interface";
import { IAccountingQueue, IFilingTransaction, UpdatePaymentResource } from "interfaces/filing-transaction.interface";
import AllUsersService from "services/all-users.service";
import FilingTransactionService from "services/filing-transaction.service";
import { CourtDecisionEnum, HttpStatusCode, OperationTypeEnum } from "utils/enum";

// Define the shape of the context data
type FilingTransactionContextType = {
   showSpinner: boolean;
   setShowSpinner: Dispatch<SetStateAction<boolean>>;
   //  filingType: OperationTypeEnum;
   //  setFilingType: Dispatch<SetStateAction<OperationTypeEnum>>;
   filingType: string;
   setFilingType: Dispatch<SetStateAction<string>>;
   companyId: string;
   setCompanyId: Dispatch<SetStateAction<string>>;
   dateFiled: [Date | null, Date | null];
   datePaid: [Date | null, Date | null];
   setDateFiled: Dispatch<SetStateAction<[Date | null, Date | null]>>;
   setDatePaid: Dispatch<SetStateAction<[Date | null, Date | null]>>;
   filingTransactions: IFilingTransaction;
   setFilingTransactions: Dispatch<SetStateAction<IFilingTransaction>>;
   getFilingTransactions: (pageNumber: number, pageSize: number, searchParam?: string | null, type?: string | null, companyId?: string, fromDate?: Date | null,
      toDate?: Date | null , datePaidFromDate?: Date | null, datePaidToDate?: Date | null, blankOption?: string[]) => void;
      accountingQueue: IAccountingQueue;
   setAccountingQueue: Dispatch<SetStateAction<IAccountingQueue>>;
   getAccountingQueue: (pageNumber: number, pageSize: number, searchParam?: string | null, 
      datePaidFromDate?: Date | null, datePaidToDate?: Date | null, type?: string, 
      payrollFromDate?: Date | null, payrollToDate?: Date | null, 
      commissionFromDate?: Date | null, commissionToDate?: Date | null,
      blankOption?: string[]) => void;
   allCompanies: ICompanyItems[];
   getAllCompanies: () => void;
   setAllCompanies: Dispatch<SetStateAction<ICompanyItems[]>>;
   selectedFilingTransactionId: string[];
   setSelectedFilingTransactionId: Dispatch<SetStateAction<string[]>>;
   bulkRecords: UpdatePaymentResource[];
   setBulkRecords: Dispatch<SetStateAction<UpdatePaymentResource[]>>;
   filteredRecords: UpdatePaymentResource[];
   setFilteredRecords: Dispatch<SetStateAction<UpdatePaymentResource[]>>;
   selectedFilteredFilingTransactionId: string[];
   setSelectedFilteredFilingTransactionId: Dispatch<SetStateAction<string[]>>;
};

// Create a context with an initial value
const initialFilingTransactionContextValue: FilingTransactionContextType = {
   filingTransactions: {
      items: [
         {
            id: "",
            dispoId: "",
            clientId: "", // Ensure this is included
            caseNumber: "", // Ensure this is included
            signedDispo: null,
            operationType: OperationTypeEnum.Eviction,
            courtDecision: CourtDecisionEnum.Accepted,
            applicantDate: "",
            filedDate: "",
            courtTransAmount: null,
            paymentAmount: 0,
            invoiceDate: "",
            invoiceNumber: "",
            datePaid: "",
            checkNumber: "",
            hasInvoiceCreated: false,
            tenantNames: [],
            county: "",
            issueDate: "",
            expedited: "",
            companyName: null,
            address: null,
            tenantFirstName: "",
            tenantLastName: "",
            clientReferenceId: null,
            serverReceived: null,
            serviceDate: null,
            serviceDayCal: null,
            house: null,
            addtlTenants: null,
            andAllOtherOccupants: null,
            stateCourt: "",
            eFileMethod: "",
            paymentNotes: null,
            eFileClientFee: null,
            expFee: null,
            c2CFilingFee: null,
            sheriffFee: null,
            c2CServiceFee: null,
            automationFee: null,
            payPalFee: null,
            payPalManual: null,
            propertyName: "",
            personalService:""
         },
      ],
      currentPage: 1,
      pageSize: 100,
      totalCount: 0,
      totalPages: 0,
   },
   accountingQueue: {
      items: [
         {
            id: "",
            dispoId: "",
            clientId: "", // Ensure this is included
            caseNumber: "", // Ensure this is included
            signedDispo: null,
            operationType: OperationTypeEnum.Eviction,
            courtDecision: CourtDecisionEnum.Accepted,
            applicantDate: "",
            filedDate: "",
            courtTransAmount: null,
            paymentAmount: 0,
            invoiceDate: "",
            invoiceNumber: "",
            datePaid: "",
            checkNumber: "",
            hasInvoiceCreated: false,
            tenantNames: [],
            county: "",
            issueDate: "",
            expedited: "",
            companyName: null,
            address: null,
            tenantFirstName: "",
            tenantLastName: "",
            clientReferenceId: null,
            serverReceived: null,
            serviceDate: null,
            serviceDayCal: null,
            house: null,
            addtlTenants: null,
            andAllOtherOccupants: null,
            stateCourt: "",
            eFileMethod: "",
            paymentNotes: null,
            eFileClientFee: null,
            expFee: null,
            c2CFilingFee: null,
            sheriffFee: null,
            c2CServiceFee: null,
            automationFee: null,
            payPalFee: null,
            payPalManual: null,
            propertyName: "",
            type: "",
   filerEmail: "",
   envelopeId: "",
   paymentMethod: "",
   processServerCompany: "",
   paidServer: null,
   serverSignature: "",
   personalService: "",
   serviceMethod: "",
   payrollDate: null,
   commissionDate: null,
   serverPayAdminNotes: null,
         },
      ],
      currentPage: 1,
      pageSize: 100,
      totalCount: 0,
      totalPages: 0,
   },
   allCompanies: [],
   getAllCompanies: () => { },
   setAllCompanies: () => { },
   showSpinner: false,
   filingType: "Eviction",
   setFilingType: () => { },
   companyId: "",
   setCompanyId: () => { },
   dateFiled: [null,null],
   setDateFiled: () => { },
   datePaid: [null,null],
   setDatePaid: () => { },
   setShowSpinner: () => { },
   getFilingTransactions: () => { },
   setFilingTransactions: () => { },
   getAccountingQueue: () => { },
   setAccountingQueue: () => { },
   selectedFilingTransactionId: [],
   setSelectedFilingTransactionId: () => { },
   bulkRecords: [],
   setBulkRecords: () => { },
   filteredRecords: [],
   setFilteredRecords: () => { },
   selectedFilteredFilingTransactionId: [],
   setSelectedFilteredFilingTransactionId: () => { },
};

// Create a context with an initial value
const FilingTransactionContext = createContext<FilingTransactionContextType>(
   initialFilingTransactionContextValue
);

// Provide a component to wrap the application and make the context available
export const FilingTransactionProvider: React.FC<{ children: ReactNode }> = ({
   children,
}) => {


   // State to hold the spinner
   const [showSpinner, setShowSpinner] = useState<boolean>(false);
   const [filingTransactions, setFilingTransactions] = useState<IFilingTransaction>(
      initialFilingTransactionContextValue.filingTransactions
   );
   const [accountingQueue, setAccountingQueue] = useState<IAccountingQueue>(
      initialFilingTransactionContextValue.accountingQueue
   );
   const [filingType, setFilingType] = useState<string>(
      initialFilingTransactionContextValue.filingType
   );
   const [companyId, setCompanyId] = useState<string>(
      initialFilingTransactionContextValue.companyId
   );
   const [dateFiled, setDateFiled] = useState<[Date | null, Date | null]>(
      initialFilingTransactionContextValue.dateFiled
   );
   const [datePaid, setDatePaid] = useState<[Date | null, Date | null]>(
      initialFilingTransactionContextValue.datePaid
   );
   const [allCompanies, setAllCompanies] = useState<ICompanyItems[]>(
      initialFilingTransactionContextValue.allCompanies
   );
   const [selectedFilingTransactionId, setSelectedFilingTransactionId] = useState<string[]>(
      initialFilingTransactionContextValue.selectedFilingTransactionId
   );
   const [bulkRecords, setBulkRecords] = useState<UpdatePaymentResource[]>([]);
   const [selectedFilteredFilingTransactionId, setSelectedFilteredFilingTransactionId] = useState<string[]>(
      initialFilingTransactionContextValue.selectedFilteredFilingTransactionId
   );
   const [filteredRecords, setFilteredRecords] = useState<UpdatePaymentResource[]>([]);


   const getFilingTransactions = async (pageNumber: number, pageSize: number, searchParam?: string | null, type?: string | null, companyId?: string, fromDate?: Date | null,
      toDate?: Date | null , datePaidFromDate?: Date | null,datePaidToDate?: Date | null, blankOption?: string[]) => {
      try {
         setShowSpinner(true);
         const apiResponse = await FilingTransactionService.getFilingTransactions(pageNumber, pageSize, searchParam, type, companyId, fromDate,
            toDate, datePaidFromDate, datePaidToDate,blankOption);
         if (apiResponse.status === HttpStatusCode.OK) {
            setFilingTransactions((prev) => ({
               ...prev,
               items: apiResponse.data.items,
               currentPage: apiResponse.data.currentPage,
               totalCount: apiResponse.data.totalCount,
               totalPages: apiResponse.data.totalPages,
               pageSize: apiResponse.data.pageSize,
               ...(searchParam ? { searchParam: searchParam } : {}),
            }));
         }
      } finally {
         setShowSpinner(false);
      }
   };

   const getAccountingQueue = async (pageNumber: number, pageSize: number, searchParam?: string | null
      , datePaidFromDate?: Date | null,datePaidToDate?: Date | null, type?: string, 
      payrollFromDate?: Date | null, payrollToDate?: Date | null, 
      commissionFromDate?: Date | null, commissionToDate?: Date | null,
      blankOption?: string[]) => {
      try {
         setShowSpinner(true);
         const apiResponse = await FilingTransactionService.getAccountingQueue(pageNumber, pageSize, searchParam
            , datePaidFromDate, datePaidToDate,type,payrollFromDate,payrollToDate,commissionFromDate,commissionToDate,blankOption);
         if (apiResponse.status === HttpStatusCode.OK) {
            setAccountingQueue((prev) => ({
               ...prev,
               items: apiResponse.data.items,
               currentPage: apiResponse.data.currentPage,
               totalCount: apiResponse.data.totalCount,
               totalPages: apiResponse.data.totalPages,
               pageSize: apiResponse.data.pageSize,
               ...(searchParam ? { searchParam: searchParam } : {}),
            }));
         }
      } finally {
         setShowSpinner(false);
      }
   };
   const getAllCompanies = async () => {
      try {
         // setShowSpinner(true);
         // get late notices
         const apiResponse = await AllUsersService.getAllCompaniesList();
         if (apiResponse.status === HttpStatusCode.OK) {
            setAllCompanies(apiResponse.data);
         }
      } finally {
         // setShowSpinner(false);
      }
   };

   // Provide the context value to its children
   return (
      <FilingTransactionContext.Provider
         value={{
            showSpinner,
            setShowSpinner,
            filingType,
            setFilingType,
            companyId,
            setCompanyId,
            dateFiled,
            setDateFiled,
            datePaid,
            setDatePaid,
            filingTransactions,
            getFilingTransactions,
            setFilingTransactions,
            accountingQueue,
            getAccountingQueue,
            setAccountingQueue,
            allCompanies,
            getAllCompanies,
            setAllCompanies,
            selectedFilingTransactionId,
            setSelectedFilingTransactionId,
            bulkRecords,
            setBulkRecords,
            filteredRecords,
            setFilteredRecords,
            selectedFilteredFilingTransactionId,
            setSelectedFilteredFilingTransactionId,
         }}
      >
         {children}
      </FilingTransactionContext.Provider>
   );
};

// Create a hook to easily access the context within components
export const useFilingTransactionContext = (): FilingTransactionContextType => {
   // Get the context value using useContext
   const context = useContext(FilingTransactionContext);
   // If the context is not found, throw an error
   if (!context) {
      throw new Error(
         "useFillingTransactionContext must be used within a FillingTransactionProvider"
      );
   }
   // Return the context value
   return context;
};
