import { AxiosResponse } from "axios";
import axios from "middlewares/axiosInstance";
import { 
   DocumentReviewResponse, 
   IProcessServerCaseInfoEditRequest, 
   IProcessServerCaseInfoImportRequest, 
   IProcessServerCaseInfoItem, 
   IProcessServerCaseInfoResponse, 
   IProcessServerRequest, 
   IServerCaseInfoResource, 
   ISignAOSResource, 
   ProcessServerExportItem
} from "interfaces/process-server.interface";

const createProcessServer = async (payload: IProcessServerRequest) => {
   const response: AxiosResponse =
      await axios({
         method: "POST",
         url: `api/ProcessServer`,
         data: payload,
      });

   return response as AxiosResponse;
};

const getProcessServerUsers = async () => {
   // const queryParams = `?search=${searchParam}`;
   const response: AxiosResponse =
      await axios({
         method: "POST",
         url: `api/ProcessServer/GetAllProcessServer`,
      });

   return response as AxiosResponse;
};

const getAllProcessServerUsers = async () => {
   const response: AxiosResponse =
      await axios({
         method: "POST",
         url: `api/ProcessServer/GetAllProcessServer`,
      });

   return response as AxiosResponse;
};

// api to get process server assigned geographical area in settings tab
const getProcessServers = async (currentPage: number, pageSize: number, searchParam: string = '') => {
   // const queryParams = `?search=${searchParam}`;
   const response: AxiosResponse =
      await axios({
         method: "GET",
         url: `api/ProcessServer/GetProcessServer/${currentPage}/${pageSize}`,
      });

   return response as AxiosResponse;
};

const editProcessServerUser = async (payload: IProcessServerRequest[]) => {
   const response: AxiosResponse =
      await axios({
         method: "PUT",
         url: `api/ProcessServer`,
         data: payload,
      });

   return response as AxiosResponse;
};

const deleteProcessServerUser = async (id: string) => {
   const response: AxiosResponse =
      await axios({
         method: "DELETE",
         url: `api/ProcessServer/${id}`
      });

   return response as AxiosResponse;
};

const importProcessServerCaseInfo = async (payload: IServerCaseInfoResource) => {
   const response: AxiosResponse =
      await axios({
         method: "POST",
         url: `api/ProcessServer/AddProcessServerCase`,
         data: payload,
      });

   return response as AxiosResponse;
};

const getProcessServerCases = async (
   currentPage: number,
   pageSize: number,
   searchParam: string = '',
   serverName: string = '',
   serviceMethod: string = '',
   dateRange: string = ''
) => {
   const queryParams = new URLSearchParams({
      searchParam,
      serverName,
      serviceMethod,
      dateRange
   }).toString();

   const response: AxiosResponse =
      await axios({
         method: "GET",
         url: `api/ProcessServer/ProcessServerCaseInfo/${currentPage}/${pageSize}/?${queryParams}`,
      });

   return response as AxiosResponse;
};

/**
 * delete ProcessServer
 * @returns
 */
const deleteProcessServer = async (processServerIDs: string[]) => {
   const response: AxiosResponse<IProcessServerCaseInfoItem[]> = await axios<
   IProcessServerCaseInfoItem[]
   >({
      method: "DELETE",
      url: `/api/ProcessServer/RemoveProcessServer`,
      data: processServerIDs,
   });

   return response as AxiosResponse<IProcessServerCaseInfoItem[]>;
};

const editProcessServerCaseInfo = async (payload: IProcessServerCaseInfoEditRequest) => {
   const response: AxiosResponse<IProcessServerCaseInfoResponse> =
      await axios({
         method: "PUT",
         url: `api/ProcessServer/EditProcessServerCaseInfo`,
         data: payload
      });

   return response as AxiosResponse<IProcessServerCaseInfoResponse>;
};

const editProcessServerCaseInfoBulk = async (payload: IProcessServerCaseInfoItem[]) => {
   const response: AxiosResponse =
      await axios({
         method: "PUT",
         url: `api/ProcessServer/EditProcessServerCaseInfoBulk`,
         data: payload
      });

   return response as AxiosResponse;
};

const getProcessServerCasesById = async (id: string) => {
   const response: AxiosResponse =
      await axios({
         method: "GET",
         url: `api/ProcessServer/ProcessServerCaseInfo/${id}`,
      });

   return response as AxiosResponse;
};
const getProcessServerDocuments = async (currentPage: number, pageSize: number, searchParam: string = '') => {
   // const queryParams = `?search=${searchParam}`;
   const response: AxiosResponse =
      await axios({
         method: "GET",
         url: `api/ProcessServer/GetProcessServerDocuments/${currentPage}/${pageSize}`,
      });

   return response as AxiosResponse;
};

const sendForSignature = async (payload: string[]) => {
   const response: AxiosResponse =
      await axios({
         method: "POST",
         url: `api/ProcessServer/SendForSignature`,
         data: payload,
      });

   return response as AxiosResponse;
};
const exportProcessServer = async (serverIds: string[],searchParam: string = '',
   serverName: string = '',
   serviceMethod: string = '',
   dateRange: string = '') => {
      const queryParams = new URLSearchParams({
         searchParam,
         serverName,
         serviceMethod,
         dateRange
      }).toString();
   const response: AxiosResponse<ProcessServerExportItem[]> =
     await axios<ProcessServerExportItem[]>({
       method: "POST",
       url: `api/ProcessServer/ExportAllProcessServer?${queryParams}`,
       data: serverIds,
     });
 
   return response as AxiosResponse<ProcessServerExportItem[]>;
};

const getDocumentsForSignature = async (payload: string[]) => {
   const response: AxiosResponse<DocumentReviewResponse> =
      await axios<DocumentReviewResponse>({
         method: "POST",
         url: `api/ProcessServer/GetDocumentsForSignature`,
         data: payload,
      });

   return response as AxiosResponse<DocumentReviewResponse>;
};

const signAOSDocuments = async (payload: ISignAOSResource) => {
   const response: AxiosResponse =
      await axios({
         method: "POST",
         url: `api/ProcessServer/SignAOS`,
         data: payload,
      });

   return response as AxiosResponse;
};

const ProcessServerService = {
   createProcessServer,
   editProcessServerUser,
   deleteProcessServerUser,
   getProcessServerUsers,
   getProcessServers,
   importProcessServerCaseInfo,
   getProcessServerCases,
   deleteProcessServer,
   exportProcessServer,
   editProcessServerCaseInfo,
   editProcessServerCaseInfoBulk,
   getProcessServerCasesById,
   getProcessServerDocuments,
   getAllProcessServerUsers,
   sendForSignature,
   getDocumentsForSignature,
   signAOSDocuments
};

export default ProcessServerService;