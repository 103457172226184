import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormikHelpers } from "formik";
import Spinner from "components/common/spinner/Spinner";
import ReviewSignDocument from "components/common/documentReview/ReviewSign";
import Spinner2 from "components/common/spinner/Spinner2";
import ProcessServerService from "services/process-server.service";
import { HttpStatusCode, UserRole } from "utils/enum";
import { ISignAOSResource } from "interfaces/process-server.interface";
import { useAuth } from "context/AuthContext";

type ReviewSignProps = {
   dispoIds: string[];
   showReviewSign: boolean;
   setShowReviewSign: (show: boolean) => void;
};

const ReviewSign = (props: ReviewSignProps) => {
   const navigate = useNavigate();
   const { userRole } = useAuth();
   const isMounted = useRef(true);
   const [pdfLink, setPdfLink] = useState<string>("");
   const [pdfCount, setPdfCount] = useState<number>(0);
   const [showSpinner, setShowSpinner] = useState<boolean>(false);
   const [showSigningSpinner, setShowSigningSpinner] = useState<boolean>(false);

   useEffect(() => {
      if (isMounted.current) {
         getDocumentsForSignature();
         isMounted.current = false;
      }

      return () => {
         isMounted.current = false;
      };

   }, []);

   const getDocumentsForSignature = async () => {
      try {
         setShowSpinner(true);
         const response = await ProcessServerService.getDocumentsForSignature(props.dispoIds);
         if (response.status === HttpStatusCode.OK) {
            if(response.data.isSuccess === false) {
               toast.info("No AOS documents were found, or the case may have already been signed.");
            }
            setPdfLink(response?.data?.combinedPdfUrl);
            setPdfCount(response.data.pdfCount);
         } else {
            console.error("Failed to fetch documents for signature");
         }
      } catch (error) {
         console.error("An error occurred while fetching documents for signature", error);
      } finally {
         setShowSpinner(false);
      }
   };

   const handleSignature = useCallback(async (formValues: any) => {
      try {
         setShowSigningSpinner(true);
         setShowSpinner(true);
         // Ensure formValues is a string
         const signValue = formValues?.sign.toString() || "";

         const resource: ISignAOSResource = {
            sign: signValue,
            dispoIds: props.dispoIds
         };

         const response = await ProcessServerService.signAOSDocuments(resource);
         if (response.status === HttpStatusCode.OK) {
            setPdfCount(0);
            setPdfLink("");
            toast.success(response.data.message);
            props.setShowReviewSign(false);
            if(userRole.includes(UserRole.C2CAdmin)||userRole.includes(UserRole.ChiefAdmin))
               navigate("/all-cases");
            else
               navigate("/home");
         } else {
            toast.error(response.data.message);
         }
      } catch (error) {
         console.log(error);
      } finally {
         setShowSigningSpinner(false);
         setShowSpinner(false);
      }

   }, []);

   const handleBack = () => {
      props.setShowReviewSign(false);
      if(userRole.includes(UserRole.C2CAdmin)||userRole.includes(UserRole.ChiefAdmin))
         navigate("/all-cases");
      else
         navigate("/home");
   };

   return (
      <>
         {(showSpinner) && <Spinner />}

         {/* {showSigningSpinner && <Spinner2
            showLabel={showSigningSpinner}
            labelText={"Your cases are submitting to the court. This may take a few moments. Please remain on this screen until the process is complete."}
         ></Spinner2>} */}

         <ReviewSignDocument
            handleBack={handleBack}
            handleSignature={(values: {
               sign: string;
            }, formikHelpers: FormikHelpers<{
               sign: string;
            }>) => handleSignature(values)}
            pdfLink={pdfLink}
            pdfCount={pdfCount}
            showSpinner={showSigningSpinner}
         />
      </>
   );
}

export default ReviewSign;