import React, { ChangeEvent, useEffect, useState } from "react";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";
import { useDebounce } from "hooks/useDebounce";
import { useUserContext } from "../UserContext";

type User_SearchBarProps = {
   activeTab: string;
};

const User_SearchBar = ({ activeTab }: User_SearchBarProps) => {
   const [searchQuery, setSearchQuery] = useState<string>('');
   const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');
   const debouncedSearch = useDebounce(searchQuery, 500, 3);

   const {
      users,
      setUserList,
      getListOfUsers,
      companies,
      setCompanyList,
      getListOfCompany,
      properties,
      getProperties,
      setProperties
   } = useUserContext();

   // Search on the basis of property name, property address, tenant name, case number, or filer email
   const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      
      const { value } = event.target;
      setSearchQuery(value);
   };

   const handleCrossClick = () => {
      setSearchQuery('');
      if (activeTab === "Users") {
         getListOfUsers(1, 100, "");
         setUserList((prev) => ({ ...prev, searchParam: "" }));
      }

      if (activeTab === "Company") {
         getListOfCompany(1, 100, "");
         setCompanyList((prev) => ({ ...prev, searchParam: "" }));
      }
      if (activeTab === "Properties") {
         getProperties(1, 100, "");
         setProperties((prev) => ({ ...prev, searchParam: "" }));
      }
   };

   const handleSearchIconClick = () => {
      const trimmedSearchParam = searchQuery.trim();
      if (activeTab === "Users") {
         getListOfUsers(1, 100, trimmedSearchParam);
         setUserList((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
      }

      if (activeTab === "Company") {
         getListOfCompany(1, 100, trimmedSearchParam);
         setCompanyList((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
      }
      if (activeTab === "Properties") {
         getProperties(1, 100, trimmedSearchParam);
         setProperties((prev) => ({ ...prev, searchParam:trimmedSearchParam }));
      }
   };

   // useEffect(() => {
      
   //    const trimmedSearchParam = debouncedSearch.trim();

   //    if (activeTab === "Users") {
   //       setUserList(prev => ({ ...prev, searchParam: trimmedSearchParam }));
   //    } else if (activeTab === "Company") {
   //       setCompanyList(prev => ({ ...prev, searchParam: trimmedSearchParam }));
   //    }

   //    if (trimmedSearchParam.length >= 3) {
   //       if(searchedApiQuery  == "")
   //          {
   //             setSearchedApiQuery(trimmedSearchParam);
   //          }
   //       if (activeTab === "Users") {
   //          getListOfUsers(1, 100, trimmedSearchParam);
   //       } else if (activeTab === "Company") {
   //          getListOfCompany(1, 100, trimmedSearchParam);
   //       }
   //    }
   //    if(trimmedSearchParam.length < searchedApiQuery.length)
   //       {
   //          setSearchedApiQuery(trimmedSearchParam);
   //          if (activeTab === "Users") {
   //             getListOfUsers(1, 100, trimmedSearchParam);
   //          } else if (activeTab === "Company") {
   //             getListOfCompany(1, 100, trimmedSearchParam);
   //          }
   //       }
   // }, [debouncedSearch, activeTab]);

   useEffect(() => {
      
      const trimmedSearchParam = debouncedSearch.trim();

      if (activeTab === "Users") {
         setUserList(prev => ({ ...prev, searchParam: trimmedSearchParam }));
      } else if (activeTab === "Company") {
         setCompanyList(prev => ({ ...prev, searchParam: trimmedSearchParam }));
      }else if(activeTab=="Properties"){
         setProperties(prev => ({ ...prev, searchParam: trimmedSearchParam }));
      }

      if (trimmedSearchParam) {
         if(searchedApiQuery  == "")
            {
               setSearchedApiQuery(trimmedSearchParam);
            }
         if (activeTab === "Users") {
            getListOfUsers(1, 100, trimmedSearchParam);
         } else if (activeTab === "Company") {
            getListOfCompany(1, 100, trimmedSearchParam);
         }else if(activeTab=="Properties"){
            getProperties(1,100,trimmedSearchParam);
         }
      }
      if(trimmedSearchParam.length < searchedApiQuery.length)
         {
            setSearchedApiQuery(trimmedSearchParam);
            if (activeTab === "Users") {
               getListOfUsers(1, 100, trimmedSearchParam);
            } else if (activeTab === "Company") {
               getListOfCompany(1, 100, trimmedSearchParam);
            }else if(activeTab=="Properties"){
               getProperties(1,100,trimmedSearchParam)
            }
         }
   }, [debouncedSearch, activeTab]);


   useEffect(() => {
      if (!users.searchParam?.length) {
         setSearchQuery('');
      }
   }, [users.searchParam]);

   useEffect(() => {
      if (!companies.searchParam?.length) {
         setSearchQuery('');
      }
   }, [companies.searchParam]);
   useEffect(() => {
      if (!properties.searchParam?.length) {
         setSearchQuery('');
      }
   }, [properties.searchParam]);

   useEffect(() => {
      setSearchQuery('');
      setUserList(prev => ({ ...prev, searchParam: '' }));
      setCompanyList(prev => ({ ...prev, searchParam: '' }));
      setProperties(prev => ({ ...prev, searchParam: '' }))
   }, [activeTab]);

   return (
      <MultiLineSearch
         value={searchQuery}
         handleInputChange={handleInputChange}
         handleSearchIconClick={handleSearchIconClick}
         handleCrossIconClick={handleCrossClick}
      />
   );
};

export default User_SearchBar;
