import React, { ChangeEvent, useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { FilingType, StatusList } from "utils/constants";
import { UserRole } from "utils/enum";
import { ISelectOptions } from "interfaces/all-cases.interface";
import DropdownPresentation from "components/common/dropdown/DropDown";
import ClearFilters from "components/common/button/ClearFilters";
import { useAllCasesContext } from "../AllCasesContext";
import { useAuth } from "context/AuthContext";

const initialSelectOption: ISelectOptions = { id: '', value: '' };

const AllCases_SearchFilters = () => {
    const [selectedStatus, setSelectedStatus] = useState<ISelectOptions>(initialSelectOption);
    const [selectedCounty, setSelectedCounty] = useState<ISelectOptions>(initialSelectOption);
    const [selectedFiling, setSelectedFiling] = useState<ISelectOptions>(initialSelectOption);
    // will get data from api and set that in the updateAllCases
    const { allCases, setAllCases, getAllCases, setSelectedAllCasesId ,allCounties} = useAllCasesContext();
    const { userRole } = useAuth();
    const [countyList,setCountyList]=useState<ISelectOptions[]>([]);
    const handleStatusChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const status = event.target.value as string;
        setSelectedStatus({ id: status, value: status });
        setSelectedAllCasesId([]);

        getAllCases(1, 100, allCases.searchParam, status, selectedCounty.id.toString(),selectedFiling && selectedFiling.id != ""?selectedFiling.id==1:null);

        setAllCases((prevAllCases) => ({ ...prevAllCases, status: status }));
    };
    const handleFilingChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const filing = event.target.value as string;
        setSelectedFiling({ id: filing, value: filing });
        setSelectedAllCasesId([]);

        getAllCases(1, 100, allCases.searchParam, selectedStatus.id.toString(), selectedCounty.id.toString(),event.target.value=="1");
        setAllCases((prevAllCases) => ({ ...prevAllCases,filingType:event.target.value=="1"}));
    };
    useEffect(()=>{
        var list = allCounties.map((item) => ({
            id: item.countyName,
            value: item.countyName
          })); 
          setCountyList(list);
    },[allCounties]);
    const handleCountyChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const county = event.target.value as string;
        setSelectedCounty({ id: county, value: county });
        setSelectedAllCasesId([]);
        getAllCases(1, 100, allCases.searchParam, selectedStatus.id.toString(), county,selectedFiling && selectedFiling.id != ""?selectedFiling.id==1:null);
        setAllCases((prevAllCases) => ({ ...prevAllCases, county: county }));
    };

    const clearSearchFilters = () => {
        // if(allCases.searchParam?.length || selectedStatus.value.length || selectedCounty.value.length){
            setSelectedStatus(initialSelectOption);
            setSelectedCounty(initialSelectOption);
            setSelectedFiling(initialSelectOption);
            setSelectedAllCasesId([]);
            // getAllCases(1, 100, '', '', '',null);
            // setAllCases((prevAllCases) => ({ ...prevAllCases, searchParam: '', status: '', county: '',filingType:null }));
            getAllCases(1, 100, allCases.searchParam, '', '',null);
            setAllCases((prevAllCases) => ({ ...prevAllCases, status: '', county: '',filingType:null }));
        // }
    };

    return (
        <>
        {!userRole.includes(UserRole.Viewer) ?
        <div className="flex items-center filterSec">
            <DropdownPresentation
                heading={""}
                selectedOption={selectedStatus}
                handleSelect={(event) => handleStatusChange(event)}
                options={StatusList}
                placeholder="Filter by status"
            />
            <DropdownPresentation
                heading=""
                selectedOption={selectedCounty}
                handleSelect={handleCountyChange}
                options={countyList}                
                placeholder="Filter by county"
            />
            <DropdownPresentation
                heading={""}
                selectedOption={selectedFiling}
                handleSelect={(event) => handleFilingChange(event)}
                options={FilingType}
                placeholder="Filter by EvictionType"
            />           
            <ClearFilters
                type="button"
                isRounded={false}
                title="Clear Filters"
                handleClick={clearSearchFilters}
                icon={<FaTimes />}
            />
        </div>
        : <></>}
        </>
    );
};

export default AllCases_SearchFilters;