import React, { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "hooks/useDebounce";
import { useWritsOfPossessionContext } from "../../WritsOfPossessionContext";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";

type WritsOfPossession__SearchBarProps = {
   activeTab?: string;
};

const WritsOfPossession_SearchBar = (props: WritsOfPossession__SearchBarProps) => {
   // State for holding the search query
   const [searchQuery, setSearchQuery] = useState<string>("");
   const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');

   // Debounce the search query
   const debouncedSearch = useDebounce(searchQuery, 500);

   // Get necessary functions from the context
   const {
      getAllWritsOfPossession,
      setAllUnsignedWrits,
      unsignedWrits,
      signedWrits,
      setAllSignedWrits,
      setSelectedWritsOfPossessionId
   } = useWritsOfPossessionContext();

   useEffect(() => {
      if(props.activeTab === "Ready to Sign"){
         setSearchQuery(unsignedWrits.searchParam ?? "");
      } else if(props.activeTab === "Signed"){
         setSearchQuery(signedWrits.searchParam ?? "");
      }

   },  []);

   // Handle change in the search input
   const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setSearchQuery(event.target.value);
      setSelectedWritsOfPossessionId([]);
   };

   // Update writs of possession when the debounced search query changes
   const updateWritsOfPossession = (searchParam: string) => {
      const trimmedSearchParam = searchParam.trim();
      if (!trimmedSearchParam.length) return;
      setSearchQuery('');
      getAllWritsOfPossession(1, 100, props.activeTab === "Ready to Sign" ? false : true, trimmedSearchParam);
      // getAllWritsOfPossession(1, 100, props.activeTab === "Signed" ? true : false, trimmedSearchParam);
      setAllUnsignedWrits((prev) => ({
         ...prev,
         searchParam: trimmedSearchParam,
      }));
      setAllSignedWrits((prev) => ({
         ...prev,
         searchParam: trimmedSearchParam,
      }));
   };

   const handleSearchIconClick = () => {
      const trimmedSearchParam = searchQuery.trim();
      setAllSignedWrits((prev) => ({
         ...prev,
         searchParam: trimmedSearchParam,
      }));
      setAllUnsignedWrits((prev) => ({
         ...prev,
         searchParam: trimmedSearchParam,
      }));
      // if (props.activeTab === "Signed") {
         getAllWritsOfPossession(1, 100, true, "");
      // } else {
         getAllWritsOfPossession(1, 100, false, "");
      // }
   };

   const handleCrossIconClick = () => {
      setSearchQuery("");
      setAllUnsignedWrits((prev) => ({
         ...prev,
         searchParam: "",
      }));
      setAllSignedWrits((prev) => ({
         ...prev,
         searchParam: "",
      }));
      // if (props.activeTab === "Signed") {
         getAllWritsOfPossession(1, 100, true, "");

      // } else {
         getAllWritsOfPossession(1, 100, false, "");
      // }
   };

   // useEffect to trigger the API call when the debounced search query changes
   // useEffect(() => {
   //    const trimmedSearchParam = searchQuery.trim();
   //    if (debouncedSearch.trim()) {
   //       setAllUnsignedWrits((prev) => ({
   //          ...prev,
   //          searchParam: trimmedSearchParam,
   //       }));
   //       setAllSignedWrits((prev) => ({
   //          ...prev,
   //          searchParam: trimmedSearchParam,
   //       }));
   //       if (trimmedSearchParam && trimmedSearchParam.length >= 3) {
   //          if(searchedApiQuery  == "")
   //             {
   //                setSearchedApiQuery(trimmedSearchParam);
   //             }
   //          getAllWritsOfPossession(1, 100, props.activeTab === "Unsigned" ? false : true, trimmedSearchParam)
   //       }
   //    } 
   //    else if(trimmedSearchParam.length < searchedApiQuery.length)
   //       {
   //          setSearchedApiQuery(trimmedSearchParam);
   //          getAllWritsOfPossession(1, 100, props.activeTab === "Unsigned" ? false : true, trimmedSearchParam)
   //          setSearchQuery('');
   //          setAllUnsignedWrits((prev) => ({
   //             ...prev,
   //             searchParam: "",
   //          }));
   //          setAllSignedWrits((prev) => ({
   //             ...prev,
   //             searchParam: "",
   //          }));
   //       }
   //    else {
   //       updateWritsOfPossession('');
   //    }
   // }, [debouncedSearch]);

   useEffect(() => {
      const trimmedSearchParam = searchQuery.trim();
      if (debouncedSearch.trim()) {
         setAllUnsignedWrits((prev) => ({
            ...prev,
            searchParam: trimmedSearchParam,
         }));
         setAllSignedWrits((prev) => ({
            ...prev,
            searchParam: trimmedSearchParam,
         }));
         if (trimmedSearchParam) {
            if(searchedApiQuery  == "")
               {
                  setSearchedApiQuery(trimmedSearchParam);
               }
            getAllWritsOfPossession(1, 100, props.activeTab === "Ready to Sign" ? false : true, trimmedSearchParam)
         }
      } 
      else if(trimmedSearchParam.length < searchedApiQuery.length)
         {
            setSearchedApiQuery(trimmedSearchParam);
            getAllWritsOfPossession(1, 100, props.activeTab === "Ready to Sign" ? false : true, trimmedSearchParam)
            setSearchQuery('');
            setAllUnsignedWrits((prev) => ({
               ...prev,
               searchParam: "",
            }));
            setAllSignedWrits((prev) => ({
               ...prev,
               searchParam: "",
            }));
         }
      else {
         updateWritsOfPossession('');
      }
   }, [debouncedSearch]);
   useEffect(() => {
      if (!unsignedWrits.searchParam?.length) {
         setSearchQuery('');
      }

      if (!signedWrits.searchParam?.length) {
         setSearchQuery('');
      }

   }, [unsignedWrits.searchParam]);

   useEffect(() => {
      setSearchQuery('');
      setAllUnsignedWrits((prev) => ({
         ...prev,
         searchParam: '',
      }));
      setAllSignedWrits((prev) => ({
         ...prev,
         searchParam: '',
      }));
   }, [props.activeTab]);

   return (
      <MultiLineSearch
         value={searchQuery}
         handleInputChange={handleChange}
         handleSearchIconClick={handleSearchIconClick}
         handleCrossIconClick={handleCrossIconClick}
      />
   );
};

export default WritsOfPossession_SearchBar;
