import React, { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "hooks/useDebounce";
import { useFileEvictionsContext } from "../../FileEvictionsContext";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";
type FileEvictions_SearchBarProp={
   activeTab?:string;
};
const FileEvictions_SearchBar = (props:FileEvictions_SearchBarProp) => {
   const [searchQuery, setSearchQuery] = useState<string>("");
   const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');

   const debouncedSearch = useDebounce(searchQuery, 500, 3);

   const { fileEvictions, setFileEvictions, getFileEvictions ,evictionAutomationApprovalsQueue,setEvictionAutomationApprovalsQueue,getEvictionAutomationApprovalsQueue} = useFileEvictionsContext();

   useEffect(() => {
      setSearchQuery(fileEvictions.searchParam ?? "");

   }, []);

   useEffect(()=>{
      setSearchQuery(fileEvictions.searchParam ?? "");
      if(props.activeTab=="Ready to Sign")
         getFileEvictions(1,100,fileEvictions.isViewAll??true,"");
      else
         getEvictionAutomationApprovalsQueue(1,100,false,true,"");
   },[props.activeTab])

   const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setSearchQuery(event.target.value);
   };

   const updateFileEvictions = (searchParam: string) => {
      const trimmedSearchParam = searchParam.trim();
      if (trimmedSearchParam.length >= 3) {
         if(props.activeTab=="Ready to Sign"){
            getFileEvictions(1, 100,fileEvictions.isViewAll??true, trimmedSearchParam);
            setFileEvictions((prev) => ({
               ...prev,
               searchParam: trimmedSearchParam,
            }));
         }
         else{
            getEvictionAutomationApprovalsQueue(1,100,false,true,trimmedSearchParam);
            setEvictionAutomationApprovalsQueue((prev) => ({
               ...prev,
               searchParam: trimmedSearchParam,
            }));
         }
        
      }
   };

   const handleSearchIconClick = () => {
      if(props.activeTab=="Ready to Sign"){
         getFileEvictions(1, 100,fileEvictions.isViewAll??true, searchQuery.trim());
         setFileEvictions((prev) => ({ ...prev, searchParam: searchQuery }));
      }
      else{
         getEvictionAutomationApprovalsQueue(1, 100,false,true, searchQuery.trim());
         setEvictionAutomationApprovalsQueue((prev) => ({ ...prev, searchParam: searchQuery }));
      }
      
   };

   const handleCrossIconClick = () => {
      setSearchQuery('');
      if(props.activeTab=="Ready to Sign"){
         getFileEvictions(1, 100,fileEvictions.isViewAll??true, "");
         setFileEvictions((prev) => ({ ...prev, searchParam: debouncedSearch }));
      }
      else{
         getEvictionAutomationApprovalsQueue(1, 100,false,true,"");
         setEvictionAutomationApprovalsQueue((prev) => ({ ...prev, searchParam: debouncedSearch }));
      }
      
   };

   useEffect(() => {
      
      const trimmedSearchParam = debouncedSearch.trim();
      if(props.activeTab=="Ready to Sign")
         setFileEvictions((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
      else
         setEvictionAutomationApprovalsQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
      if (trimmedSearchParam) {
         if(searchedApiQuery  == "")
         {
            setSearchedApiQuery(trimmedSearchParam);
         }
         if(props.activeTab=="Ready to Sign")
            getFileEvictions(1, 100,fileEvictions.isViewAll??true, trimmedSearchParam);
         else
            getEvictionAutomationApprovalsQueue(1, 100,false,true,trimmedSearchParam);
      } 
         else if(trimmedSearchParam.length < searchedApiQuery.length)
         {
            setSearchedApiQuery(trimmedSearchParam);
            if(props.activeTab=="Ready to Sign")
               getFileEvictions(1, 100, fileEvictions.isViewAll??true,trimmedSearchParam);
            else
               getEvictionAutomationApprovalsQueue(1, 100,false,true,trimmedSearchParam);
         }else {
         updateFileEvictions("");
      }
   }, [debouncedSearch]);

   useEffect(() => {
      if (!searchQuery) {
         updateFileEvictions('');
      }
   }, [searchQuery]);

   useEffect(() => {
      setSearchQuery('');
      setFileEvictions((prev) => ({ ...prev, searchParam: '' }));
      setEvictionAutomationApprovalsQueue((prev) => ({ ...prev, searchParam: '' }));
   }, [props.activeTab]);

   return (
      <MultiLineSearch
         value={searchQuery}
         handleInputChange={handleChange}
         handleSearchIconClick={handleSearchIconClick}
         handleCrossIconClick={handleCrossIconClick}
      />
   );
};

export default FileEvictions_SearchBar;