import axios from "middlewares/axiosInstance";
import {
   ICreateWritsOfPossession,
   IWritsOfPossessionPdfLink,
   IWritsOfPossessionSign,
   IWritsOfPossession,
   IWritsOfPossessionItems,
} from "interfaces/writs-of-possession.interface";
import { AxiosResponse } from "axios";
import { WritsExportResource } from "interfaces/export-late-notices.interface";
import { IExportCsv } from "interfaces/common.interface";

/**
 * add writs of possession
 * @returns writs of possession created successfully or not
 */
const createWritsOfPossession = async (
   writsOfPssession: ICreateWritsOfPossession[]
) => {
   const response: AxiosResponse<ICreateWritsOfPossession> =
      await axios<ICreateWritsOfPossession>({
         method: "POST",
         url: `api/`,
         data: writsOfPssession,
      });

   return response as AxiosResponse<ICreateWritsOfPossession>;
};

/**
 * fetch list of writsofpossession from api
 * @param userID get all writeOfPossession by user id
 * @returns list of all writeOfPossession
 */
const getAllWritesOfPossession = async (
   pageNumber: number,
   pageSize: number,
   isSigned: boolean,
   searchParam?: string
) => {
   const url: string = searchParam
      ? `/api/Writ/${pageNumber}/${pageSize}?searchParam=${searchParam}&isSigned=${isSigned}`
      : `/api/Writ/${pageNumber}/${pageSize}?isSigned=${isSigned}`;
   const response: AxiosResponse<IWritsOfPossession> = await axios<IWritsOfPossession>({
      method: "GET",
      url: url,
   });
   return response as AxiosResponse<IWritsOfPossession>;
};

/**
 * delete writs of possession
 * @returns
 */
const deleteWritsOfPossession = async (writsOfPossessionIDs: string[]) => {
   const response: AxiosResponse<IWritsOfPossessionItems[]> = await axios<
      IWritsOfPossessionItems[]
   >({
      method: "DELETE",
      url: `/api/Writ`,
      data: writsOfPossessionIDs,
   });

   return response as AxiosResponse<IWritsOfPossessionItems[]>;
};
/**
 *
 * @param writsOfPossessionIDs selectec writsOfPossession id
 * @returns pdf link
 */
const getWritsOfPossessionDocumentForSign = async (
   writsOfPossessionIDs: string[]
) => {
   const response: AxiosResponse<IWritsOfPossessionPdfLink> =
      await axios<IWritsOfPossessionPdfLink>({
         method: "POST",
         url: `/api/FileEviction/GetEvictionDocumentForSign`,
         data: writsOfPossessionIDs,
      });

   return response as AxiosResponse<IWritsOfPossessionPdfLink>;
};

/**
 * sign  File Eviction
 * @returns
 */

const signWritsOfPossession = async (
   writsOfPossessionSign: IWritsOfPossessionSign
) => {

   const response: AxiosResponse = await axios({
      method: "POST",
      url: `/api/`,
      data: writsOfPossessionSign,
   });

   return response as AxiosResponse;
};

const editWritOfPossessionByWritLabor = async (writOfPossessios: IWritsOfPossessionItems[]) => {

   const response: AxiosResponse<IWritsOfPossessionItems[]> =
      await axios<IWritsOfPossessionItems[]>({
         method: "PUT",
         url: `api/WritLabor/UpdateWritOfPossession`,
         data: writOfPossessios,
      });

   return response as AxiosResponse;
};

const exportWrits = async (payload: IExportCsv,  searchParam?: string) => {
   const queryString = new URLSearchParams(searchParam).toString();
   const response: AxiosResponse<WritsExportResource[]> =
      await axios<WritsExportResource[]>({
         method: "POST",
         url: `api/Writ/ExportWrits?searchParam=${searchParam}`,
         data: payload,
      });

   return response as AxiosResponse<WritsExportResource[]>;
};

const WritsOfPossessionService = {
   createWritsOfPossession,
   getAllWritesOfPossession,
   deleteWritsOfPossession,
   getWritsOfPossessionDocumentForSign,
   signWritsOfPossession,
   editWritOfPossessionByWritLabor,
   exportWrits
};

export default WritsOfPossessionService;
