import React, { useState, useCallback } from "react";
import * as yup from "yup";
import { AxiosError, HttpStatusCode } from "axios";
import { toast } from "react-toastify";
import { Form, Formik, FormikProps } from "formik";
import Button from "components/common/button/Button";
import Modal from "components/common/popup/PopUp";
import FormikControl from "components/formik/FormikControl";
import { IProcessServerCaseInfoEditRequest, IProcessServerCaseInfoFormValue, IProcessServerCaseInfoItem } from "interfaces/process-server.interface";
import ProcessServerService from "services/process-server.service";
import { useProcessServerContext } from "pages/process-server/ProcessServerContext";
import { ServiceMethodList } from "utils/constants";
import { AOSStatusEnum, ServiceMethod } from "utils/enum";
import vm from "utils/validationMessages";

type ProcessServerCaseInfoProps = {
   open: boolean;
   setOpen: (open: boolean) => void;
   selectedCaseInfo: IProcessServerCaseInfoItem | null;
   setSelectedCaseInfo: (user: IProcessServerCaseInfoItem | null) => void;
};

const requiredField = (errorMessage: string) => yup.string().required(errorMessage);
const optionalField = yup.string().optional();

const validationSchema = yup.object({
   caseNumber: yup
      .string()
      // .max(10, "Max 10 digits")
      .required("Case number is required"),
   serviceMethod: yup
      .string()
      .required("Service Type is required"),
   // personServed: yup.string().when("serviceMethod", {
   //    is: (val: string) =>
   //       val === ServiceMethod.NOTORIOUSLY || val === ServiceMethod.PERSONALLY,
   //    then(schema) { return schema.required("Person Served is required") },
   //    otherwise(schema) { return schema.optional() },
   // }),
   height: yup.string().when('serviceMethod', {
      is: (val: string) =>
         val === ServiceMethod.NOTORIOUSLY || val === ServiceMethod.PERSONALLY,
      then(schema) { return schema.required("Height is required") },
      otherwise(schema) { return schema.optional() },
   }),
   weight: yup.string().when('serviceMethod', {
      is: (val: string) =>
         val === ServiceMethod.NOTORIOUSLY || val === ServiceMethod.PERSONALLY,
      then(schema) { return schema.required("Weight is required") },
      otherwise(schema) { return schema.optional() },
   }),
   age: yup.string().when('serviceMethod', {
      is: (val: string) =>
         val === ServiceMethod.NOTORIOUSLY || val === ServiceMethod.PERSONALLY,
      then(schema) { return schema.required("Age is required") },
      otherwise(schema) { return schema.optional() },
   }),
   serviceNotes: yup.string().when('serviceMethod', {
      is: ServiceMethod.NON_EST,
      then(schema) { return schema.required("Service notes is required") },
      otherwise(schema) { return schema.optional() },
   }),
   serverEmail: yup.string().nullable().email(vm.email.email)
});

const formatDate = (dateString: string) => {
   const date = new Date(dateString);
   const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
   return formattedDate;
};

const ProcessServer_EditCaseInfo = (props: ProcessServerCaseInfoProps) => {
   const { open, setOpen, selectedCaseInfo, setSelectedCaseInfo } = props;
   const { getProcessServerCases, processServerCases } = useProcessServerContext();
   const [nameMismatchError, setNameMismatchError] = useState<string | null>("");
   const [showSpinner, setShowSpinner] = useState<boolean>(false);

   const initialValues: IProcessServerCaseInfoFormValue = {
      id: selectedCaseInfo?.id ?? "",
      caseNumber: selectedCaseInfo?.caseNumber ?? "",
      unitNumber: selectedCaseInfo?.unitNumber ?? "",
      streetNumber: selectedCaseInfo?.streetNumber ?? "",
      streetName: selectedCaseInfo?.streetName ?? "",
      personServed: selectedCaseInfo?.personServed ?? "",
      height: selectedCaseInfo?.height ?? "",
      weight: selectedCaseInfo?.weight ?? "",
      age: selectedCaseInfo?.age ?? "",
      serviceNotes: selectedCaseInfo?.serviceNotes ?? "",
      serviceDate: selectedCaseInfo ? formatDate(selectedCaseInfo.serviceDate as string) : "",
      dateScanned: selectedCaseInfo ? formatDate(selectedCaseInfo.dateScanned as string) : "",
      // longitude: selectedCaseInfo?.longitude ?? "",
      // latitude: selectedCaseInfo?.latitude ?? "",
      locationCoord: selectedCaseInfo?.locationCoord ?? "",
      comments: selectedCaseInfo?.comments ?? "",
      tenantnames: selectedCaseInfo?.tenantnames ?? "",
      propertyName: selectedCaseInfo?.propertyName ?? "",
      // defendantName: selectedCaseInfo?.defendantName ?? "",
      // house: selectedCaseInfo?.house ?? "",
      serviceMethod: selectedCaseInfo?.serviceMethod ?? "",
      companyName: selectedCaseInfo?.companyName ?? "",
      serverName: selectedCaseInfo?.serverName ?? "",
      status: AOSStatusEnum.Modified,
      serverEmail: selectedCaseInfo?.serverEmail ?? "",
      serverExists: selectedCaseInfo?.serverExists ?? false
   };

   const handleServiceMethodChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>, formik: FormikProps<IProcessServerCaseInfoFormValue>) => {
      const value = event.target.value;
      formik.setFieldValue("serviceMethod", value);

      if (!(value === ServiceMethod.PERSONALLY || value === ServiceMethod.NOTORIOUSLY)) {
         formik.setFieldValue("personServed", "N/A");
         formik.setFieldValue("height", "N/A");
         formik.setFieldValue("weight", "N/A");
         formik.setFieldValue("age", "N/A");
      } else {
         formik.setFieldValue("personServed", "");
         formik.setFieldValue("height", "");
         formik.setFieldValue("weight", "");
         formik.setFieldValue("age", "");
      }

      // Manually trigger validation after setting field values
      formik.validateForm();

   }, []);

   const isServiceMethodPersonalOrNotorious = (formValues: IProcessServerCaseInfoFormValue): boolean => {
      return (formValues.serviceMethod === ServiceMethod.PERSONALLY ||
         formValues.serviceMethod === ServiceMethod.NOTORIOUSLY);
   };

   const handleProcessServerCaseInfo = async (formValues: IProcessServerCaseInfoFormValue) => {
      setNameMismatchError(null);
      setShowSpinner(true);
      // Convert date strings to Date objects
      const serviceDate = new Date(formValues.serviceDate);
      const dateScanned = new Date(formValues.dateScanned);

      // Adjust to local timezone offset
      formValues.serviceDate = new Date(serviceDate.getTime() - (serviceDate.getTimezoneOffset() * 60000));
      formValues.dateScanned = new Date(dateScanned.getTime() - (dateScanned.getTimezoneOffset() * 60000));

      const payload: IProcessServerCaseInfoEditRequest = {
         id: formValues.id, // Assuming id is included in formValues
         caseNumber: formValues.caseNumber,
         personServed: formValues.personServed === "N/A" ? "" : formValues.personServed,
         serverName: formValues.serverName, // Fill this with the appropriate value
         height: formValues.height === "N/A" ? "" : formValues.height,
         weight: formValues.weight === "N/A" ? "" : formValues.weight,
         age: formValues.age === "N/A" ? "" : formValues.age,
         serviceMethod: formValues.serviceMethod,
         serviceDate: formValues.serviceDate,
         serviceNotes: formValues.serviceNotes,
         // defendantName: formValues.defendantName,
         // house: formValues.house,
         dateScanned: formValues.dateScanned,
         // longitude: formValues.longitude,
         // latitude: formValues.latitude,
         locationCoord: formValues.locationCoord,
         comments: formValues.comments,
         serverEmail: formValues.serverEmail
      };

      try {
         const response = await ProcessServerService.editProcessServerCaseInfo(payload);
         if (response.status === HttpStatusCode.Ok) {
            setShowSpinner(false);
            setOpen(false); // close the modal pop-up
            setSelectedCaseInfo(null);
            getProcessServerCases(
               1,
               100,
               processServerCases.searchParam,
               processServerCases.serverName,
               processServerCases.serviceMethod,
               processServerCases.dateRange
            );
            // getProcessServerCases(1, 100);
            toast.success(response.data.message);
         } else {
            setShowSpinner(false);
            const error = response as unknown as AxiosError<any>;
            if (error && error.response) {
               const { data } = error.response;
               if (data.message.includes("The person served does not match to the tenant names associated with case number"))
                  setNameMismatchError(data.message);
            };
         }
      } catch (error) {
         console.log(error);
      } finally {
         setShowSpinner(false);
      }
   };

   return (
      <>
         <Modal showModal={open} onClose={() => setOpen(false)} width="max-w-5xl">
            <div className="bg-white px-3.5 pb-3.5 pt-4 sm:p-5 rounded-md">
               <div className="sm:flex sm:items-start">
                  <div className="text-center sm:text-left">
                     <h3
                        className="leading-5 text-gray-900 text-[16px] md:text-xl mb-1.5"
                        id="modal-title"
                     >
                        Update Case Info
                     </h3>
                  </div>
               </div>
               <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleProcessServerCaseInfo}
               >
                  {(formik) => (
                     <Form className="flex flex-col pt-1.5">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2.5 md:gap-3.5">
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Case Number"}
                                 name={"caseNumber"}
                                 placeholder={"Enter Case Number"}
                                 disabled={true}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Process Server Email"}
                                 name={"serverEmail"}
                                 placeholder={"Enter Server Email"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="select"
                                 type="select"
                                 label={"Service Type"}
                                 name={"serviceMethod"}
                                 placeholder={"Select"}
                                 options={ServiceMethodList}
                                 onChange={(event: React.ChangeEvent<HTMLSelectElement>) => handleServiceMethodChange(event, formik)}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Server Name"}
                                 name={"serverName"}
                                 placeholder={"Enter Server Name"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Person Served"}
                                 name={"personServed"}
                                 placeholder={"Enter Person Served"}
                                 disabled={!isServiceMethodPersonalOrNotorious(formik.values)}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Height"}
                                 name={"height"}
                                 placeholder={"Enter Height"}
                                 disabled={!isServiceMethodPersonalOrNotorious(formik.values)}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Weight"}
                                 name={"weight"}
                                 placeholder={"Enter Weight"}
                                 disabled={!isServiceMethodPersonalOrNotorious(formik.values)}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Age"}
                                 name={"age"}
                                 placeholder={"Enter Age"}
                                 disabled={!isServiceMethodPersonalOrNotorious(formik.values)}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Service Notes"}
                                 name={"serviceNotes"}
                                 placeholder={"Enter Service Notes"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="date"
                                 type="date"
                                 label={"Service Date"}
                                 name={"serviceDate"}
                                 placeholder={"Enter Service Date"}
                                 minDate={null}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="date"
                                 type="date"
                                 label={"Date Scanned"}
                                 name={"dateScanned"}
                                 placeholder={"Enter Date Scanned"}
                                 minDate={null}
                              />
                           </div>
                           {/* <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Defendant Name"}
                                 name={"defendantName"}
                                 placeholder={"Enter Defendant Name"}
                              />
                           </div> */}
                           {/* <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Longitude"}
                                 name={"longitude"}
                                 placeholder={"Enter Longitude"}
                              />
                           </div> */}
                           {/* <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Latitude"}
                                 name={"latitude"}
                                 placeholder={"Enter Latitude"}
                              />
                           </div> */}
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Location Coord"}
                                 name={"locationCoord"}
                                 placeholder={"Enter Location Coord"}
                              />
                           </div>
                           <div className="relative">
                              <FormikControl
                                 control="input"
                                 type="text"
                                 label={"Service Comments"}
                                 name={"comments"}
                                 placeholder={"Enter Service Comments"}
                              />
                           </div>
                        </div>

                        <div className="flex justify-between items-center">
                           <div className="text-[#E61818]">
                              {nameMismatchError}
                           </div>
                           <div className="text-right pt-2.5">
                              <Button
                                 type="button"
                                 isRounded={false}
                                 title="Cancel"
                                 handleClick={() => setOpen(false)}
                                 classes="text-xs bg-white inline-flex justify-center items-center rounded-md font-semibold py-2 md:py-2.5 px-4 md:px-5 mr-1.5 ring-1 ring-slate-900/10 hover:bg-[#f5f8fb] hover:ring-slate-900/15 shadow-lg"
                              ></Button>
                              <Button
                                 title={"Update"}
                                 type={"submit"}
                                 isRounded={false}
                                 disabled={showSpinner}
                                 classes="mt-2.5 py-2 md:py-2.5 px-4 inline-flex justify-center items-center gap-x-1.5 text-xs font-semibold rounded-md border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none   "
                              ></Button>
                           </div>
                        </div>
                     </Form>
                  )}
               </Formik>
            </div>
         </Modal>
      </>
   );
};

export default ProcessServer_EditCaseInfo;