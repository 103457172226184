import React, { useEffect, useState } from "react";
import { ChangeEvent } from "react";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";
import { useDebounce } from "hooks/useDebounce";
import { useLogsContext } from "./LogsContext";

const Log_SearchBar = () => {
   const { logs, setLogs, getLogs } = useLogsContext();
   const [searchQuery, setSearchQuery] = useState<string>('');
   const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');

   const debouncedSearch = useDebounce(searchQuery, 500, 3);

   const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setSearchQuery(event.target.value);
   };

   const updateAllLogs = () => {
      const trimmedSearchParam = searchQuery.trim();
      if(trimmedSearchParam)
      getLogs(1, 100, trimmedSearchParam, logs.event, logs.type, logs.fromDate, logs.toDate,logs.userId ?? "",logs.companyId ?? "");
      setLogs((prevAllLog) => ({ ...prevAllLog, searchParam: trimmedSearchParam }));
   };

   const handleCrossClick = () => {
      setSearchQuery('');
      getLogs(1, 100, '', logs.event, logs.type, logs.fromDate, logs.toDate,logs.userId ?? "",logs.companyId ?? "");
      setLogs((prevAllLog) => ({ ...prevAllLog, searchParam: '' }));
   };

   const handleSearchIconClick = () => {
      const trimmedSearchParam = searchQuery.trim();
      getLogs(1, 100, trimmedSearchParam, logs.event, logs.type, logs.fromDate, logs.toDate,logs.userId ?? "",logs.companyId ?? "");
      setLogs((prevAllLog) => ({ ...prevAllLog, searchParam: trimmedSearchParam }));
   };
   // useEffect(() => {
   //    const trimmedSearchParam = searchQuery.trim();
   //    if (debouncedSearch.trim()) {
   //       if (trimmedSearchParam && trimmedSearchParam.length >= 3) {
   //          if(searchedApiQuery  == "")
   //             {
   //                setSearchedApiQuery(trimmedSearchParam);
   //             }
   //          updateAllLogs();
   //       }
   //    }
   //    else if(trimmedSearchParam.length < searchedApiQuery.length)
   //       {
   //          setSearchedApiQuery(trimmedSearchParam);
   //          getLogs(1, 100, trimmedSearchParam, logs.event, logs.type, logs.fromDate, logs.toDate);
   //    setLogs((prevAllLog) => ({ ...prevAllLog, searchParam: trimmedSearchParam }));
   //       }
   //     else {
   //       updateAllLogs();
   //    }
   // }, [debouncedSearch]);

   useEffect(() => {
      const trimmedSearchParam = searchQuery.trim();
      if (debouncedSearch.trim()) {
         if (trimmedSearchParam) {
            if(searchedApiQuery  == "")
               {
                  setSearchedApiQuery(trimmedSearchParam);
               }
            updateAllLogs();
         }
      }
      else if(trimmedSearchParam.length < searchedApiQuery.length)
         {
            setSearchedApiQuery(trimmedSearchParam);
            getLogs(1, 100, trimmedSearchParam, logs.event, logs.type, logs.fromDate, logs.toDate,logs.userId ?? "",logs.companyId ?? "");
      setLogs((prevAllLog) => ({ ...prevAllLog, searchParam: trimmedSearchParam }));
         }
       else {
         updateAllLogs();
      }
   }, [debouncedSearch]);

   useEffect(() => {
      if (!logs.searchParam?.length) {
         setSearchQuery('');
      }
      
   }, [logs.searchParam]);

   return (
      <MultiLineSearch
         value={searchQuery}
         handleInputChange={handleChange}
         handleSearchIconClick={handleSearchIconClick}
         handleCrossIconClick={handleCrossClick}
      />
   );
};

export default Log_SearchBar;
