import React, { useEffect, useState } from "react";
import { ChangeEvent } from "react";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";
import { useDebounce } from "hooks/useDebounce";
import { useEvictionQueueContext } from "../EvictionQueueContext";

const EvictionQueueTask_SearchBar = () => {
   const { selectEvictionQueueId, evictionQueueTasks, setEvictionQueueTasks, getEvictionQueueTasks } = useEvictionQueueContext();
   const [searchQuery, setSearchQuery] = useState<string>('');
   const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');

   const debouncedSearch = useDebounce(searchQuery, 500, 3);

   const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setSearchQuery(event.target.value);
   };

   // const updateAllTasks = () => {
   //    ;
   //    const trimmedSearchParam = searchQuery.trim();
   //    if (selectEvictionQueueId !== 0 && trimmedSearchParam.length >= 3) {
   //       getEvictionQueueTasks(
   //          1, // pageNumber
   //          100, // pageSize
   //          selectEvictionQueueId,
   //          evictionQueueTasks.actiontype ?? 0,
   //          evictionQueueTasks.status ?? 0,
   //          trimmedSearchParam,
   //          evictionQueueTasks.county,
   //          evictionQueueTasks.company
   //       );
   //       setEvictionQueueTasks((prevAllLog) => ({
   //          ...prevAllLog,
   //          searchParam: trimmedSearchParam
   //       }));
   //    }
   //    if(trimmedSearchParam.length < searchedApiQuery.length)
   //       {
   //          setSearchedApiQuery(trimmedSearchParam);
   //          getEvictionQueueTasks(
   //             1, // pageNumber
   //             100, // pageSize
   //             selectEvictionQueueId,
   //             evictionQueueTasks.actiontype ?? 0,
   //             evictionQueueTasks.status ?? 0,
   //             trimmedSearchParam,
   //             evictionQueueTasks.county,
   //             evictionQueueTasks.company
   //          );
   //          setEvictionQueueTasks((prevAllLog) => ({
   //             ...prevAllLog,
   //             searchParam: trimmedSearchParam
   //          }));
   //       }
   // };

   const updateAllTasks = () => {
      ;
      const trimmedSearchParam = searchQuery.trim();
      if (selectEvictionQueueId !== 0) {
         getEvictionQueueTasks(
            1, // pageNumber
            100, // pageSize
            selectEvictionQueueId,
            evictionQueueTasks.actiontype ?? 0,
            evictionQueueTasks.status ?? 0,
            trimmedSearchParam,
            evictionQueueTasks.county,
            evictionQueueTasks.company
         );
         setEvictionQueueTasks((prevAllLog) => ({
            ...prevAllLog,
            searchParam: trimmedSearchParam
         }));
      }
      if(trimmedSearchParam.length < searchedApiQuery.length)
         {
            setSearchedApiQuery(trimmedSearchParam);
            getEvictionQueueTasks(
               1, // pageNumber
               100, // pageSize
               selectEvictionQueueId,
               evictionQueueTasks.actiontype ?? 0,
               evictionQueueTasks.status ?? 0,
               trimmedSearchParam,
               evictionQueueTasks.county,
               evictionQueueTasks.company
            );
            setEvictionQueueTasks((prevAllLog) => ({
               ...prevAllLog,
               searchParam: trimmedSearchParam
            }));
         }
   };
   const handleCrossClick = () => {
      setSearchQuery('');
      if (selectEvictionQueueId !== 0) {
         getEvictionQueueTasks(
            1,
            100,
            selectEvictionQueueId,
            evictionQueueTasks.actiontype ?? 0,
            evictionQueueTasks.status ?? 0,
            "",
            evictionQueueTasks.county,
            evictionQueueTasks.company
         );
         setEvictionQueueTasks((prevAllLog) => ({
            ...prevAllLog,
            searchParam: ""
         }));
      }
   };

   const handleSearchIconClick = () => {
      const trimmedSearchParam = searchQuery.trim();
      getEvictionQueueTasks(
         1,
         100,
         selectEvictionQueueId,
         evictionQueueTasks.actiontype ?? 0,
         evictionQueueTasks.status ?? 0,
         trimmedSearchParam,
         evictionQueueTasks.county,
         evictionQueueTasks.company
      );
      setEvictionQueueTasks((prevAllLog) => ({
         ...prevAllLog,
         searchParam: trimmedSearchParam
      }));
   };
   useEffect(() => {
      ;
      const trimmedSearchParam = debouncedSearch.trim();
      if (trimmedSearchParam) {
         if(searchedApiQuery  == "")
            {
               setSearchedApiQuery(trimmedSearchParam);
            }
         if (searchQuery) {
            updateAllTasks();
         }
      }
       else {
         updateAllTasks();
      }
   }, [debouncedSearch]);
   // useEffect(() => {
   //    const trimmedSearchParam = debouncedSearch.trim();
   //    if (trimmedSearchParam && trimmedSearchParam.length >= 3) {
   //       if(searchedApiQuery  == "")
   //          {
   //             setSearchedApiQuery(trimmedSearchParam);
   //          }
   //       if (searchQuery && searchQuery.length >= 3) {
   //          updateAllTasks();
   //       }
   //    }
   //     else {
   //       updateAllTasks();
   //    }
   // }, [debouncedSearch]);
   useEffect(() => {
      setSearchQuery('');
      setEvictionQueueTasks((prevAllLog) => ({
         ...prevAllLog,
         searchParam: ''
      }));
   }, [selectEvictionQueueId]);

   return (
      <MultiLineSearch
         value={searchQuery}
         handleInputChange={handleChange}
         handleSearchIconClick={handleSearchIconClick}
         handleCrossIconClick={handleCrossClick}
      />
   );
};

export default EvictionQueueTask_SearchBar;
