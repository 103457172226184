import React, { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "hooks/useDebounce";
import { useDissmissalsContext } from "../../DismissalsContext";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";

type Dismissals_SearchBarProps = {
   activeTab?: string;
};

const Dismissals_SearchBar = (props: Dismissals_SearchBarProps) => {
   // State for holding the search query
   const [searchQuery, setSearchQuery] = useState<string>("");
   const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');

   // Debounce the search query
   const debouncedSearch = useDebounce(searchQuery, 500);

   // Get necessary functions from the context
   const { 
      setAllSignedDismissals, 
      setAllUnsignedDismissals, 
      getAllDismissals, 
      unsignedDismissals, 
      signedDismissals,
      evictionAutomationDismissalApprovalsQueue,
      setEvictionAutomationDismissalApprovalsQueue,
      getEvictionAutomationDismissalApprovalsQueue
   } = useDissmissalsContext();

   useEffect(() => {
      if(props.activeTab === "Ready to Sign"){
         setSearchQuery(unsignedDismissals.searchParam ?? "");
      } else if(props.activeTab === "Signed Dismissals"){
         setSearchQuery(signedDismissals.searchParam ?? "");
      }
      else if(props.activeTab==="EA - Ready to Confirm")
         setSearchQuery(evictionAutomationDismissalApprovalsQueue.searchParam ?? "");
   },  []);

   // Handle change in the search input
   const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setSearchQuery(event.target.value);
   };

   // Update dismissals when the debounced search query changes
   const updateDismissals = (searchParam: string) => {
      const trimmedSearchParam = searchParam.trim();
      if (!trimmedSearchParam.length) return;
      setSearchQuery('');
      getAllDismissals(1, 100, props.activeTab === "Ready to Sign" ? false : true, trimmedSearchParam,"", props.activeTab === "Ready to Sign"?unsignedDismissals.isViewAll??true:true);
      // getAllDismissals(1, 100, props.activeTab === "Signed" ? false : true, trimmedSearchParam);
      setAllUnsignedDismissals((prevDismissals) => ({
         ...prevDismissals,
         searchParam: trimmedSearchParam
      }));
      setAllSignedDismissals((prevDismissals) => ({
         ...prevDismissals,
         searchParam: trimmedSearchParam
      }));
      if(props.activeTab==="EA - Ready to Confirm"){
         getEvictionAutomationDismissalApprovalsQueue(1,100,false,true,trimmedSearchParam);
         setEvictionAutomationDismissalApprovalsQueue((prevDismissals) => ({
            ...prevDismissals,
            searchParam: trimmedSearchParam
         }));

      }
   };


   const handleSearchIconClick = () => {
      const trimmedSearchParam = searchQuery.trim();
      setAllUnsignedDismissals((prev) => ({
         ...prev,
         searchParam: trimmedSearchParam,
      }));
      setAllSignedDismissals((prev) => ({
         ...prev,
         searchParam: trimmedSearchParam,
      }));
      // if (props.activeTab === "Signed") {
         getAllDismissals(1, 100, true, "","");
      // } else {
         getAllDismissals(1, 100, false, "","",unsignedDismissals.isViewAll??true);
      // }
      if(props.activeTab=="EA - Ready to Confirm"){
         getEvictionAutomationDismissalApprovalsQueue(1,100,false,true,trimmedSearchParam)
         setEvictionAutomationDismissalApprovalsQueue((prevDismissals) => ({
            ...prevDismissals,
            searchParam: trimmedSearchParam
         }));
      }
   };

   const handleCrossIconClick = () => {
      setSearchQuery("");
      setAllUnsignedDismissals((prev) => ({
         ...prev,
         searchParam: "",
      }));
      setAllSignedDismissals((prev) => ({
         ...prev,
         searchParam: "",
      }));
      // if (props.activeTab === "Signed") {
         getAllDismissals(1, 100, true, "",);

      // } else {
         getAllDismissals(1, 100, false, "","",unsignedDismissals.isViewAll??true);
      // }
      if(props.activeTab=="EA - Ready to Confirm"){
         getEvictionAutomationDismissalApprovalsQueue(1,100,false,true,"");
         setEvictionAutomationDismissalApprovalsQueue((prevDismissals) => ({
            ...prevDismissals,
            searchParam: ""
         }));
      }
   };
   // useEffect to trigger the API call when the debounced search query changes
   // useEffect(() => {
   //    const trimmedSearchParam = searchQuery.trim();
   //    if (debouncedSearch.trim()) {
   //       setAllUnsignedDismissals((prevDismissals) => ({
   //          ...prevDismissals,
   //          searchParam: trimmedSearchParam
   //       }));

   //       setAllSignedDismissals((prevDismissals) => ({
   //          ...prevDismissals,
   //          searchParam: trimmedSearchParam
   //       }));

   //       if (trimmedSearchParam && trimmedSearchParam.length >= 3) {
   //          if(searchedApiQuery  == "")
   //             {
   //                setSearchedApiQuery(trimmedSearchParam);
   //             }
   //          getAllDismissals(1, 100, props.activeTab === "Unsigned" ? false : true, trimmedSearchParam);
   //       }
   //    } 
   //    else if(trimmedSearchParam.length < searchedApiQuery.length)
   //       {
   //          setSearchedApiQuery(trimmedSearchParam);
   //          getAllDismissals(1, 100, props.activeTab === "Unsigned" ? false : true, trimmedSearchParam);
   //          setAllUnsignedDismissals((prevDismissals) => ({
   //             ...prevDismissals,
   //             searchParam: trimmedSearchParam
   //          }));
   
   //          setAllSignedDismissals((prevDismissals) => ({
   //             ...prevDismissals,
   //             searchParam: trimmedSearchParam
   //          }));
   //       }
   //    else {
   //       updateDismissals("");
   //    }
   // }, [debouncedSearch]);

   useEffect(() => {
      const trimmedSearchParam = searchQuery.trim();
      if (debouncedSearch.trim()) {
         setAllUnsignedDismissals((prevDismissals) => ({
            ...prevDismissals,
            searchParam: trimmedSearchParam
         }));

         setAllSignedDismissals((prevDismissals) => ({
            ...prevDismissals,
            searchParam: trimmedSearchParam
         }));

         setEvictionAutomationDismissalApprovalsQueue((prevDismissals) => ({
            ...prevDismissals,
            searchParam: trimmedSearchParam
         }));

         if (trimmedSearchParam) {
            if(searchedApiQuery  == "")
               {
                  setSearchedApiQuery(trimmedSearchParam);
               }
            if(props.activeTab=="EA - Ready to Confirm"){
               getEvictionAutomationDismissalApprovalsQueue(1,100,false,true,trimmedSearchParam)
            }
            else{
               getAllDismissals(1, 100, props.activeTab === "Ready to Sign" ? false : true, trimmedSearchParam,"",props.activeTab === "Ready to Sign"?unsignedDismissals.isViewAll??true:true);

            }
         }
      } 
      else if(trimmedSearchParam.length < searchedApiQuery.length)
         {
            setSearchedApiQuery(trimmedSearchParam);
            getAllDismissals(1, 100, props.activeTab === "Ready to Sign" ? false : true, trimmedSearchParam,"",props.activeTab === "Ready to Sign"?unsignedDismissals.isViewAll??true:true);
            setAllUnsignedDismissals((prevDismissals) => ({
               ...prevDismissals,
               searchParam: trimmedSearchParam
            }));
   
            setAllSignedDismissals((prevDismissals) => ({
               ...prevDismissals,
               searchParam: trimmedSearchParam
            }));
            
            if(props.activeTab=="EA - Ready to Confirm"){
               getEvictionAutomationDismissalApprovalsQueue(1,100,false,true,trimmedSearchParam);
               setEvictionAutomationDismissalApprovalsQueue((prevDismissals) => ({
                  ...prevDismissals,
                  searchParam: trimmedSearchParam
               }));
            }
         }
      else {
         updateDismissals("");
      }
   }, [debouncedSearch]);

   useEffect(() => {
      if (!unsignedDismissals.searchParam?.length) {
         setSearchQuery('');
      }

      if (!signedDismissals.searchParam?.length) {
         setSearchQuery('');
      }
      if (!evictionAutomationDismissalApprovalsQueue.searchParam?.length) {
         setSearchQuery('');
      }

   }, [unsignedDismissals.searchParam,evictionAutomationDismissalApprovalsQueue.searchParam,signedDismissals.searchParam]);
   useEffect(() => {
      setSearchQuery('');
      setAllUnsignedDismissals((prevDismissals) => ({
         ...prevDismissals,
         searchParam: ''
      }));
      setAllSignedDismissals((prevDismissals) => ({
         ...prevDismissals,
         searchParam: ''
      }));
      setEvictionAutomationDismissalApprovalsQueue((prevDismissals) => ({
         ...prevDismissals,
         searchParam: ''
      }));
   }, [props.activeTab]);

   // Render the MultiLineSearch component
   return (
      <MultiLineSearch
         value={searchQuery}
         handleInputChange={handleChange}
         handleSearchIconClick={handleSearchIconClick}
         handleCrossIconClick={handleCrossIconClick}
      />
   );
};

export default Dismissals_SearchBar;
