import React from 'react';
import { FaFilePdf } from 'react-icons/fa';
import { IAttachment } from 'interfaces/common.interface';
import HelperViewPdfService from "services/helperViewPdfService";

type AttachmentsInfoCardProps = {
   attachments: IAttachment[] | undefined;
};

const AttachmentsInfoCard: React.FC<AttachmentsInfoCardProps> = ({ attachments }: AttachmentsInfoCardProps) => {
   const openPdf = async (url: string) => {
      HelperViewPdfService.GetPdfView(url);
   };

   return (
      <div className='p-3.5 bg-white border border-gray-200 rounded shadow relative'>
         <h2 className='text-sm font-medium absolute bg-white top-[-8px] left-2.5 px-1.5 !leading-4'>Attachments</h2>
         <div className='flex gap-1 flex-wrap mt-1'>
            {attachments && attachments.map((item) => (
               // eslint-disable-next-line jsx-a11y/anchor-is-valid
               <a
                  target="_blank"
                  rel='noopener noreferrer'
                  onClick={() => openPdf(item.url)}
                  className='whitespace-nowrap rounded h-[21px] bg-[#f2f2f3] py-1 px-2 text-[11px] text-blue-500 hover:underline font-medium flex items-center gap-1 cursor-pointer'
               >
                  <FaFilePdf />
                  {item.type}
                  <span>{item.filedDate ?? ""}</span>
               </a>
            ))}
         </div>
      </div>
   );
};

export default AttachmentsInfoCard;
