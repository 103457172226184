import React, { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "hooks/useDebounce";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";
import { useFilingTransactionContext } from "pages/filing-transaction/FilingTransactionContext";

type FilingTransaction_SearchBarProps = {
   activeTab?: string;
};
const FilingTransaction_SearchBar = (props: FilingTransaction_SearchBarProps) => {
   const { filingTransactions, setFilingTransactions, getFilingTransactions,
       filingType,companyId, setCompanyId,dateFiled,datePaid, getAccountingQueue, setAccountingQueue, accountingQueue} = useFilingTransactionContext();
   const [searchQuery, setSearchQuery] = useState<string>('');
   const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');

   let debouncedSearch = useDebounce(searchQuery, 500, 3);

   // useEffect(() => {
   //    setSearchQuery(filingTransactions.searchParam ?? "");
   //    debouncedSearch = filingTransactions.searchParam ?? "";

   // }, []);

   useEffect(() => {
      setSearchQuery('');
      setFilingTransactions((prev) => ({ ...prev, searchParam: '' }));
      setAccountingQueue((prev) => ({ ...prev, searchParam: '' }));
   }, [props.activeTab]);

   const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      ;
      const { value } = event.target;
      setSearchQuery(value);
      // setSelectedAllCasesId([]);
      // setBulkRecords([]);
   };

   const handleCrossClick = () => {
      setSearchQuery('');
      if(props.activeTab != "Accounting Queue")
      {
      getFilingTransactions(1, 100, "",filingType,companyId, dateFiled[0] ,dateFiled[1], datePaid[0] ,datePaid[1],filingTransactions.blankOption);
      setFilingTransactions((prev) => ({ ...prev, searchParam: "" }));
      }
      else
      {
         getAccountingQueue(1, 100, "", accountingQueue.fromDatePaid,accountingQueue.toDatePaid
            , accountingQueue.type, accountingQueue.fromDatePayroll, accountingQueue.toDatePayroll
            , accountingQueue.fromDateCommission, accountingQueue.toDateCommission, accountingQueue.blankOption);
      setAccountingQueue((prev) => ({ ...prev, searchParam: "" }));
      }
   };

   const handleSearchIconClick = () => {
      const trimmedSearchParam = searchQuery.trim();
      if(props.activeTab != "Accounting Queue")
      {
      getFilingTransactions(1, 100, trimmedSearchParam,filingType,companyId, dateFiled[0] ,dateFiled[1], datePaid[0] ,datePaid[1],filingTransactions.blankOption);
      setFilingTransactions((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
      }
      else
      {
         getAccountingQueue(1, 100, trimmedSearchParam, accountingQueue.fromDatePaid,accountingQueue.toDatePaid
            , accountingQueue.type, accountingQueue.fromDatePayroll, accountingQueue.toDatePayroll, accountingQueue.fromDateCommission, accountingQueue.toDateCommission, accountingQueue.blankOption);
      setAccountingQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
      }
   };

   // useEffect(() => {
   //    const trimmedSearchParam = debouncedSearch.trim();
   //    setFilingTransactions(prev => ({ ...prev, searchParam: trimmedSearchParam }));
   //    if (trimmedSearchParam && trimmedSearchParam.length >= 3) {
   //       if(searchedApiQuery  == "")
   //       {
   //          setSearchedApiQuery(trimmedSearchParam);
   //       }
   //      getFilingTransactions(1, 100, trimmedSearchParam,filingType,companyId);
   //    }
   //    if(trimmedSearchParam.length < searchedApiQuery.length)
   //    {
   //       setSearchedApiQuery(trimmedSearchParam);
   //       getFilingTransactions(1, 100, trimmedSearchParam,filingType,companyId);
   //    }
   // }, [debouncedSearch]);

   useEffect(() => {
      ;
      const trimmedSearchParam = debouncedSearch.trim();
      if(props.activeTab != "Accounting Queue")
      {
      setFilingTransactions(prev => ({ ...prev, searchParam: trimmedSearchParam }));
      }
      else
      {
         setAccountingQueue(prev => ({ ...prev, searchParam: trimmedSearchParam }));
      }
      if (trimmedSearchParam) {
         if(searchedApiQuery  == "")
         {
            setSearchedApiQuery(trimmedSearchParam);
         }
         if(props.activeTab != "Accounting Queue")
         {
        getFilingTransactions(1, 100, trimmedSearchParam,filingType,companyId, dateFiled[0] ,dateFiled[1], datePaid[0] ,datePaid[1],filingTransactions.blankOption);
         }
         else
         {
            getAccountingQueue(1, 100, trimmedSearchParam, accountingQueue.fromDatePaid,accountingQueue.toDatePaid
               , accountingQueue.type, accountingQueue.fromDatePayroll, accountingQueue.toDatePayroll, accountingQueue.fromDateCommission, accountingQueue.toDateCommission,accountingQueue.blankOption);
         }
      }
      if(trimmedSearchParam.length < searchedApiQuery.length)
      {
         setSearchedApiQuery(trimmedSearchParam);
         if(props.activeTab != "Accounting Queue")
         {
         getFilingTransactions(1, 100, trimmedSearchParam,filingType,companyId, dateFiled[0] ,dateFiled[1], datePaid[0] ,datePaid[1],filingTransactions.blankOption);
         }
         else
         {
            getAccountingQueue(1, 100, trimmedSearchParam, accountingQueue.fromDatePaid,accountingQueue.toDatePaid
               , accountingQueue.type, accountingQueue.fromDatePayroll, accountingQueue.toDatePayroll, accountingQueue.fromDateCommission, accountingQueue.toDateCommission,accountingQueue.blankOption);
         }
      }
   }, [debouncedSearch]);

   useEffect(() => {
      if (!filingTransactions.searchParam?.length) {
         setSearchQuery('');
      }
   }, [filingTransactions.searchParam]);

   useEffect(() => {
      if (!accountingQueue.searchParam?.length) {
         setSearchQuery('');
      }
   }, [accountingQueue.searchParam]);

 


   return (
      <MultiLineSearch
         value={searchQuery}
         handleInputChange={handleInputChange}
         handleSearchIconClick={handleSearchIconClick}
         handleCrossIconClick={handleCrossClick}
      />
   );
};

export default FilingTransaction_SearchBar;
