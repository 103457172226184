import { AxiosResponse } from "axios";
import { IEditEmailQueueItem, IEmailQueueItem, IMailManagementItem, IServerEmailLogItem } from "interfaces/email-queue.interface";
import axios from "middlewares/axiosInstance";

const getEmailQueues = async (currentPage: number, pageSize: number, searchParam: string = '',companyId?:string,county?:string,serverId?:string,isExpedited?:number,isStateCourt?:number,staus?:string) => {
    const response: AxiosResponse =
       await axios({
           method: "GET",
           url: `api/ProcessServer/GetCasesToSendEmail/${currentPage}/${pageSize}?searchParam=${searchParam ?? ''}&companyId=${companyId??''}&county=${county ?? ''}&severEmail=${serverId??''}&isExpedited=${isExpedited??0}&isStateCourt=${isStateCourt??0}&status=${staus??''}`,
       });

   return response as AxiosResponse;
};

const sendEmailToServer = async (selectedIds: string[]) => {
    const response: AxiosResponse =
        await axios({
            method: "POST",
            url: `api/ProcessServer/SendEmailToServer`,
            data: selectedIds, 
        });
 
    return response as AxiosResponse;
};

const UpdateCaseInfo = async (data:IEditEmailQueueItem) => {    
    const response: AxiosResponse =
        await axios({
            method: "POST",
            url: `api/ProcessServer/UpdateCaseInfo`,  
            data:data,        
        });
 
    return response as AxiosResponse;
};

const GetProcessServer = async () => {    
    const response: AxiosResponse =
        await axios({
            method: "POST",
            url: `api/ProcessServer/GetAllProcessServer`,       
        });
 
    return response as AxiosResponse;
};
const editEmailQueueCaseInfoBulk = async (payload: IEmailQueueItem[]) => {
    const response: AxiosResponse =
        await axios({
            method: "PUT",
            url: `api/ProcessServer/EditCaseInfoBulk`,
            data: payload
        });

    return response as AxiosResponse;
};

const runManualCrawling = async () => {
    const response: AxiosResponse =
       await axios({
          method: "GET",
          url: `api/EvictionQueue/RunManualCrawling`,
       });
 
    return response as AxiosResponse;
 };

const getServerEmailLogs = async (currentPage: number, pageSize: number, searchParam: string = '') => {
   
    const response: AxiosResponse =
       await axios({
           method: "GET",
           url: `api/ProcessServer/GetProcessServerEmailHistory/${currentPage}/${pageSize}?searchParam=${searchParam ?? ''}`,
       });

   return response as AxiosResponse;
};

const editServerEmailLogInfoBulk = async (payload: IServerEmailLogItem[]) => {    
    const response: AxiosResponse =
        await axios({
            method: "PUT",
            url: `api/ProcessServer/UpdateServerEmailLogInfoBulk`,
            data: payload
        });

    return response as AxiosResponse;
};

const getMailManagementQueue = async (currentPage: number, pageSize: number, searchParam: string = '') => {
   const response: AxiosResponse =
      await axios({
          method: "GET",
          url: `api/ProcessServer/MailManagementQueue/${currentPage}/${pageSize}?searchParam=${searchParam ?? ''}`,
      });

  return response as AxiosResponse;
};

const updateMailManagementQueue = async (payload: IMailManagementItem[]) => {    
   const response: AxiosResponse =
       await axios({
           method: "PUT",
           url: `api/ProcessServer/MailManagementQueue`,
           data: payload
       });

   return response as AxiosResponse;
};

const EmailQueueService = {
   getEmailQueues,
   sendEmailToServer,
   UpdateCaseInfo,
   GetProcessServer,
   runManualCrawling,
   editEmailQueueCaseInfoBulk,
   getServerEmailLogs,
   editServerEmailLogInfoBulk,
   getMailManagementQueue,
   updateMailManagementQueue
};

export default EmailQueueService;