import React, { ChangeEvent, useEffect, useState } from "react";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";
import { useDebounce } from "hooks/useDebounce";
import { useEvictionAutomationContext } from "pages/eviction-automation/EvictionAutomationContext";
import { IEvictionAutomationStatusQueue } from "interfaces/eviction-automation.interface";

type User_SearchBarProps = {
   activeTab: string;
};

const User_SearchBar = ({ activeTab }: User_SearchBarProps) => {
   const [searchQuery, setSearchQuery] = useState<string>('');
   const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');
   const debouncedSearch = useDebounce(searchQuery, 500, 3);

   const {
      evictionAutomationApprovedQueue,
      setEvictionAutomationApprovedQueue,
      evictionAutomationApprovalsQueue,
      setEvictionAutomationApprovalsQueue,
      getEvictionAutomationApprovalsQueue,
      evictionAutomationQueue,
      setEvictionAutomationQueue,
      getEvictionAutomationQueue,
      evictionAutomationStatusQueue,
      setEvictionAutomationStatusQueue,
      getEvictionAutomationStatusQueue,
      evictionAutomationStatusAllQueueData,
      setEvictionAutomationStatusAllQueueData,
      evictionAutomationDismissalApprovalsQueue,
      evictionAutomationDismissalApprovedQueue,
      getEvictionAutomationDismissalApprovalsQueue,
      setEvictionAutomationDismissalApprovalsQueue,
      setEvictionAutomationDismissalApprovedQueue,
      getEvictionAutomationNotices,
      setEvictionAutomationNotices,
      evictionAutomationNotices,
      transactionCodes,
      getTransactionCodes,
      setTransactionCodes
   } = useEvictionAutomationContext();

   // Search on the basis of property name, property address, tenant name, case number, or filer email
   const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      setSearchQuery(value);
   };

   const handleCrossClick = () => {
      setSearchQuery('');
      if (activeTab === "Settings") {
         getEvictionAutomationQueue(1, 100, "",evictionAutomationQueue.companyId,evictionAutomationQueue.county,evictionAutomationQueue.isExpedited,evictionAutomationQueue.isStateCourt);
         setEvictionAutomationQueue((prev) => ({ ...prev, searchParam: "" }));
      }

      if (activeTab === "Transaction Codes") {
         getTransactionCodes(1, 100, "",transactionCodes.companyId,transactionCodes.propertyId);
         setTransactionCodes((prev) => ({ ...prev, searchParam: "" }));
      }

      if (activeTab === "Confirm Delinquencies") {
         getEvictionAutomationApprovalsQueue(1, 100,false,evictionAutomationApprovalsQueue.isViewAll, "");
         setEvictionAutomationApprovalsQueue((prev) => ({ ...prev, searchParam: "" }));
      }
      if (activeTab === "EA - Notices") {
         getEvictionAutomationNotices(1, 100,"");
         setEvictionAutomationNotices((prev) => ({ ...prev, searchParam: "" }));
      }
      if (activeTab === "Sign Evictions") {
         getEvictionAutomationApprovalsQueue(1, 100,true,evictionAutomationApprovalsQueue.isViewAll, "");
         setEvictionAutomationApprovedQueue((prev) => ({ ...prev, searchParam: "" }));
      }
      if (activeTab === "Status") {
      setEvictionAutomationStatusQueue({
         ...evictionAutomationStatusAllQueueData,
         searchParam: ""
     });
 
      }
      if (activeTab === "Confirm Dismissals") {
         getEvictionAutomationDismissalApprovalsQueue(1, 100,false,evictionAutomationDismissalApprovalsQueue.isViewAll, "");
         setEvictionAutomationDismissalApprovalsQueue((prev) => ({ ...prev, searchParam: "" }));
      }
      if (activeTab === "Sign Dismissals") {
         getEvictionAutomationDismissalApprovalsQueue(1, 100,true,evictionAutomationDismissalApprovedQueue.isViewAll, "");
         setEvictionAutomationDismissalApprovedQueue((prev) => ({ ...prev, searchParam: "" }));
      }
      if (activeTab === "Signed") {
         // getEvictionAutomationApprovalsQueue(1, 100,true, "");
         // setEvictionAutomationApprovedQueue((prev) => ({ ...prev, searchParam: "" }));
      }
   };

   const handleSearchIconClick = () => {   
      const trimmedSearchParam = searchQuery.trim();
      if (activeTab === "Settings") {
         getEvictionAutomationQueue(1, 100, trimmedSearchParam,evictionAutomationQueue.companyId,evictionAutomationQueue.county,evictionAutomationQueue.isExpedited,evictionAutomationQueue.isStateCourt);
         setEvictionAutomationQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
      }
      if (activeTab === "Transaction Codes") {
         getTransactionCodes(1, 100, trimmedSearchParam,transactionCodes.companyId,transactionCodes.propertyId);
         setTransactionCodes((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
      }

      if (activeTab === "Confirm Delinquencies") {
         getEvictionAutomationApprovalsQueue(1, 100,false,evictionAutomationApprovalsQueue.isViewAll, trimmedSearchParam);
         setEvictionAutomationApprovalsQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
      }
      if (activeTab === "EA - Notices") {
         getEvictionAutomationNotices(1, 100,trimmedSearchParam);
         setEvictionAutomationNotices((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
      }
      if (activeTab === "Sign Evictions") {
         getEvictionAutomationApprovalsQueue(1, 100,true,evictionAutomationApprovedQueue.isViewAll, trimmedSearchParam);
         setEvictionAutomationApprovedQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
      }
      if (activeTab === "Status") {
         // getEvictionAutomationStatusQueue(1, 100,trimmedSearchParam);
         const filteredData = evictionAutomationStatusAllQueueData.items.filter(item =>
            Object.values(item).some(value =>
                typeof value === 'string' && value.toLowerCase().includes(trimmedSearchParam)
            )
        );
         setEvictionAutomationStatusQueue((prev) => ({ ...prev, filteredData, searchParam: trimmedSearchParam}));

      }
      if (activeTab === "Confirm Dismissals") {
         getEvictionAutomationDismissalApprovalsQueue(1, 100,false,evictionAutomationDismissalApprovalsQueue.isViewAll, trimmedSearchParam);
         setEvictionAutomationDismissalApprovalsQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
      }
      if (activeTab === "Sign Dismissals") {
         getEvictionAutomationDismissalApprovalsQueue(1, 100,true,evictionAutomationDismissalApprovedQueue.isViewAll, trimmedSearchParam);
         setEvictionAutomationDismissalApprovedQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
      }     
   };

   // useEffect(() => {
      
   //    const trimmedSearchParam = debouncedSearch.trim();
   //    var filteredData: IEvictionAutomationStatusQueue;

   //    if (activeTab === "Settings") {
   //       setEvictionAutomationQueue(prev => ({ ...prev, searchParam: trimmedSearchParam }));
   //    } else if (activeTab === "Confirm Delinquencies") {
   //       setEvictionAutomationApprovalsQueue(prev => ({ ...prev, searchParam: trimmedSearchParam }));
   //    }else if (activeTab === "Sign Evictions") {
   //       setEvictionAutomationApprovedQueue(prev => ({ ...prev, searchParam: trimmedSearchParam }));
   //    }else if (activeTab === "Status") {
   //       setEvictionAutomationStatusQueue(prev => ({ ...prev, searchParam: trimmedSearchParam }));
   //       // setEvictionAutomationStatusQueue(prev => ({ ...prev,filteredData, searchParam: trimmedSearchParam }));
   //    }else if (activeTab === "Confirm Dismissals") {
   //       setEvictionAutomationDismissalApprovalsQueue(prev => ({ ...prev, searchParam: trimmedSearchParam }));
   //    }else if (activeTab === "Sign Dismissals") {
   //       setEvictionAutomationDismissalApprovedQueue(prev => ({ ...prev, searchParam: trimmedSearchParam }));
   //    }

   //    if (trimmedSearchParam.length >= 3) {
   //       if(searchedApiQuery  == "")
   //          {
   //             setSearchedApiQuery(trimmedSearchParam);
   //          }
   //       if (activeTab === "Settings") {
   //          getEvictionAutomationQueue(1, 100, trimmedSearchParam);
   //       } else if (activeTab === "Confirm Delinquencies") {
   //          getEvictionAutomationApprovalsQueue(1, 100,false,evictionAutomationApprovalsQueue.isViewAll,trimmedSearchParam);
   //       }else if (activeTab === "Sign Evictions") {
   //          getEvictionAutomationApprovalsQueue(1, 100,true,evictionAutomationApprovedQueue.isViewAll,trimmedSearchParam);
   //       }else if (activeTab === "Status") {
   
   //          var data  = evictionAutomationStatusAllQueueData.items.filter(item =>
   //             item.name.toLowerCase().includes(trimmedSearchParam.toLowerCase())
   //             || item.ownerId.toLowerCase().includes(trimmedSearchParam.toLowerCase())
   //             || item.propertyId.toLowerCase().includes(trimmedSearchParam.toLowerCase())
   //             || new Date(item.pullDate).toLocaleString().includes(trimmedSearchParam.toLowerCase())
   //             || item.status.toLowerCase().includes(trimmedSearchParam.toLowerCase())
   //             || item.batchId.toLowerCase().includes(trimmedSearchParam.toLowerCase())
   //             || item.docUrl.toLowerCase().includes(trimmedSearchParam.toLowerCase())
   //         );

   //         var TotalCount = data.length;
   //        setEvictionAutomationStatusQueue(prev => ({  ...prev,items: data,searchParam: trimmedSearchParam,totalCount:TotalCount,}));
   //         console.log(evictionAutomationStatusAllQueueData);
        
   //       }else if (activeTab === "Confirm Dismissals") {
   //          getEvictionAutomationDismissalApprovalsQueue(1, 100,false,evictionAutomationApprovalsQueue.isViewAll,trimmedSearchParam);
   //       }else if (activeTab === "Sign Dismissals") {
   //          getEvictionAutomationDismissalApprovalsQueue(1, 100,true,evictionAutomationApprovedQueue.isViewAll,trimmedSearchParam);
   //       }else if (activeTab === "Signed") {
   //          // getEvictionAutomationApprovalsQueue(1, 100,true,trimmedSearchParam);
   //       }
   //    }
   //    if(trimmedSearchParam.length < searchedApiQuery.length)
   //       {
   //          setSearchedApiQuery(trimmedSearchParam);
   //          if (activeTab === "Settings") {
   //             getEvictionAutomationQueue(1, 100, trimmedSearchParam);
   //             setEvictionAutomationQueue((prev) => ({ ...prev, searchParam: "" }));
   //          } else if (activeTab === "Confirm Delinquencies") {
   //             getEvictionAutomationApprovalsQueue(1, 100,false,evictionAutomationApprovalsQueue.isViewAll,trimmedSearchParam);
   //             setEvictionAutomationApprovalsQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
   //          }else if (activeTab === "Sign Evictions") {
   //             getEvictionAutomationApprovalsQueue(1, 100,true,evictionAutomationApprovedQueue.isViewAll,trimmedSearchParam);
   //             setEvictionAutomationApprovedQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
   //          }else if (activeTab === "Status") {
      
   //             var data  = evictionAutomationStatusAllQueueData.items.filter(item =>
   //                item.name.toLowerCase().includes(trimmedSearchParam.toLowerCase())
   //                || item.ownerId.toLowerCase().includes(trimmedSearchParam.toLowerCase())
   //                || item.propertyId.toLowerCase().includes(trimmedSearchParam.toLowerCase())
   //                || new Date(item.pullDate).toLocaleString().includes(trimmedSearchParam.toLowerCase())
   //                || item.status.toLowerCase().includes(trimmedSearchParam.toLowerCase())
   //                || item.batchId.toLowerCase().includes(trimmedSearchParam.toLowerCase())
   //                || item.docUrl.toLowerCase().includes(trimmedSearchParam.toLowerCase())
   //            );
   
   //            var TotalCount = data.length;
   //           setEvictionAutomationStatusQueue(prev => ({  ...prev,items: data,searchParam: trimmedSearchParam,totalCount:TotalCount,}));
   //           setEvictionAutomationStatusQueue({
   //             ...evictionAutomationStatusAllQueueData,
   //             searchParam: trimmedSearchParam
   //         });
   //            console.log(evictionAutomationStatusAllQueueData);
           
   //          }else if (activeTab === "Confirm Dismissals") {
   //             getEvictionAutomationDismissalApprovalsQueue(1, 100,false,evictionAutomationDismissalApprovalsQueue.isViewAll,trimmedSearchParam);
   //             setEvictionAutomationDismissalApprovalsQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
   //          }else if (activeTab === "Sign Dismissals") {
   //             getEvictionAutomationDismissalApprovalsQueue(1, 100,true,evictionAutomationDismissalApprovedQueue.isViewAll,trimmedSearchParam);
   //             setEvictionAutomationDismissalApprovedQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
   //          }else if (activeTab === "Signed") {
   //             // getEvictionAutomationApprovalsQueue(1, 100,true,trimmedSearchParam);
   //          }
   //       }
   // }, [debouncedSearch, activeTab]);

   useEffect(() => {
      const trimmedSearchParam = debouncedSearch.trim();
      var filteredData: IEvictionAutomationStatusQueue;

      if (activeTab === "Settings") {
         setEvictionAutomationQueue(prev => ({ ...prev, searchParam: trimmedSearchParam }));
      } else if (activeTab === "Confirm Delinquencies") {
         setEvictionAutomationApprovalsQueue(prev => ({ ...prev, searchParam: trimmedSearchParam }));
      }
      else if (activeTab === "Transaction Codes") {
         setTransactionCodes(prev => ({ ...prev, searchParam: trimmedSearchParam }));
      }
      else if (activeTab === "EA - Notices") {
         setEvictionAutomationNotices(prev => ({ ...prev, searchParam: trimmedSearchParam }));
      }
      else if (activeTab === "Sign Evictions") {
         setEvictionAutomationApprovedQueue(prev => ({ ...prev, searchParam: trimmedSearchParam }));
      }else if (activeTab === "Status") {
         setEvictionAutomationStatusQueue(prev => ({ ...prev, searchParam: trimmedSearchParam }));
         // setEvictionAutomationStatusQueue(prev => ({ ...prev,filteredData, searchParam: trimmedSearchParam }));
      }else if (activeTab === "Confirm Dismissals") {
         setEvictionAutomationDismissalApprovalsQueue(prev => ({ ...prev, searchParam: trimmedSearchParam }));
      }else if (activeTab === "Sign Dismissals") {
         setEvictionAutomationDismissalApprovedQueue(prev => ({ ...prev, searchParam: trimmedSearchParam }));
      }

      if (trimmedSearchParam) {
         if(searchedApiQuery  == "")
            {
               setSearchedApiQuery(trimmedSearchParam);
            }
         if (activeTab === "Settings") {
            getEvictionAutomationQueue(1, 100, trimmedSearchParam,evictionAutomationQueue.companyId,evictionAutomationQueue.county,evictionAutomationQueue.isExpedited,evictionAutomationQueue.isStateCourt);
         } else if (activeTab === "Confirm Delinquencies") {
            getEvictionAutomationApprovalsQueue(1, 100,false,evictionAutomationApprovalsQueue.isViewAll,trimmedSearchParam);
         }
         else if (activeTab === "Transaction Codes") {
            getTransactionCodes(1, 100,trimmedSearchParam,transactionCodes.companyId,transactionCodes.propertyId);
         }
         else if (activeTab === "EA - Notices") {
            getEvictionAutomationNotices(1, 100,trimmedSearchParam);
         }
         else if (activeTab === "Sign Evictions") {
            getEvictionAutomationApprovalsQueue(1, 100,true,evictionAutomationApprovedQueue.isViewAll,trimmedSearchParam);
         }else if (activeTab === "Status") {
   
            var data  = evictionAutomationStatusAllQueueData.items.filter(item =>
               item.name.toLowerCase().includes(trimmedSearchParam.toLowerCase())
               || item.ownerId.toLowerCase().includes(trimmedSearchParam.toLowerCase())
               || item.propertyId.toLowerCase().includes(trimmedSearchParam.toLowerCase())
               || new Date(item.pullDate).toLocaleString().includes(trimmedSearchParam.toLowerCase())
               || item.status.toLowerCase().includes(trimmedSearchParam.toLowerCase())
               || item.batchId.toLowerCase().includes(trimmedSearchParam.toLowerCase())
               || item.docUrl.toLowerCase().includes(trimmedSearchParam.toLowerCase())
           );

           var TotalCount = data.length;
          setEvictionAutomationStatusQueue(prev => ({  ...prev,items: data,searchParam: trimmedSearchParam,totalCount:TotalCount,}));
           console.log(evictionAutomationStatusAllQueueData);
        
         }else if (activeTab === "Confirm Dismissals") {
            getEvictionAutomationDismissalApprovalsQueue(1, 100,false,evictionAutomationApprovalsQueue.isViewAll,trimmedSearchParam);
         }else if (activeTab === "Sign Dismissals") {
            getEvictionAutomationDismissalApprovalsQueue(1, 100,true,evictionAutomationApprovedQueue.isViewAll,trimmedSearchParam);
         }else if (activeTab === "Signed") {
            // getEvictionAutomationApprovalsQueue(1, 100,true,trimmedSearchParam);
         }
      }
      if(trimmedSearchParam.length < searchedApiQuery.length)
         {
            setSearchedApiQuery(trimmedSearchParam);
            if (activeTab === "Settings") {
               getEvictionAutomationQueue(1, 100, trimmedSearchParam,evictionAutomationQueue.companyId,evictionAutomationQueue.county,evictionAutomationQueue.isExpedited,evictionAutomationQueue.isStateCourt);
               setEvictionAutomationQueue((prev) => ({ ...prev, searchParam: "" }));
            } else if (activeTab === "Confirm Delinquencies") {
               getEvictionAutomationApprovalsQueue(1, 100,false,evictionAutomationApprovalsQueue.isViewAll,trimmedSearchParam);
               setEvictionAutomationApprovalsQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
            }
            else if (activeTab === "Transaction Codes") {
               getTransactionCodes(1, 100,trimmedSearchParam, transactionCodes.companyId,transactionCodes.propertyId);
               setTransactionCodes((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
            }
            else if (activeTab === "EA - Notices") {
               getEvictionAutomationNotices(1, 100,trimmedSearchParam);
               setEvictionAutomationNotices((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
            }
            else if (activeTab === "Sign Evictions") {
               getEvictionAutomationApprovalsQueue(1, 100,true,evictionAutomationApprovedQueue.isViewAll,trimmedSearchParam);
               setEvictionAutomationApprovedQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
            }else if (activeTab === "Status") {
      
               var data  = evictionAutomationStatusAllQueueData.items.filter(item =>
                  item.name.toLowerCase().includes(trimmedSearchParam.toLowerCase())
                  || item.ownerId.toLowerCase().includes(trimmedSearchParam.toLowerCase())
                  || item.propertyId.toLowerCase().includes(trimmedSearchParam.toLowerCase())
                  || new Date(item.pullDate).toLocaleString().includes(trimmedSearchParam.toLowerCase())
                  || item.status.toLowerCase().includes(trimmedSearchParam.toLowerCase())
                  || item.batchId.toLowerCase().includes(trimmedSearchParam.toLowerCase())
                  || item.docUrl.toLowerCase().includes(trimmedSearchParam.toLowerCase())
              );
   
              var TotalCount = data.length;
             setEvictionAutomationStatusQueue(prev => ({  ...prev,items: data,searchParam: trimmedSearchParam,totalCount:TotalCount,}));
             setEvictionAutomationStatusQueue({
               ...evictionAutomationStatusAllQueueData,
               searchParam: trimmedSearchParam
           });
              console.log(evictionAutomationStatusAllQueueData);
           
            }else if (activeTab === "Confirm Dismissals") {
               getEvictionAutomationDismissalApprovalsQueue(1, 100,false,evictionAutomationDismissalApprovalsQueue.isViewAll,trimmedSearchParam);
               setEvictionAutomationDismissalApprovalsQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
            }else if (activeTab === "Sign Dismissals") {
               getEvictionAutomationDismissalApprovalsQueue(1, 100,true,evictionAutomationDismissalApprovedQueue.isViewAll,trimmedSearchParam);
               setEvictionAutomationDismissalApprovedQueue((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
            }else if (activeTab === "Signed") {
               // getEvictionAutomationApprovalsQueue(1, 100,true,trimmedSearchParam);
            }
         }
   }, [debouncedSearch, activeTab]);


   useEffect(() => {
      if (!evictionAutomationApprovalsQueue.searchParam?.length) {
         setSearchQuery('');
      }
   }, [evictionAutomationApprovalsQueue.searchParam]);
   useEffect(() => {
      if (!evictionAutomationApprovedQueue.searchParam?.length) {
         setSearchQuery('');
      }
   }, [evictionAutomationApprovedQueue.searchParam]);
   useEffect(() => {
      if (!evictionAutomationNotices.searchParam?.length) {
         setSearchQuery('');
      }
   }, [evictionAutomationNotices.searchParam]);
   useEffect(() => {
      if (!evictionAutomationDismissalApprovalsQueue.searchParam?.length) {
         setSearchQuery('');
      }
   }, [evictionAutomationDismissalApprovalsQueue.searchParam]);
   useEffect(() => {
      if (!evictionAutomationDismissalApprovedQueue.searchParam?.length) {
         setSearchQuery('');
      }
   }, [evictionAutomationDismissalApprovedQueue.searchParam]);

   useEffect(() => {
      if (!evictionAutomationQueue.searchParam?.length) {
         setSearchQuery('');
      }
   }, [evictionAutomationQueue.searchParam]);
   useEffect(() => {
      if (!transactionCodes.searchParam?.length) {
         setSearchQuery('');
      }
   }, [transactionCodes.searchParam]);
   useEffect(() => {
      
      if (!evictionAutomationStatusQueue.searchParam?.length) {
         setSearchQuery('');
      }
   }, [evictionAutomationStatusQueue.searchParam]);

   useEffect(() => {
      setSearchQuery('');
      setEvictionAutomationApprovedQueue((prev) => ({ ...prev, searchParam: '' }));
      setEvictionAutomationApprovalsQueue((prev) => ({ ...prev, searchParam: '' }));
      setEvictionAutomationNotices((prev) => ({ ...prev, searchParam: '' }));
      setEvictionAutomationDismissalApprovalsQueue((prev) => ({ ...prev, searchParam: '' }));
      setEvictionAutomationDismissalApprovedQueue((prev) => ({ ...prev, searchParam: '' }));
      setEvictionAutomationStatusQueue(prev => ({  ...prev,searchParam: ''}));
      setEvictionAutomationStatusQueue({
        ...evictionAutomationStatusAllQueueData,
        searchParam: ''
    });
    setEvictionAutomationQueue((prev) => ({ ...prev, searchParam: "" }));
    setTransactionCodes((prev) => ({ ...prev, searchParam: "" }));

   }, [activeTab]);

   return (
      <MultiLineSearch
         value={searchQuery}
         handleInputChange={handleInputChange}
         handleSearchIconClick={handleSearchIconClick}
         handleCrossIconClick={handleCrossClick}
         isVisible={activeTab !== "Propexo"}
      />
   );
};

export default User_SearchBar;
