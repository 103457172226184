import React, { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "hooks/useDebounce";
import { useAllCasesContext } from "../../AllCasesContext";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";

const AllCases_SearchBar = () => {
   const { allCases, setAllCases, getAllCases, setSelectedAllCasesId, setBulkRecords } = useAllCasesContext();
   const [searchQuery, setSearchQuery] = useState<string>('');
   const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');

   let debouncedSearch = useDebounce(searchQuery, 500, 3);

   useEffect(() => {
      setSearchQuery(allCases.searchParam ?? "");
      debouncedSearch = allCases.searchParam ?? "";

   }, []);

   // Search on the basis of property name, property address, tenant name, case number, or filer email
   const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      setSearchQuery(value);
      setSelectedAllCasesId([]);
      setBulkRecords([]);
   };

   const handleCrossClick = () => {
      setSearchQuery('');
      getAllCases(1, 100, "", allCases.status, allCases.county,allCases.filingType);
      setAllCases((prev) => ({ ...prev, searchParam: "" }));
   };

   const handleSearchIconClick = () => {
      const trimmedSearchParam = searchQuery.trim();
      getAllCases(1, 100, trimmedSearchParam, allCases.status, allCases.county,allCases.filingType);
      setAllCases((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
   };

   // useEffect(() => {
   //    const trimmedSearchParam = debouncedSearch.trim();
   //    setAllCases(prev => ({ ...prev, searchParam: trimmedSearchParam }));
   //    if (trimmedSearchParam && trimmedSearchParam.length >= 3) {
   //       if(searchedApiQuery  == "")
   //          {
   //             setSearchedApiQuery(trimmedSearchParam);
   //          }
   //       getAllCases(1, 100, trimmedSearchParam, allCases.status, allCases.county);
   //    }
   //    if(trimmedSearchParam.length < searchedApiQuery.length)
   //       {
   //          setSearchedApiQuery(trimmedSearchParam);
   //          getAllCases(1, 100, trimmedSearchParam, allCases.status, allCases.county);
   //       }
   // }, [debouncedSearch]);
   useEffect(() => {
      const trimmedSearchParam = debouncedSearch.trim();
      setAllCases(prev => ({ ...prev, searchParam: trimmedSearchParam }));
      if (trimmedSearchParam) {
         if(searchedApiQuery  == "")
            {
               setSearchedApiQuery(trimmedSearchParam);
            }
         getAllCases(1, 100, trimmedSearchParam, allCases.status, allCases.county,allCases.filingType);
      }
      if(trimmedSearchParam.length < searchedApiQuery.length)
         {
            setSearchedApiQuery(trimmedSearchParam);
            getAllCases(1, 100, trimmedSearchParam, allCases.status, allCases.county,allCases.filingType);
         }
   }, [debouncedSearch]);

   useEffect(() => {
      if (!allCases.searchParam?.length) {
         setSearchQuery('');
      }
   }, [allCases.searchParam]);

   return (
      <MultiLineSearch
         value={searchQuery}
         handleInputChange={handleInputChange}
         handleSearchIconClick={handleSearchIconClick}
         handleCrossIconClick={handleCrossClick}
      />
   );
};

export default AllCases_SearchBar;
