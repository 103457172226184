import React, { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "hooks/useDebounce";
import { useEmailQueueContext } from "../../EmailQueueContext";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";

type EmailQueue_SearchBarProps = {
   activeTab?: string;
};

const EmailQueue_SearchBar = (props:EmailQueue_SearchBarProps) => {
   const { 
      emailQueues, 
      setEmailQueues, 
      getEmailQueues,
      serverEmailLogs,
      setServerEmailLogs,
      getServerEmailLogs,
      mailManagementQueue,
      getMailManagementQueue,
      setMailManagementQueue
   } = useEmailQueueContext();
   const [searchQuery, setSearchQuery] = useState<string>('');
   const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');

   const debouncedSearch = useDebounce(searchQuery, 500, 3);

   // Search on the basis of property name, property address, tenant name, case number, or filer email
   const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.target;
      setSearchQuery(value);
   };

   const handleCrossClick = () => {
      setSearchQuery('');
      getEmailQueues(1, 100, "",emailQueues.companyId,emailQueues.county,emailQueues.serverId);
      setEmailQueues((prev) => ({ ...prev, searchParam: "" }));
   };

   const handleSearchIconClick = () => {
      const trimmedSearchParam = searchQuery.trim();
      getEmailQueues(1, 100, trimmedSearchParam,emailQueues.companyId,emailQueues.county,emailQueues.serverId);
      setEmailQueues((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
   };
   // useEffect(() => {
   //    const trimmedSearchParam = debouncedSearch.trim();
   //    setEmailQueues(prev => ({ ...prev, searchParam: trimmedSearchParam }));
   //    if (trimmedSearchParam && trimmedSearchParam.length >= 3) {
   //       if(searchedApiQuery  == "")
   //          {
   //             setSearchedApiQuery(trimmedSearchParam);
   //          }
   //       getEmailQueues(1, 100, trimmedSearchParam,emailQueues.companyId,emailQueues.county,emailQueues.serverId);
   //    }
   //    if(trimmedSearchParam.length < searchedApiQuery.length)
   //       {
   //          setSearchedApiQuery(trimmedSearchParam);
   //          getEmailQueues(1, 100, trimmedSearchParam,emailQueues.companyId,emailQueues.county,emailQueues.serverId);
   //       }
   // }, [debouncedSearch]);

   useEffect(() => {
      const trimmedSearchParam = searchQuery.trim();
      if (debouncedSearch.trim()) {
         setEmailQueues((prev) => ({
            ...prev,
            searchParam: trimmedSearchParam
         }));
         setServerEmailLogs((prev) => ({
            ...prev,
            searchParam: trimmedSearchParam,
         }));
         setMailManagementQueue((prev) => ({
            ...prev,
            searchParam: trimmedSearchParam,
         }));

         if (trimmedSearchParam) {
            if(searchedApiQuery === "")
               {
                  setSearchedApiQuery(trimmedSearchParam);
               }
               if(props.activeTab === "Process Evictions"){
                  getEmailQueues(1,100,trimmedSearchParam,emailQueues.companyId,emailQueues.county,emailQueues.serverId,emailQueues.isExpedited,emailQueues.isStateCourt);
               } else if(props.activeTab === "Process Server Eviction"){
                  getServerEmailLogs(1,100,trimmedSearchParam);
               } else if(props.activeTab === "Mail Management"){
                  getMailManagementQueue(1,100,trimmedSearchParam);
               }
         }      
         }
         else if(trimmedSearchParam.length < searchedApiQuery.length)
            {
               setSearchedApiQuery(trimmedSearchParam);
               if(props.activeTab === "Process Evictions"){
                  getEmailQueues(1,100,trimmedSearchParam,emailQueues.companyId,emailQueues.county,emailQueues.serverId,emailQueues.isExpedited,emailQueues.isStateCourt);
               } else if(props.activeTab === "Process Server Eviction"){
                  getServerEmailLogs(1,100,trimmedSearchParam);
               }  else if(props.activeTab === "Mail Management"){
                  getMailManagementQueue(1,100,trimmedSearchParam);
               }
               setEmailQueues((prev) => ({
                  ...prev,
                  searchParam: trimmedSearchParam
               }));
               setServerEmailLogs((prev) => ({
                  ...prev,
                  searchParam: trimmedSearchParam,
               }));
               setMailManagementQueue((prev) => ({
                  ...prev,
                  searchParam: trimmedSearchParam,
               }));
            }
       else {
         updateQueue("");
      }
   }, [debouncedSearch]);

   useEffect(() => {
      setSearchQuery('');
      setEmailQueues((prev) => ({
         ...prev,
         searchParam: '',
      }));
      setServerEmailLogs((prev) => ({
         ...prev,
         searchParam: '',
      }));
      setMailManagementQueue((prev) => ({
         ...prev,
         searchParam: '',
      }));
   }, [props.activeTab]);

   useEffect(() => {
      if(props.activeTab === "Process Evictions"){
         setSearchQuery(emailQueues.searchParam ?? "");
      } else if(props.activeTab === "Process Server Eviction"){
         setSearchQuery(serverEmailLogs.searchParam ?? "");
      } else if(props.activeTab === "Mail Management"){
         setSearchQuery(mailManagementQueue.searchParam ?? "");
      }
   },  []);

   const updateQueue = (searchParam: string) => {
      const trimmedSearchParam = searchParam.trim();
      if (!trimmedSearchParam.length) return;
      setSearchQuery('');
      if(props.activeTab === "Process Evictions"){
         getEmailQueues(1,100,trimmedSearchParam,emailQueues.companyId,emailQueues.county,emailQueues.serverId,emailQueues.isExpedited,emailQueues.isStateCourt);
      } else if(props.activeTab === "Process Server Eviction"){
         getServerEmailLogs(1,100,trimmedSearchParam);
      }  else if(props.activeTab === "Mail Management"){
         getMailManagementQueue(1,100,trimmedSearchParam);
      } 
      setEmailQueues((prev) => ({
         ...prev,
         searchParam: trimmedSearchParam
      }));
      setServerEmailLogs((prev) => ({
         ...prev,
         searchParam: trimmedSearchParam,
      }));
      setMailManagementQueue((prev) => ({
         ...prev,
         searchParam: trimmedSearchParam,
      }));
   };

   useEffect(() => {
      setSearchQuery('');
      setEmailQueues((prev) => ({
         ...prev,
         searchParam: '',
      }));
      setServerEmailLogs((prev) => ({
         ...prev,
         searchParam: '',
      }));
      setMailManagementQueue((prev) => ({
         ...prev,
         searchParam: '',
      }));
   }, [props.activeTab]);

   return (
      <MultiLineSearch
         value={searchQuery}
         handleInputChange={handleInputChange}
         handleSearchIconClick={handleSearchIconClick}
         handleCrossIconClick={handleCrossClick}
      />
   );
};

export default EmailQueue_SearchBar;
