import React, { ChangeEvent, useEffect, useState } from "react";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";
import { useDebounce } from "hooks/useDebounce";
import { useServiceTrackerContext } from "../../ServiceTrackerContext";

type ServiceTracker_SearchBarProps = {
  activeTab?: string;
};
const ServiceTracker_SearchBar = (props: ServiceTracker_SearchBarProps) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');

  const debouncedSearch = useDebounce(searchQuery, 500, 3);

  const { setServiceTracker, getServiceTracker,
    setUnservedQueue, getUnservedQueue
  } = useServiceTrackerContext();

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSearchQuery(event.target.value);
  };

  const updateServiceTracker = (searchParam: string) => {
    const trimmedSearchParam = searchParam.trim();
    if (searchParam.length >= 3) {
      if (props.activeTab == "Tracker") {
        getServiceTracker(1, 100, trimmedSearchParam);
        setServiceTracker((prev) => ({
          ...prev,
          searchParam: trimmedSearchParam,
        }));
      }
      else {
        getUnservedQueue(1, 100, trimmedSearchParam);
        setUnservedQueue((prev) => ({
          ...prev,
          searchParam: trimmedSearchParam,
        }));
      }
    }
    if (trimmedSearchParam.length < searchedApiQuery.length) {
      setSearchedApiQuery(trimmedSearchParam);
      if (props.activeTab == "Tracker") {
        getServiceTracker(1, 100, trimmedSearchParam);
        setServiceTracker((prev) => ({
          ...prev,
          searchParam: trimmedSearchParam,
        }));
      }
      else {
        getUnservedQueue(1, 100, trimmedSearchParam);
        setUnservedQueue((prev) => ({
          ...prev,
          searchParam: trimmedSearchParam,
        }));
      }
    }
  };

  const handleSearchIconClick = () => {
    if (props.activeTab == "Tracker") {
      getServiceTracker(1, 100, searchQuery.trim());
      setServiceTracker((prev) => ({
        ...prev,
        searchParam: searchQuery.trim(),
      }));
    }
    else {
      getUnservedQueue(1, 100, searchQuery.trim());
      setUnservedQueue((prev) => ({
        ...prev,
        searchParam: searchQuery.trim(),
      }));
    }
  };

  const handleCrossIconClick = () => {
    setSearchQuery('');
    if (props.activeTab == "Tracker") {
      getServiceTracker(1, 100, "");
      setServiceTracker((prev) => ({
        ...prev,
        searchParam: "",
      }));
    }
    else {
      getUnservedQueue(1, 100, "");
      setUnservedQueue((prev) => ({
        ...prev,
        searchParam: "",
      }));
    }
  };

  // useEffect(() => {
  //  const trimmedSearchParam = debouncedSearch.trim();
  //   setServiceTracker((prev) => ({
  //     ...prev,
  //     searchParam: trimmedSearchParam,
  //   }));
  //   if (trimmedSearchParam && trimmedSearchParam.length >= 3) {
  //     if(searchedApiQuery  == "")
  //       {
  //          setSearchedApiQuery(trimmedSearchParam);
  //       }
  //     getServiceTracker(1, 100, trimmedSearchParam);

  //   } else {
  //     updateServiceTracker("");
  //   }
  // }, [debouncedSearch]);

  useEffect(() => {
    const trimmedSearchParam = debouncedSearch.trim();
    if (props.activeTab == "Tracker") {
      setServiceTracker((prev) => ({
        ...prev,
        searchParam: trimmedSearchParam,
      }));
    }
    else {
      setUnservedQueue((prev) => ({
        ...prev,
        searchParam: trimmedSearchParam,
      }));
    }
    if (trimmedSearchParam) {
      if (searchedApiQuery == "") {
        setSearchedApiQuery(trimmedSearchParam);
      }
      if (props.activeTab == "Tracker") {
        getServiceTracker(1, 100, trimmedSearchParam);
      }
      else {
        getUnservedQueue(1, 100, trimmedSearchParam);
      }

    } else {
      updateServiceTracker("");
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (!searchQuery) {
      updateServiceTracker('');
    }
  }, [searchQuery]);

  useEffect(() => {
    setSearchQuery('');
    setServiceTracker((prev) => ({
      ...prev,
      searchParam: '',
    }));
    setUnservedQueue((prev) => ({
      ...prev,
      searchParam: '',
    }));
 }, [props.activeTab]);


  return (
    <>
      <MultiLineSearch
        value={searchQuery}
        handleInputChange={handleChange}
        handleSearchIconClick={handleSearchIconClick}
        handleCrossIconClick={handleCrossIconClick}
      />
    </>

  );
};

export default ServiceTracker_SearchBar;