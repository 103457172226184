// FileEvictionsButtons.tsx
import React, { useState } from "react";
import * as yup from "yup";
import {
   FaExclamationTriangle, FaEdit,
   FaFileExport
} from "react-icons/fa";
import Papa from "papaparse";
import Modal from "components/common/popup/PopUp";
import Button from "components/common/button/Button";
import Spinner from "components/common/spinner/Spinner";
import {
   IFileEvictionsButton
} from "interfaces/file-evictions.interface";
import { IAccountingQueueExportResource, IEvictionFilingTransactionExportResource, IExportCsv, IExportTransactionCsv, IFilingTransactionButton, IFilingTransactionExportResource } from "interfaces/filing-transaction.interface";
import { useAuth } from "context/AuthContext";

import { useFilingTransactionContext } from "../FilingTransactionContext";
import FilingTransaction_BulkEdit from "./FilingTransactionActions/FilingTransaction_BulkEdit";
import FilingTransactionService from "services/filing-transaction.service";

const classNames = (...classes: string[]) => {
   return classes.filter(Boolean).join(" ");
};

// Define the props type for FileEvictionsButton component
type FileEvictionsButtonsProps = {
   buttons: IFileEvictionsButton[];
   activeTab?: string;
};

const formatDate = (dateString: string | Date | null): string => {
   if (!dateString) return ""; // Return empty string for null or undefined dates
 
   const date = new Date(dateString);
 
   if (isNaN(date.getTime())) {
     // If it's not a valid date, return the original value (to prevent wrongly formatting strings like "AWESOME APARTMENTS 172")
     return String(dateString);
   }
 
   // Format date as MM/DD/YYYY
   const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
   const day = date.getDate().toString().padStart(2, "0");
   const year = date.getFullYear();
   return `${month}/${day}/${year}`;
 };

// Validation schema for manual create eviction
const validationSchema: yup.ObjectSchema<any> = yup.object({
   email: yup
      .string()
      .required("Email is required.")
      .test("valid-emails", "Invalid email format", (value) => {
         if (!value) return true; // Allow empty value
         const emails = value.split(",").map((email) => email.trim());
         const isValid = emails.every((email) =>
            yup.string().email().isValidSync(email)
         );
         return isValid;
      }),
});

export const FilingTransactionButtons = (props: FileEvictionsButtonsProps) => {
   // this is to get selected file evictions on the basis of checkbox
   const {
      selectedFilingTransactionId,
      setSelectedFilingTransactionId,
      setFilingTransactions,
      filingTransactions,
      filingType,
      accountingQueue,
      companyId
   } = useFilingTransactionContext();
   const { userRole } = useAuth();
   const [showSpinner, setShowSpinner] = useState<boolean>(false);
   // when no row is selected then show error message based on this varible
   const [
      showErrorMessageWhenNoRowIsSelected,
      setShowErrorMessageWhenNoRowIsSelected,
   ] = useState<boolean>(false);

   // to show import csv pop up
   const [importCsvPopUp, setImportCsvPopUp] = useState<boolean>(false);
   // const [evictionFilteredRecords, setevictionFilteredRecords] = useState<IFileEvictionsItems[]>(
   //    []
   // );
   const [pdfLink, setPdfLink] = useState<string>("");
   const [bulkEditPopUp, setBulkEditPopUp] = useState<boolean>(false);
   const [
      showPopUpWhenDownloadFileEviction,
      setShowPopUpWhenDownloadFileEviction,
   ] = useState<boolean>(false);
   const [sendToAdditionalParties, setSendToAdditionalParties] =
      useState<boolean>(false);

   /**
    * this is to remove selected tenant from the application
    * @returns show success message when user remove all tenants.
    */
   const resetSelectedRows = () => {
      // setevictionFilteredRecords([]);
      setSelectedFilingTransactionId([]);
      setFilingTransactions((prev) => {
         return {
            ...prev,
            items: prev.items.map((item) => ({
               ...item,
               isChecked: false,
            })),
         };
      });
   };

   const getDataForCsv = async () => {
      try {
         // setShowExportSpinner(true);
         const request: IExportTransactionCsv = {
            transactionIds: selectedFilingTransactionId,
            filingType: filingType
         };
         const response = await FilingTransactionService.exportFilingTransaction(request,filingTransactions.searchParam,
            filingType,
            companyId,
            filingTransactions.fromDate,
            filingTransactions.toDate,
            filingTransactions.datePaidFromDate,
            filingTransactions.datePaidToDate,
            filingTransactions.blankOption);
         return response.data;
      } catch (error) {
         throw new Error("Error fetching cases data:");
      } finally {
         // setShowExportSpinner(false);
      }
   };

   const getEvictionDataForCsv = async () => {
      try {
         // setShowExportSpinner(true);
         const request: IExportTransactionCsv = {
            transactionIds: selectedFilingTransactionId,
            filingType: filingType
         };
         const response = await FilingTransactionService.exportEvictionFilingTransaction(request,
            filingTransactions.searchParam,
            "Eviction",
            companyId,
            filingTransactions.fromDate,
            filingTransactions.toDate,
            filingTransactions.datePaidFromDate,
            filingTransactions.datePaidToDate,
            filingTransactions.blankOption
         );
         return response.data;
      } catch (error) {
         throw new Error("Error fetching eviction filing transaction data:");
      } finally {
         // setShowExportSpinner(false);
      }
   };

   const getAccountingQueueDataForCsv = async () => {
      try {
         // setShowExportSpinner(true);
         const request: IExportCsv = {
            transactionIds: selectedFilingTransactionId,
         };
         const response = await FilingTransactionService.exportAccountingQueue(request, accountingQueue.searchParam, accountingQueue.fromDatePaid,accountingQueue.toDatePaid
            , accountingQueue.type, accountingQueue.fromDatePayroll, accountingQueue.toDatePayroll
            , accountingQueue.fromDateCommission, accountingQueue.toDateCommission, accountingQueue.blankOption);
         return response.data;
      } catch (error) {
         throw new Error("Error fetching eviction filing transaction data:");
      } finally {
         // setShowExportSpinner(false);
      }
   };

   const isISODateString = (value: unknown): boolean => {
      return (
        typeof value === "string" &&
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|[+-]\d{2}:\d{2})?$/.test(value)
      );
    };
   const downloadCSV = async () => {
      try {
         // Fetch data from the API
         if (filingType === 'Eviction' && props.activeTab != "Accounting Queue") {
            const response: IEvictionFilingTransactionExportResource[] = await getEvictionDataForCsv();
            // Ensure that response is not null or undefined
            if (response) {
               // Convert the single object to an array
               const dataArray: IEvictionFilingTransactionExportResource[] = response as IEvictionFilingTransactionExportResource[];

               // Convert objects to strings using JSON.stringify
               const stringifiedDataArray = dataArray.map((item) => {
                  // Ensure that each item is of type Record<string, string>
                  const typedItem = item as unknown as Record<string, string>;

                  // Convert each object property to a string
                  return Object.keys(typedItem).reduce((acc, key) => {
                     // const value = typedItem[key];
                     const typedKey = key as keyof IEvictionFilingTransactionExportResource;
                  const value = item[typedKey];
                     // const stringValue =
                     //    typeof value === "object" ? JSON.stringify(value) : String(value);
                     // acc[key] = stringValue;
                     // return acc;
                     if (isISODateString(value) || value instanceof Date) {
                        acc[key] = formatDate(value as string); // Format date
                      } else if (typeof value === "object" && value !== null) {
                        // If the value is an object (but not null), stringify it
                        acc[key] = JSON.stringify(value);
                      } else {
                        // Otherwise, just convert it to a string
                        acc[key] = String(value);
                      }
                      return acc;
                  }, {} as Record<string, string>);
               });

               // Convert the data array to CSV format
               const csv = Papa.unparse(stringifiedDataArray as object[]);

               // Create a Blob with the CSV data
               const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

               // Create a temporary link element and trigger the download
               const link = document.createElement("a");
               const url = URL.createObjectURL(blob);
               link.href = url;
               link.setAttribute("download", "EvictionFilingTransaction.csv");
               document.body.appendChild(link);
               link.click();

               // Clean up by removing the link and revoking the URL
               document.body.removeChild(link);
               URL.revokeObjectURL(url);
            }
         }
         else if(props.activeTab != "Accounting Queue") {
            const response: IFilingTransactionExportResource[] = await getDataForCsv();

            // Ensure that response is not null or undefined
            if (response) {
               // Convert the single object to an array
               const dataArray: IFilingTransactionExportResource[] = response as IFilingTransactionExportResource[];

               // Define the keys that need to be prefixed with the tab name
               const keysToPrefix = [
                  "filedDate",
                  "courtTransAmt",
                  "paymentAmount",
                  "payPalFee",
                  "payPalManual",
                  "invoiceDate",
                  "invoiceNo",
                  "datePaid",
                  "checkNo",
               ];

               // Function to convert the filing type and key into camel case
               const toCamelCase = (filingType: string, key: string) => {
                  const lowerFilingType = filingType.toLowerCase();
                  return lowerFilingType + key.charAt(0).toUpperCase() + key.slice(1);
               };

               // Convert objects to strings using JSON.stringify and modify keys based on tabName
               // const stringifiedDataArray = dataArray.map((item) => {
               //    const typedItem = item as unknown as Record<string, string>;

               //    // return Object.keys(typedItem).reduce((acc, key) => {
               //    //    const value = typedItem[key];
               //    //    const stringValue =
               //    //       typeof value === "object" ? JSON.stringify(value) : String(value);

               //    //    // Only prefix keys that are in the keysToPrefix array
               //    //    if (keysToPrefix.includes(key)) {
               //    //       acc[toCamelCase(filingType, key)] = stringValue;
               //    //    } else {
               //    //       acc[key] = stringValue;
               //    //    }
               //    //    return acc;
               //    // }, {} as Record<string, string>);
               //    return Object.keys(typedItem).reduce((acc, key) => {
               //       // const value = typedItem[key];
               //       const typedKey = key as keyof IFilingTransactionExportResource;
               //    const value = item[typedKey];
               //       const stringValue = typeof value === "object" && value !== null 
               //         ? JSON.stringify(value) 
               //         : String(value);
                   
               //       // Check if the value is an ISO date string or Date object
               //       if (isISODateString(value) || value instanceof Date) {
               //         acc[key] = formatDate(value as string); // Format date
               //       } else if (typeof value === "object" && value !== null) {
               //         acc[key] = JSON.stringify(value); // Stringify object
               //       } else {
               //         // Check if the key should be prefixed
               //         if (keysToPrefix.includes(key)) {
               //           acc[toCamelCase(filingType, key)] = stringValue;
               //         } else {
               //           acc[key] = stringValue;
               //         }
               //       }
                   
               //       return acc;
               //     }, {} as Record<string, string>);
               // });

               const stringifiedDataArray = dataArray.map((item) => {
                  const typedItem = item as unknown as Record<string, string>;
                
                  return Object.keys(typedItem).reduce((acc, key) => {
                    const typedKey = key as keyof IFilingTransactionExportResource;
                    const value = item[typedKey];
                    let stringValue: string;
                
                    // Check if the value is an ISO date string or Date object
                    if (isISODateString(value) || value instanceof Date) {
                      stringValue = formatDate(value as string); // Format date
                    } else if (typeof value === "object" && value !== null) {
                      stringValue = JSON.stringify(value); // Stringify object
                    } else {
                      stringValue = String(value);
                    }
                
                    // Rename 'created' based on filingType
                    if (key.toLowerCase() === "created") {
                      if (filingType === "Dismissal") {
                        acc["dismissalApplicantDate"] = stringValue;
                      } else if (filingType === "Writ") {
                        acc["writApplicantDate"] = stringValue;
                      } else if (filingType === "Amendment") {
                        acc["amendmentApplicantDate"] = stringValue;
                      } else {
                        acc[key] = stringValue; // Keep original key if no match
                      }
                    } else {
                      // Check if the key should be prefixed
                      if (keysToPrefix.includes(key)) {
                        acc[toCamelCase(filingType, key)] = stringValue;
                      } else {
                        acc[key] = stringValue;
                      }
                    }
                
                    return acc;
                  }, {} as Record<string, string>);
                });

               // Convert the data array to CSV format
               const csv = Papa.unparse(stringifiedDataArray as object[]);

               // Create a Blob with the CSV data
               const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

               // Create a temporary link element and trigger the download
               const link = document.createElement("a");
               const url = URL.createObjectURL(blob);
               link.href = url;
               link.setAttribute("download", `${filingType}FilingTransaction.csv`);
               document.body.appendChild(link);
               link.click();

               // Clean up by removing the link and revoking the URL
               document.body.removeChild(link);
               URL.revokeObjectURL(url);
            }
         }
         else
         {
            const response: IAccountingQueueExportResource[] = await getAccountingQueueDataForCsv();
            // Ensure that response is not null or undefined
            if (response) {
               // Convert the single object to an array
               const dataArray: IAccountingQueueExportResource[] = response as IAccountingQueueExportResource[];

               // Convert objects to strings using JSON.stringify
               const stringifiedDataArray = dataArray.map((item) => {
                  // Ensure that each item is of type Record<string, string>
                  const typedItem = item as unknown as Record<string, string>;

                  // Convert each object property to a string
                  return Object.keys(typedItem).reduce((acc, key) => {
                     // const value = typedItem[key];
                     const typedKey = key as keyof IAccountingQueueExportResource;
                  const value = item[typedKey];
                     // const stringValue =
                     //    typeof value === "object" ? JSON.stringify(value) : String(value);
                     // acc[key] = stringValue;
                     // return acc;
                     if (isISODateString(value) || value instanceof Date) {
                        acc[key] = formatDate(value as string); // Format date
                      } else if (typeof value === "object" && value !== null) {
                        // If the value is an object (but not null), stringify it
                        acc[key] = JSON.stringify(value);
                      } else {
                        // Otherwise, just convert it to a string
                        acc[key] = String(value);
                      }
                      return acc;
                  }, {} as Record<string, string>);
               });

               // Convert the data array to CSV format
               const csv = Papa.unparse(stringifiedDataArray as object[]);

               // Create a Blob with the CSV data
               const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

               // Create a temporary link element and trigger the download
               const link = document.createElement("a");
               const url = URL.createObjectURL(blob);
               link.href = url;
               link.setAttribute("download", "AccountingQueue.csv");
               document.body.appendChild(link);
               link.click();

               // Clean up by removing the link and revoking the URL
               document.body.removeChild(link);
               URL.revokeObjectURL(url);
            }
         }
      } catch (error) {
         console.error("Error fetching or exporting data:", error);
         // Handle error (e.g., display an error message)
      }
   };

   /** handle click of all buttons  */
   const handleClick = (button: IFilingTransactionButton) => {
      // Switch based on the button type or any other property that uniquely identifies the button
      switch (button.title) {
         case "Edit":
            if (selectedFilingTransactionId.length === 0) {
               setShowErrorMessageWhenNoRowIsSelected(true);
               return;
            } else {
               setShowErrorMessageWhenNoRowIsSelected(false);
               setBulkEditPopUp(true);
            }
            break;
         case "Export CSV":
            // if (selectedFilingTransactionId.length === 0) {
            //    setShowErrorMessageWhenNoRowIsSelected(true);
            //    return;
            // } else {
            //    setShowErrorMessageWhenNoRowIsSelected(false);
               downloadCSV();
            // }
            break;
         // Add more cases for other button types
         default:
            // Handle default case or unknown button types
            console.log(`Unknown button type: ${button.icon}`);
      }
   };


   /**
    *
    * @param formValues get those values from the email
    */
   //    const handleSignIn = async (formValues: any) => {

   //       try {
   //          setShowSpinner(true);
   //          // Download all file evictions
   //          if (sendToAdditionalParties) {
   //             let request: ISendFileEvictionEmail = {
   //                combinedPdfUrl: pdfLink,
   //                dispoIds: selectedFileEvictionId,
   //                userEmails: formValues.email.split(","),
   //             };
   //             const apiResponse = await FileEvictionService.sendFileEvictionEmail(
   //                request
   //             );
   //          }
   //          setShowPopUpWhenDownloadFileEviction(false);
   //          await downloadPDF(pdfLink);
   //          setShowSpinner(false);
   //          getFileEvictions(1, 100);
   //          setSelectedFileEvictionId([]);
   //       } catch (error) { }
   //    };


   return (
      <>
         {/* Map through the buttons array to generate individual buttons */}
         {props.buttons.map((item: IFilingTransactionButton, index: number) => {
            let iconComponent;
            // Switch statement to determine the icon based on the provided icon type
            switch (item.icon) {
               case "FaEdit":
                  iconComponent = (
                     <FaEdit className="fa-solid fa-plus  mr-1 text-xs" />
                  );
                  break;
               case "FaFileExport":
                  iconComponent = (
                     <FaFileExport className="fa-solid fa-plus mr-1 text-xs" />
                  );
                  break;
               default:
                  // Provide a default case or handle unknown icon types
                  iconComponent = <></>;
            }
            // if (
            //    item.title === "Review & Sign" &&
            //    userRole.includes(UserRole.NonSigner)
            // ) {
            //    return null; // Hide the button for non-signers
            // }

            // Conditionally check userRole and skip rendering buttons accordingly
            // if (
            //    (item.title === "New" ||
            //       item.title === "Import Data" ||
            //       item.title === "Verify Address" ||
            //       item.title === "Delete" ||
            //       item.title === "Review & Sign" ||
            //    item.title==="Edit") &&
            //    userRole.includes(UserRole.Viewer)
            // ) {
            //    return null; // Hide the button for viewers
            // }

            return (
               <Button
                  title={item.title}
                  classes={item.classes}
                  type={"button"}
                  isRounded={false}
                  icon={iconComponent}
                  key={index}
                  handleClick={() => handleClick(item)}
                  disabled={item.title === "Verify Address" ? true : false}
               ></Button>
            );
         })}

         {showSpinner && <Spinner></Spinner>}
         {/* This is to show error message when no row is selected from grid */}
         {showErrorMessageWhenNoRowIsSelected && (
            <>
               <Modal
                  showModal={showErrorMessageWhenNoRowIsSelected}
                  onClose={() => {
                     setShowErrorMessageWhenNoRowIsSelected(false);
                  }}
                  width="max-w-md"
               >
                  <div className="bg-white px-3.5 pb-3.5 pt-4 sm:p-5 sm:pb-3.5">
                     <div className="text-center py-8">
                        <div className="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full bg-red-100 mx-auto">
                           <FaExclamationTriangle className="h-5 w-5 text-red-600" />
                        </div>
                        <div className="mt-2.5 text-center ">
                           <p className="text-xs text-gray-500 text-center font-medium text-gray-600 text-md">
                              Please select at least 1 record
                           </p>
                        </div>
                     </div>
                  </div>
               </Modal>
            </>
         )}
         {bulkEditPopUp && (
            <>
               <FilingTransaction_BulkEdit
                  showFileEvictionPopup={bulkEditPopUp}
                  handleClose={() => {
                     setBulkEditPopUp(false);
                     //    resetSelectedRows();
                  }}
                  tabName={props.activeTab}
               />
            </>
         )}
      </>
   );
};
