import React, { useState } from "react";
import { HttpStatusCode } from "axios";
import { toast } from "react-toastify";
import { FaEdit, FaEnvelope, FaExclamationTriangle, FaInfoCircle, FaRedo } from "react-icons/fa";
import { IEmailQueueButton } from "interfaces/email-queue.interface";
import Button from "components/common/button/Button";
import Modal from "components/common/popup/PopUp";
import { useEmailQueueContext } from "../EmailQueueContext";
import EmailQueueService from "services/email-queue.service";
import EmailQueueBulkEdit from "./EmailQueueActions/EmailQueue_BulkEdit";
import ServerEmailLogBulkEdit from "./EmailQueueActions/ServerEmailLog_BulkEdit";
import MailManagementBulkEdit from "./EmailQueueActions/MailManagement_BulkEdit";

type EmailQueueButtonProps = {
   buttons: IEmailQueueButton[];
   activeTab: string;
};

const EmailQueueButtons = (props: EmailQueueButtonProps) => {
   const [sendEmailConfirmation, setSendEmailConfirmation] = useState<boolean>(false);
   const [showInfo, setShowInfo] = useState<boolean>(false);
   const [bulkEditPopUp, setBulkEditPopUp] = useState<boolean>(false);
   const [bulkEditServerLogPopUp, setBulkEditServerLogPopUp] = useState<boolean>(false);
   const [bulkEditMailManagementPopUp, setBulkEditMailManagementPopUp] = useState<boolean>(false);
   const [errorMessage, setErrorMsg] = useState<string>("");
   const [
      showErrorMessageWhenNoRowIsSelected,
      setShowErrorMessageWhenNoRowIsSelectedState,
   ] = useState<boolean>(false);
   const setShowErrorMessageWhenNoRowIsSelected = (show: boolean, customMessage?: string) => {
      setErrorMsg(customMessage || "Please select at least 1 record.");
      setShowErrorMessageWhenNoRowIsSelectedState(show);
   };
   const handleModalClose = () => {
      setSendEmailConfirmation(false);
   };
   const {
      selectedEmailQueueIds,
      selectedServerLogIds,
      getEmailQueues,
      setShowSpinner,
      setEmailQueues,
      setServerEmailLogs,
      allCounties
   } = useEmailQueueContext();

   const handleSendEmail = async () => {
      setSendEmailConfirmation(false);
      setShowSpinner(true);
      const response = await EmailQueueService.sendEmailToServer(selectedEmailQueueIds);
      if (response.status === HttpStatusCode.Ok) {

         if (response.data.isSuccess)
            toast.success("Email sent successfully to the server and filer.");
         else {
            setErrorMsg(response.data.message);
            setShowInfo(true);
         }
         // toast.warning(response.data.message);

         getEmailQueues(1, 100, "");
      }
      setShowSpinner(false);
   };

   const resetSelectedRows = () => {
      setEmailQueues((prev) => {
         return {
            ...prev,
            items: prev.items.map((item) => ({
               ...item,
               isChecked: false,
            })),
         };
      });
      setServerEmailLogs((prev) => {
         return {
            ...prev,
            items: prev.items.map((item) => ({
               ...item,
               isChecked: false,
            })),
         };
      });
   };

   const handleClick = (button: IEmailQueueButton) => {
      // Switch based on the button type or any other property that uniquely identifies the button
      switch (button.title) {
         case "Send Email":
            console.log(button.title, 'clicked')
            setSendEmailConfirmation(true);
            break;
         case "Manual Crawl":
            handleManualCrawling();
            break;
         case "Edit":
            if (props.activeTab === "Process Server Eviction") {
               // Check if selectedServerLogIds is empty for Process Server Eviction tab
               if (selectedServerLogIds.length === 0) {
                  setShowErrorMessageWhenNoRowIsSelected(
                     true,
                     "Please select at least one record."
                  );
               } else {
                  setShowErrorMessageWhenNoRowIsSelected(false);
                  setBulkEditServerLogPopUp(true);
               }
            } else if (props.activeTab === "Mail Management") {
               // Check if selectedEmailQueueIds is empty for Mail Management tab
               if (selectedEmailQueueIds.length === 0) {
                  setShowErrorMessageWhenNoRowIsSelected(
                     true,
                     "Please select at least one record."
                  );
               } else {
                  setShowErrorMessageWhenNoRowIsSelected(false);
                  setBulkEditMailManagementPopUp(true);
               }
            } else {
               // General case: check selectedEmailQueueIds for other tabs
               if (selectedEmailQueueIds.length === 0) {
                  setShowErrorMessageWhenNoRowIsSelected(
                     true,
                     "Please select at least one record."
                  );
               } else {
                  setShowErrorMessageWhenNoRowIsSelected(false);
                  setBulkEditPopUp(true);
               }
            }
            break;
         default:
            // Handle default case or unknown button types
            console.log(`Unknown button type: ${button.icon}`);
      }
   };

   const handleManualCrawling = async () => {
      const response = await EmailQueueService.runManualCrawling();
      if (response.status === HttpStatusCode.Ok) {
         // toast.success("Crawling Service started Successfully.");
         toast.success(response.data.message);
      } else {
         toast.error("Failed to Crawl Data.");
      }
   };

   return (
      <>
         {props.buttons.map((item: IEmailQueueButton, index: number) => {
            let iconComponent;
            switch (item.icon) {
               case "FaFileExcel":
                  iconComponent = (
                     <FaEnvelope className="fa-solid fa-plus mr-0.5 text-xs" />
                  );
                  break;
               case "FaEdit":
                  iconComponent = (
                     <FaEdit className="fa-solid fa-plus  mr-0.5 text-xs " />
                  );
                  break;
               case "FaRedo":
                  iconComponent = (
                     <FaRedo className="fa-solid fa-plus  mr-1 text-xs" />
                  );
                  break;
               default:
                  // Provide a default case or handle unknown icon types
                  iconComponent = <FaEnvelope className="text-[13px] mr-0.5" />;
            };

            return (
               <Button
                  title={item.title}
                  classes={(props.activeTab == "Process Server Eviction" || props.activeTab == "Mail Management") && (item.title == "Send Email" || item.title =="Manual Crawl") ? "hidden" : item.classes}
                  type={"button"}
                  isRounded={false}
                  icon={iconComponent}
                  key={index}
                  handleClick={() => handleClick(item)}
               ></Button>
            );
         })}

         {(
            <Modal
               showModal={showInfo}
               onClose={() => {
                  setShowInfo(false);
               }}
               width="max-w-md"
            >
               <div className="bg-white px-3.5 pb-3.5 pt-4 sm:p-5 rounded-md">
                  <div className="text-center py-8">
                     <div className="mx-auto flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-blue-100  sm:h-16 sm:w-16 mx-auto">
                        <FaInfoCircle className="h-6 w-6 text-blue-600" />
                     </div>
                     <div className="mt-2.5 text-center ">
                        <p className="text-xs text-gray-500 text-center font-medium text-gray-600 text-md">
                           {errorMessage}
                        </p>
                     </div>
                  </div>
               </div>
            </Modal>
         )}

         {sendEmailConfirmation && (
            <div>
               <Modal
                  showModal={sendEmailConfirmation}
                  onClose={handleModalClose}
                  width="max-w-md"
               >
                  <div id="fullPageContent">
                     <div className="bg-white px-3.5 pb-3.5 pt-4 sm:p-5 sm:pb-3.5">
                        <div className="text-center pr-4 sm:text-left">
                           <h3
                              className="text-sm font-semibold leading-5 text-gray-900"
                              id="modal-title"
                           >
                              Are you sure you want to send email to the process server(s)?
                           </h3>
                        </div>
                     </div>
                     <div className="flex justify-end m-2">
                        <Button
                           type="button"
                           isRounded={false}
                           title="No"
                           handleClick={handleModalClose}
                           classes="text-[11px] md:text-xs bg-white	inline-flex justify-center items-center rounded-md text-md font-semibold py-2 md:py-2.5 px-4 md:px-5 mr-1.5 ring-1 ring-slate-900/10 hover:bg-white/25 hover:ring-slate-900/15 shadow-lg "
                        ></Button>
                        <Button
                           handleClick={handleSendEmail}
                           title="Yes"
                           isRounded={false}
                           type={"button"}
                           classes="text-[11px] md:text-xs bg-[#2472db] inline-flex justify-center items-center rounded-md font-semibold py-2 md:py-2.5 px-4 md:px-5 text-white"
                        ></Button>
                     </div>
                  </div>
               </Modal>
            </div>
         )}

         {bulkEditPopUp && (
            <>
               <EmailQueueBulkEdit
                  showEmailQueuePopup={bulkEditPopUp}
                  handleClose={() => {
                     setBulkEditPopUp(false);
                     resetSelectedRows();
                  }}
                  handleCloseConfirm={() => {
                     setBulkEditPopUp(false);
                  }}
                  counties={allCounties.map(c => c.countyName.toLowerCase())}
               />
            </>
         )}

         {bulkEditServerLogPopUp && (
            <>
               <ServerEmailLogBulkEdit
                  showPopup={bulkEditServerLogPopUp}
                  handleClose={() => {
                     setBulkEditServerLogPopUp(false);
                     resetSelectedRows();
                  }}
                  handleCloseConfirm={() => {
                     setBulkEditServerLogPopUp(false);
                  }}
               />
            </>
         )}

         {bulkEditMailManagementPopUp && (
            <>
               <MailManagementBulkEdit
                  showPopup={bulkEditMailManagementPopUp}
                  handleClose={() => {
                     setBulkEditMailManagementPopUp(false);
                     resetSelectedRows();
                  }}
                  handleCloseConfirm={() => {
                     setBulkEditMailManagementPopUp(false);
                  }}
               />
            </>
         )}

         {showErrorMessageWhenNoRowIsSelected && (
            <Modal
               showModal={showErrorMessageWhenNoRowIsSelected}
               onClose={() => {
                  setShowErrorMessageWhenNoRowIsSelected(false);
                  resetSelectedRows();
               }}
               width="max-w-md"
            >
               <div className="bg-white px-3.5 pb-3.5 pt-4 sm:p-5 sm:pb-3.5">
                  <div className="text-center py-8">
                     <div className="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full bg-red-100 mx-auto">
                        <FaExclamationTriangle className="h-5 w-5 text-red-600" />
                     </div>
                     <div className="mt-2.5 text-center ">
                        <p className="text-xs text-gray-500 text-center font-medium text-gray-600 text-md">
                           {errorMessage}
                        </p>
                     </div>
                  </div>
               </div>
            </Modal>
         )}
      </>
   );
};

export default EmailQueueButtons;