import React from 'react';
import { enumToDismissalReasonString, formattedDate } from 'utils/helper';
import { IRootCaseInfo } from 'interfaces/common.interface';

type BasicInfoInfoCardProps = {
   caseInfo: IRootCaseInfo;
};

const BasicInfoInfoCard: React.FC<BasicInfoInfoCardProps> = ({ caseInfo }: BasicInfoInfoCardProps) => {
   // Destructure fields and efile from caseInfo once it's available
   const { fields, efile, unsignedWrit, unsignedDismissal } = caseInfo || {};

   return (
      <div className='p-3.5 bg-white border border-gray-200 rounded shadow relative'>
         <h2 className='text-sm font-medium absolute bg-white top-[-8px] left-2.5 px-1.5 !leading-4'>Case Information</h2>
         <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1.5'>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>CaseNumber </strong> {efile?.caseNumber}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>EvictionEnvelopeId </strong> {efile?.envelopeId}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>Status </strong> {caseInfo?.status}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>County </strong> {efile?.county}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>EvictionDateFiled </strong> {fields?.signing?.affiantSignDate ? formattedDate(fields?.signing?.affiantSignDate) : ""}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>EvictionLastDayToAnswer </strong> {fields?.eviction?.lastDayToAnswer ? formattedDate(fields?.eviction?.lastDayToAnswer) : ""}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>ServiceDateHistory </strong> {caseInfo?.serviceDateHistory ?? ""}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>EvictionServiceDate </strong> {fields?.eviction?.serviceDate ? formattedDate(fields?.eviction?.serviceDate) : ""}
            </div>     
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>EvictionServiceMethod </strong> {fields?.eviction?.serviceMethod}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>EvictionReason </strong> {fields?.filing?.reason}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>DismissalReason </strong> {enumToDismissalReasonString(unsignedDismissal?.reason)}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>EvictionTotalRentDue </strong> {fields?.eviction?.totalRentDue}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>EvictionOtherFees </strong> {fields?.filing.otherFee}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>EvictionAffiantIs </strong> {fields?.signing?.affiantIs}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>AnswerDate </strong> N/A
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>CourtDate </strong> {fields?.eviction?.courtDate ? formattedDate(fields?.eviction?.courtDate) : ""}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>WritFiledDate </strong> {unsignedWrit?.dateFiled ? formattedDate(unsignedWrit?.dateFiled.toString()) : ""}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>DismissalFiledDate </strong> {unsignedDismissal?.dateFiled ? formattedDate(unsignedDismissal?.dateFiled.toString()) : ""}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>EvictionAffiantSignature </strong> {fields?.signing?.affiantSignature}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>EvictionCount </strong> 0
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>WritApplicantDate </strong> {unsignedWrit?.dateFiled ? formattedDate(unsignedWrit?.dateFiled.toString()) : ""}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>CaseCreatedDate </strong> {fields?.timeStamp ? formattedDate(fields?.timeStamp?.toString()) : ""}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>NoticeCount </strong> 0
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>MonthlyRent </strong> ${fields?.filing?.monthlyRent}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>AllMonths </strong> {fields?.filing?.allMonths}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>Expedited </strong> {fields?.serviceRequest?.expedited?.length ? "Expedited" : ""}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>StateCourt </strong> {efile?.courtName}
            </div>
            <div className='flex flex-col text-xs leading-[13px] gap-1 text-[#212529]'>
               <strong className='text-[#7a7a7a] capitalize font-normal text-[10px]'>EvictionAutomation </strong> {caseInfo && caseInfo.crmInfo && caseInfo.crmInfo.crmName && caseInfo.crmInfo.crmName.length ? "Yes" : "No"}
            </div>         
         </div>
      </div>
   );
};

export default BasicInfoInfoCard;
