import React from "react";
import DownloadCsv from "components/common/downloadCsv/DownloadCsv";
import ServiceTrackerService from "services/service-tracker.service";
import { useServiceTrackerContext } from "../../ServiceTrackerContext";

type ServiceTracker_ButtonProps = {
  activeTab?: string;
};
export const ServiceTrackerButtons = (props: ServiceTracker_ButtonProps) => {
const {selectedServiceTrackerId, serviceTracker, unservedQueue} = useServiceTrackerContext();
  const getDataForCsv = async () => {    
    try {
      if(props.activeTab == "Tracker")
      {
      const response = await ServiceTrackerService.exportServiceTracker(selectedServiceTrackerId,serviceTracker.searchParam);
      return response.data;
      }
      else
      {
        const response = await ServiceTrackerService.exportUnservedQueue(selectedServiceTrackerId,unservedQueue.searchParam);
      return response.data;
      }
    } catch (error) {
      throw new Error("Error fetching cases data:");
    }
  };
    return (
       <>
        <DownloadCsv fetchData={getDataForCsv} fileName={props.activeTab == "Tracker" ? "ServiceTracker.csv" : "UnservedQueue.csv"} />
       </>
       
    );
}

export default ServiceTrackerButtons;