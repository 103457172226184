import React, { useEffect, useState } from "react";
import { ChangeEvent } from "react";
import { useDebounce } from "hooks/useDebounce";
import MultiLineSearch from "components/common/multiLineSearch/MultiLineSearch";
import { useAccountingContext } from "./AccountingContext";

type Accounting__SearchBarProps = {
   activeTab?: string;
};

const Accounting_SearchBar = (props: Accounting__SearchBarProps) => {
   const {
      wallet,
      getAllWallet,
      setAllWallet,
      invoices,
      getInvoices,
      setInvoices,
      transaction,
      getAllTransaction,
      setAllTransaction,
      customerDetails,
      getCustomerDetails,
      setCustomerDetails
   } = useAccountingContext();
   const [searchQuery, setSearchQuery] = useState<string>('');
   const [searchedApiQuery, setSearchedApiQuery] = useState<string>('');

   const debouncedSearch = useDebounce(searchQuery, 500, 3);

   const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setSearchQuery(event.target.value);
   };

   const updateAllRecords = () => {
      const trimmedSearchParam = searchQuery.trim();
      if (trimmedSearchParam) {
         if (props.activeTab === "transaction") {
            // getAllTransaction(1, 100, trimmedSearchParam);
            getAllTransaction(1, 100, trimmedSearchParam,transaction.fromDate,transaction.toDate,transaction.companyId?.toString(),transaction.isC2CCard);
            setAllTransaction((prevAllTrans) => ({ ...prevAllTrans, searchParam: trimmedSearchParam }));
         } else if (props.activeTab === "wallets") {
            getAllWallet(1, 100, trimmedSearchParam);
            setAllWallet((prevAllWallet) => ({ ...prevAllWallet, searchParam: trimmedSearchParam }));
         } else if (props.activeTab === "customers") {
            getCustomerDetails(1, 100, trimmedSearchParam);
            setCustomerDetails((prevAllCustomers) => ({ ...prevAllCustomers, searchParam: trimmedSearchParam }));
         } else if (props.activeTab === "invoices") {
            getInvoices(1, 100, trimmedSearchParam);
            setInvoices((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
         }
      }

   };

   const handleCrossClick = () => {
      setSearchQuery('');
      if (props.activeTab === "transaction") {
         getAllTransaction(1, 100, '',transaction.fromDate,transaction.toDate,transaction.companyId?.toString(),transaction.isC2CCard);
         setAllTransaction((prevAllTrans) => ({ ...prevAllTrans, searchParam: '' }));
      }
      else if (props.activeTab === "wallets") {
         getAllWallet(1, 100, '');
         setAllWallet((prevAllWallet) => ({ ...prevAllWallet, searchParam: '' }));
      }
      else if (props.activeTab === "customers") {
         getCustomerDetails(1, 100, '');
         setCustomerDetails((prevAllCustomers) => ({ ...prevAllCustomers, searchParam: '' }));
      } else if (props.activeTab === "invoices") {
         getInvoices(1, 100, '');
         setInvoices((prev) => ({ ...prev, searchParam: '' }));
      }
   };

   const handleSearchIconClick = () => {
      const trimmedSearchParam = searchQuery.trim();
      if (props.activeTab === "transaction") {
         getAllTransaction(1, 100, trimmedSearchParam,transaction.fromDate,transaction.toDate,transaction.companyId?.toString(),transaction.isC2CCard);
         setAllTransaction((prevAllTrans) => ({ ...prevAllTrans, searchParam: trimmedSearchParam }));
      }
      else if (props.activeTab === "wallets") {
         getAllWallet(1, 100, trimmedSearchParam);
         setAllWallet((prevAllWallet) => ({ ...prevAllWallet, searchParam: trimmedSearchParam }));
      }
      else if (props.activeTab === "customers") {
         getCustomerDetails(1, 100, trimmedSearchParam);
         setCustomerDetails((prevAllCustomers) => ({ ...prevAllCustomers, searchParam: trimmedSearchParam }));
      } else if (props.activeTab === "invoices") {
         getInvoices(1, 100, trimmedSearchParam);
         setInvoices((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
      }
   };
   // useEffect(() => {
   //    const trimmedSearchParam = searchQuery.trim();
   //    if (debouncedSearch.trim()) {
   //       if (trimmedSearchParam && trimmedSearchParam.length >= 3) {
   //          if(searchedApiQuery  == "")
   //             {
   //                setSearchedApiQuery(trimmedSearchParam);
   //             }
   //          updateAllRecords();
   //       }
   //    }
   //    else if(trimmedSearchParam.length < searchedApiQuery.length)
   //       {
   //          setSearchedApiQuery(trimmedSearchParam);
   //          if (props.activeTab === "transaction") {
   //             getAllTransaction(1, 100, trimmedSearchParam);
   //             setAllTransaction((prevAllTrans) => ({ ...prevAllTrans, searchParam: trimmedSearchParam }));
   //          } else if (props.activeTab === "wallets") {
   //             getAllWallet(1, 100, trimmedSearchParam);
   //             setAllWallet((prevAllWallet) => ({ ...prevAllWallet, searchParam: trimmedSearchParam }));
   //          } else if (props.activeTab === "customers") {
   //             getCustomerDetails(1, 100, trimmedSearchParam);
   //             setCustomerDetails((prevAllCustomers) => ({ ...prevAllCustomers, searchParam: trimmedSearchParam }));
   //          } else if (props.activeTab === "invoices") {
   //             getInvoices(1, 100, trimmedSearchParam);
   //             setInvoices((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
   //          }
   //       } 
   //    else {
   //       updateAllRecords();
   //    }
   // }, [debouncedSearch]);

   useEffect(() => {
      const trimmedSearchParam = searchQuery.trim();
      if (debouncedSearch.trim()) {
         if (trimmedSearchParam) {
            if(searchedApiQuery  == "")
               {
                  setSearchedApiQuery(trimmedSearchParam);
               }
            updateAllRecords();
         }
      }
      else if(trimmedSearchParam.length < searchedApiQuery.length)
         {
            setSearchedApiQuery(trimmedSearchParam);
            if (props.activeTab === "transaction") {
               getAllTransaction(1, 100, trimmedSearchParam,transaction.fromDate,transaction.toDate,transaction.companyId?.toString(),transaction.isC2CCard);
               setAllTransaction((prevAllTrans) => ({ ...prevAllTrans, searchParam: trimmedSearchParam }));
            } else if (props.activeTab === "wallets") {
               getAllWallet(1, 100, trimmedSearchParam);
               setAllWallet((prevAllWallet) => ({ ...prevAllWallet, searchParam: trimmedSearchParam }));
            } else if (props.activeTab === "customers") {
               getCustomerDetails(1, 100, trimmedSearchParam);
               setCustomerDetails((prevAllCustomers) => ({ ...prevAllCustomers, searchParam: trimmedSearchParam }));
            } else if (props.activeTab === "invoices") {
               getInvoices(1, 100, trimmedSearchParam);
               setInvoices((prev) => ({ ...prev, searchParam: trimmedSearchParam }));
            }
         } 
      else {
         updateAllRecords();
      }
   }, [debouncedSearch]);

   useEffect(() => {
      setAllWallet((prevAllWallet) => ({ ...prevAllWallet, searchParam: '' }));
      setAllTransaction((preAllTrans => ({ ...preAllTrans, searchParam: '' })))
      setCustomerDetails((preAllCustomers => ({ ...preAllCustomers, searchParam: '' })))
      setInvoices((prev) => ({ ...prev, searchParam: '' }));
      if (props.activeTab === "transaction") {
         setSearchQuery(transaction.searchParam ?? "");
      } else if (props.activeTab === "wallets") {
         setSearchQuery(wallet.searchParam ?? "");
      } else if (props.activeTab === "customers") {
         setSearchQuery(customerDetails.searchParam ?? "");
      } else if (props.activeTab === "invoices") {
         setSearchQuery(invoices.searchParam ?? "");
      }

   }, []);


   return (
      <MultiLineSearch
         value={searchQuery}
         handleInputChange={handleChange}
         handleSearchIconClick={handleSearchIconClick}
         handleCrossIconClick={handleCrossClick}
      />
   );
};

export default Accounting_SearchBar;
