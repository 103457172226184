//WritsOfPossessionContext.tsx
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { IWritsOfPossession, IWritsOfPossessionItems } from "interfaces/writs-of-possession.interface";
import WritsOfPossessionService from "services/writs-of-possesson.service";
import { HttpStatusCode, UserRole } from "utils/enum";
import { useAuth } from "context/AuthContext";
  
  // Define the shape of the context data
  type WritsOfPossessionContextType = {
    showSpinner: boolean;
    setShowSpinner: Dispatch<SetStateAction<boolean>>;
    unsignedWrits: IWritsOfPossession; // The type of unsignedWrits data
    signedWrits: IWritsOfPossession; // The type of SignedWrits data
    setAllUnsignedWrits: Dispatch<SetStateAction<IWritsOfPossession>>; // A function to update unsignedWrits data
    setAllSignedWrits: Dispatch<SetStateAction<IWritsOfPossession>>; // A function to update signedWrits data
    writsOfPossession: IWritsOfPossession; // The type of Writs Of Possession data
   setAllWritsOfPossession: Dispatch<SetStateAction<IWritsOfPossession>>; // A function to update writs of possession
    getAllWritsOfPossession: (
      currentPage: number,
      pageSize: number,
      isSigned:boolean,
      search?: string
    ) => void;
    selectedWritsOfPossessionId: string[];
    setSelectedWritsOfPossessionId: Dispatch<SetStateAction<string[]>>;
    selectedUnSignedWritsId: string[];
    setSelectedUnSignedWritsId: Dispatch<SetStateAction<string[]>>;
    selectedSignedWritsId: string[];
    setSelectedSignedWritsId: Dispatch<SetStateAction<string[]>>;
    unsignedWritsCount: number;
    filteredRecords: IWritsOfPossessionItems[],
    setFilteredRecords: Dispatch<SetStateAction<IWritsOfPossessionItems[]>>;
    selectedFilteredWritOfPossessionId:string[];
    setSelectedFilteredWritOfPossessionId:Dispatch<SetStateAction<string[]>>;
    bulkRecords: IWritsOfPossessionItems[];
    setBulkRecords: Dispatch<SetStateAction<IWritsOfPossessionItems[]>>;
  };
  
  // Create a context with an initial value
  const initialWritsOfPossessionContextValue: WritsOfPossessionContextType = {
    writsOfPossession: {
      items: [
        {
          isChecked: false,
          id: "",
          caseNo: "",
          documents: "",
          propertyName: "",
          county: "",
          firstName: "",
          lastName: "",
          unit: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          dateFiled: null,
          writLabor: "",
          amended : "",
          answerBy:"",
          dateServed:"",
          evictionAffiantSignature:"",
          signedBy:"",
          companyName:"",
          tenantNames: [],
          andAllOtherOccupants: ""
        },
      ],
      currentPage: 1,
      pageSize: 100,
      totalCount: 1,
      totalPages: 1,
      searchParam: "",
      writsOfPossessionPdfLink: ""
    },
    unsignedWrits: {
      items: [
        {
          isChecked: false,
          id: "",
          caseNo: "",
          documents: "",
          propertyName: "",
          county: "",
          firstName: "",
          lastName: "",
          unit: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          dateFiled: null,
          writLabor: "",
          amended : "",
          answerBy:"",
          dateServed:"",
          evictionAffiantSignature:"",
          signedBy:"",
          companyName:"",
          tenantNames: [],
          andAllOtherOccupants: ""
        },
      ],
      currentPage: 1,
      pageSize: 100,
      totalCount: 1,
      totalPages: 1,
      searchParam: "",
      writsOfPossessionPdfLink: ""
    },
    signedWrits: {
      items: [
        {
          isChecked: false,
          id: "",
          caseNo: "",
          documents: "",
          propertyName: "",
          county: "",
          firstName: "",
          lastName: "",
          unit: "",
          address: "",
          city: "",
          state: "",
          zip: "",
          dateFiled: null,
          writLabor: "",
          amended : "",
          answerBy:"",
          dateServed:"",
          evictionAffiantSignature:"",
          signedBy:"",
          companyName:"",
          tenantNames: [],
          andAllOtherOccupants: ""
        },
      ],
      currentPage: 1,
      pageSize: 100,
      totalCount: 1,
      totalPages: 1,
      writsOfPossessionPdfLink: ""
    },
    setAllUnsignedWrits: () => {},
    setAllSignedWrits: () => {},
    // setEvictionPdfLink: () => {},
    setAllWritsOfPossession: () => {},
    getAllWritsOfPossession: () => {},
    showSpinner: false,
    setShowSpinner: () => {},
    selectedWritsOfPossessionId: [],
    setSelectedWritsOfPossessionId: () => {},
    selectedUnSignedWritsId: [],
    setSelectedUnSignedWritsId: () => {},
    selectedSignedWritsId: [],
    setSelectedSignedWritsId: () => {},
    unsignedWritsCount: 0,
    filteredRecords: [],
  setFilteredRecords: () => {},
  selectedFilteredWritOfPossessionId:[],
  setSelectedFilteredWritOfPossessionId:() => {},
  bulkRecords: [],
  setBulkRecords: () => {},
  };
  
  // Create a context with an initial value
  const WritsOfPossessionContext = createContext<WritsOfPossessionContextType>(
    initialWritsOfPossessionContextValue
  );
  
  // Provide a component to wrap the application and make the context available
  export const WritsOfPossessionProvider: React.FC<{ children: ReactNode }> = ({
    children,
  }) => {
     // State to hold the UnsignedWrits data
  const [unsignedWrits, setAllUnsignedWrits] = useState<IWritsOfPossession>(
    initialWritsOfPossessionContextValue.unsignedWrits
  );
  // State to hold the SignedWrits data
  const [signedWrits, setAllSignedWrits] = useState<IWritsOfPossession>(
    initialWritsOfPossessionContextValue.signedWrits
  );
  const [selectedFilteredWritOfPossessionId, setSelectedFilteredWritOfPossessionId] = useState<string[]>(
    initialWritsOfPossessionContextValue.selectedFilteredWritOfPossessionId
  );
  const [filteredRecords, setFilteredRecords] = useState<IWritsOfPossessionItems[]>([]);

   // State to hold the writs of possession data
    const [writsOfPossession, setAllWritsOfPossession] = useState<IWritsOfPossession>(
        initialWritsOfPossessionContextValue.writsOfPossession
    );
    // State to hold the writs of possession spinner
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
  
    // state to hold data for selected grids
    const [selectedWritsOfPossessionId, setSelectedWritsOfPossessionId] = useState<
      string[]
    >(initialWritsOfPossessionContextValue.selectedWritsOfPossessionId);
  
    const [selectedUnSignedWritsId, setSelectedUnSignedWritsId] = useState<string[]>(
      initialWritsOfPossessionContextValue.selectedUnSignedWritsId
    );

    const [selectedSignedWritsId, setSelectedSignedWritsId] = useState<string[]>(
      initialWritsOfPossessionContextValue.selectedSignedWritsId
    );
    const [unsignedWritsCount, setUnsignedWritsCount] = useState<number>(0);
    const [bulkRecords, setBulkRecords] = useState<
    IWritsOfPossessionItems[]
  >([]);
  const { userRole } = useAuth();
    /**
     * Get list of writs of possession from api and bind that with the writs of possession screen
     *
     */
    const getAllWritsOfPossession = async (
      currentPage: number,
      pageSize: number,
      isSigned:boolean,
      search?: string
    ) => {
      try {
         setShowSpinner(true);
        //  const apiResponse = await WritsService.getAllDismissals(
        //   currentPage,
        //   pageSize,
        //   isSigned,
        //   search
        // );
        // if (apiResponse.status === HttpStatusCode.OK) {
        //   if(isSigned === false) {
        //   setAllSignedWrits((prevFileEvictions) => ({
        //     ...prevFileEvictions,
        //     items: apiResponse.data.items,
        //     currentPage: apiResponse.data.currentPage,
        //     totalCount: apiResponse.data.totalCount,
        //     totalPages: apiResponse.data.totalPages,
        //     pageSize: apiResponse.data.pageSize,
        //     ...(search ? { searchParam: search } : {}),
        //   }));
        // }
        //   else {
        //     setAllSignedWrits((prevFileEvictions) => ({
        //       ...prevFileEvictions,
        //       items: apiResponse.data.items,
        //       currentPage: apiResponse.data.currentPage,
        //       totalCount: apiResponse.data.totalCount,
        //       totalPages: apiResponse.data.totalPages,
        //       pageSize: apiResponse.data.pageSize,
        //       ...(search ? { searchParam: search } : {}),
        //     })); 
        //   }
        // }



        //  setAllWritsOfPossession({
        //   items: [
        //     {
        //       id: "2",
        //   caseNo: "456",
        //    document:"cdcd" ,
        //    propertyName: "cdcd",
        //    county: "cdcd",
        //    firstName: "cdc",
        //    lastName: "cdcd",
        //    unit: "cfc",
        //    streetNo: "fgf",
        //    address: "fdhgcf",
        //    city: "gdcs",
        //    state: "vcb",
        //    zip: "hgdhhw",
        //    evictionDateFiled: new Date(),
        //    evictionServiceDate: new Date(),
        //    lastDaytoAnswer: new Date(),
        //    courtDate: new Date(),
        //    writFileDate: new Date(),
        //    writLaborName : "ghdhw",
        //    writAffiantSignature : "hgwd",
        //    amended : "hgdgvw",
        //     },
        //     {
        //     id: "3",
        //     caseNo: "467",
        //      document:"gh" ,
        //      propertyName: "ygf",
        //      county: "cdcd",
        //      firstName: "gf",
        //      lastName: "ftg",
        //      unit: "cfc",
        //      streetNo: "fgf",
        //      address: "fdhgcf",
        //      city: "gdcs",
        //      state: "vcb",
        //      zip: "gf",
        //      evictionDateFiled: new Date(),
        //      evictionServiceDate: new Date(),
        //      lastDaytoAnswer: new Date(),
        //      courtDate: new Date(),
        //      writFileDate: new Date(),
        //      writLaborName : "ghdhw",
        //      writAffiantSignature : "hgwd",
        //      amended : "hgdgvw",
        //       },
        //    {

        //     id: "5",
        //     caseNo: "456",
        //      document:"hfd" ,
        //      propertyName: "gd",
        //      county: "cdcd",
        //      firstName: "fg",
        //      lastName: "cdcd",
        //      unit: "cfc",
        //      streetNo: "fgf",
        //      address: "fdhgcf",
        //      city: "gdcs",
        //      state: "vcb",
        //      zip: "hgdhhw",
        //      evictionDateFiled: new Date(),
        //      evictionServiceDate: new Date(),
        //      lastDaytoAnswer: new Date(),
        //      courtDate: new Date(),
        //      writFileDate: new Date(),
        //      writLaborName : "ghdhw",
        //      writAffiantSignature : "hgwd",
        //      amended : "hgdgvw",
        //       },
            
        //   ],
        //   currentPage: 1,
        //   pageSize: 10,
        //   totalCount: 1,
        //   totalPages: 1,
        //   searchParam: undefined,       
        // });





        // get writs of possession

        const apiResponse = await WritsOfPossessionService.getAllWritesOfPossession(
          currentPage,
          pageSize,
          isSigned,
          search
        );
        // if (apiResponse.status === HttpStatusCode.OK) {
        //   setAllWritsOfPossession((prevFileEvictions) => ({
        //     ...prevFileEvictions,
        //     items: apiResponse.data.items,
        //     currentPage: apiResponse.data.currentPage,
        //     totalCount: apiResponse.data.totalCount,
        //     totalPages: apiResponse.data.totalPages,
        //     pageSize: apiResponse.data.pageSize,
        //     ...(search ? { searchParam: search } : {}),
        //   }));
        // }
        if (apiResponse.status === HttpStatusCode.OK) {
          if(isSigned === false && !userRole.includes(UserRole.WritLabor)) {
          setAllUnsignedWrits((prevFileEvictions) => ({
            ...prevFileEvictions,
            items: apiResponse.data.items,
            currentPage: apiResponse.data.currentPage,
            totalCount: apiResponse.data.totalCount,
            totalPages: apiResponse.data.totalPages,
            pageSize: apiResponse.data.pageSize,
            ...(search ? { searchParam: search } : {}),
          }));
          setUnsignedWritsCount(apiResponse.data.items.length);
        }
          else {
            setAllSignedWrits((prevFileEvictions) => ({
              ...prevFileEvictions,
              items: apiResponse.data.items,
              currentPage: apiResponse.data.currentPage,
              totalCount: apiResponse.data.totalCount,
              totalPages: apiResponse.data.totalPages,
              pageSize: apiResponse.data.pageSize,
              ...(search ? { searchParam: search } : {}),
            })); 
          }
        }
      } finally {
        setShowSpinner(false);
      }
    };
  
    // Provide the context value to its children
    return (
      <WritsOfPossessionContext.Provider
        value={{
          showSpinner,
          signedWrits,
          writsOfPossession,
          unsignedWrits,
          setAllUnsignedWrits,
          setAllSignedWrits,
          getAllWritsOfPossession,
          setAllWritsOfPossession,
          setShowSpinner,
          selectedWritsOfPossessionId,
          setSelectedWritsOfPossessionId,
          selectedUnSignedWritsId,   
          setSelectedUnSignedWritsId,
          selectedSignedWritsId,   
          setSelectedSignedWritsId,
          unsignedWritsCount,
          filteredRecords, 
        setFilteredRecords,
        selectedFilteredWritOfPossessionId,
        setSelectedFilteredWritOfPossessionId,
        bulkRecords,
        setBulkRecords,
        }}
      >
        {children}
      </WritsOfPossessionContext.Provider>
    );
  };
  
  // Create a hook to easily access the WritsOfPossession context within components
  export const useWritsOfPossessionContext = (): WritsOfPossessionContextType => {
    // Get the context value using useContext
    const context = useContext(WritsOfPossessionContext);
    // If the context is not found, throw an error
    if (!context) {
      throw new Error(
        "useWritsOfPossessionContext must be used within a WritsOfPossessionProvider"
      );
    }
    // Return the context value
    return context;
  };
  